<template>
    <div class="data-table__cell" :style="{ width: width }">
        <slot></slot>
    </div>
</template>
<script>
export default {
    name: 'DataTableCell',
    props: {
        width: {
            default: null,
            type: String,
        },
    },
};
</script>

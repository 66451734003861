<template>
    <card
        v-if="reservation"
        heading="Varauksen tiedot"
        class="reservation-form-card"
    >
        <form slot="content">
            <div class="field">
                <div>
                    <label>Peräkärry</label>
                    <span class="select-wrapper">
                        <select
                            v-model="capacityUnit"
                            class="select"
                            :disabled="
                                !['active', 'started'].includes(
                                    reservation.state
                                ) || readOnly
                            "
                        >
                            <option
                                v-for="unit in capacityUnits"
                                :key="unit.name"
                                :disabled="unit.disabled"
                                :value="unit.name"
                            >
                                {{ unit.name }}
                            </option>
                        </select>
                        <span class="icon icon--chevron-down"></span>
                    </span>
                </div>
            </div>
            <div class="field">
                <div>
                    <label>Varaus alkaa</label>
                    <div class="input-group">
                        <flat-pickr
                            v-model="dateFrom"
                            :config="datePickerConfig"
                            class="input"
                            :disabled="
                                !['active', 'started'].includes(
                                    reservation.state
                                ) || readOnly
                            "
                        ></flat-pickr>
                        <button data-toggle class="data-toggle">
                            <i class="icon icon--calendar-alt" />
                        </button>
                    </div>
                </div>
                <div>
                    <label>Varaus päättyy</label>
                    <div class="input-group">
                        <flat-pickr
                            v-model="dateTo"
                            :config="datePickerConfig"
                            class="input"
                            :disabled="
                                reservation.state === 'cancelled' || readOnly
                            "
                        ></flat-pickr>
                        <button data-toggle class="data-toggle">
                            <i class="icon icon--calendar-alt" />
                        </button>
                    </div>
                </div>
            </div>
            <div class="field">
                <div>
                    <label>
                        Kärryn luovuttaja (Puuilo)
                        <span
                            v-if="reservation.state === 'active' && !readOnly"
                            class="asterisk"
                        >
                            *
                        </span>
                    </label>
                    <input
                        v-model="trailerReleasedBy"
                        class="input"
                        type="text"
                        :disabled="reservation.state !== 'active' || readOnly"
                    />
                </div>
                <div>
                    <label>
                        Kärryn vastaanottaja (Puuilo)
                        <span
                            v-if="
                                ['started', 'returned'].includes(
                                    reservation.state
                                ) && !readOnly
                            "
                            class="asterisk"
                        >
                            *
                        </span>
                    </label>
                    <input
                        v-model="trailerReceivedBy"
                        class="input"
                        type="text"
                        :disabled="
                            !['started', 'returned'].includes(
                                reservation.state
                            ) || readOnly
                        "
                    />
                </div>
            </div>
            <div class="field">
                <div>
                    <label>Varauksen muistiinpanot</label>
                    <textarea
                        v-model="comment"
                        :disabled="readOnly"
                        class="input input--textarea"
                        placeholder="Lisätiedot varaukseen liittyen"
                        rows="3"
                    ></textarea>
                </div>
            </div>
            <div class="field ar">
                <div>
                    <button
                        class="button button--secondary"
                        :disabled="isDisabled"
                        @click="saveReservation"
                    >
                        Tallenna
                    </button>
                </div>
            </div>
            <div class="reservation-form__comments">
                <div
                    v-for="reservationComment in reservation.comments"
                    :key="reservationComment.id"
                    class="reservation-form__comment"
                >
                    <div class="reservation-form__comment--title">
                        Muistiinpano /
                        {{ formatDate(reservationComment.created) }}
                    </div>
                    <div class="reservation-form__comment--content">
                        {{ reservationComment.content }}
                    </div>
                    <div
                        v-if="!readOnly"
                        class="reservation-form__comment--delete"
                        @click="removeComment(reservationComment.id)"
                    >
                        <i class="icon icon--times-circle"></i>
                    </div>
                </div>
            </div>
        </form>
    </card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import moment from 'moment';
import FlatPickr from 'vue-flatpickr-component';
import { pickerConfigDateTime } from '../helpers';
import Card from './Card.vue';

export default {
    name: 'ReservationForm',
    components: {
        Card,
        FlatPickr,
    },
    props: {
        readOnly: {
            type: Boolean,
            default: false,
        },
        isDisabled: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            datePickerConfig: pickerConfigDateTime,
        };
    },
    computed: {
        ...mapGetters({
            capacityUnits: 'reservation/capacityUnits',
            dirtyReservation: 'reservation/dirtyReservation',
            reservation: 'reservation/reservation',
            currentCapacityUnit: 'reservation/currentCapacityUnit',
            currentComment: 'reservation/currentComment',
            payment: 'reservation/payment',
        }),
        dateFrom: {
            get() {
                return (
                    this.dirtyReservation.dateFrom || this.reservation.dateFrom
                ).toString();
            },
            set(value) {
                this.edit({ field: 'dateFrom', value });
            },
        },
        dateTo: {
            get() {
                return (
                    this.dirtyReservation.dateTo || this.reservation.dateTo
                ).toString();
            },
            set(value) {
                this.edit({ field: 'dateTo', value });
            },
        },
        trailerReleasedBy: {
            get() {
                return (
                    this.dirtyReservation.data.trailerReleasedBy
                    || this.reservation.data.trailerReleasedBy
                );
            },
            set(value) {
                this.edit({ field: 'trailerReleasedBy', value });
            },
        },
        trailerReceivedBy: {
            get() {
                return (
                    this.dirtyReservation.data.trailerReceivedBy
                    || this.reservation.data.trailerReceivedBy
                );
            },
            set(value) {
                this.edit({ field: 'trailerReceivedBy', value });
            },
        },
        capacityUnit: {
            get() {
                return this.currentCapacityUnit;
            },
            set(value) {
                this.setCapacityUnit(value);
            },
        },
        comment: {
            get() {
                return this.currentComment;
            },
            set(value) {
                this.setComment(value);
            },
        },
    },
    methods: {
        ...mapActions({
            edit: 'reservation/editField',
            fetchCapacityUnits: 'reservation/fetchCapacityUnits',
            setCapacityUnit: 'reservation/setCapacityUnit',
            removeComment: 'reservation/removeComment',
            setComment: 'reservation/setComment',
        }),
        formatDate(timestamp) {
            return moment.unix(timestamp).format('D.M.Y H:mm');
        },
        saveReservation() {
            this.$emit('saveReservation');
        },
    },
};
</script>

import { officeResource } from '../../api';

const initialState = {
    popup: null,
    showPopupContent: false,
    office: null,
    officeLock: false,
    officeItems: [],
    apiToken: null,
};

const getters = {
    apiToken: (state) => state.apiToken,
    office: (state) => state.office,
    officeItems: (state) => state.officeItems,
    popup: (state) => state.popup,
};

const mutations = {
    SET_POPUP(context, payload) {
        context.popup = payload.name;
        context.popupProps = payload.data ? payload.data : null;
    },
    SET_SHOWPOPUPCONTENT(context, payload) {
        context.showPopupContent = payload;
    },
    SET_OFFICE(context, payload) {
        context.office = payload;
    },
    SET_OFFICE_LOCK(context, payload) {
        context.officeLock = payload;
    },
    SET_TOKEN(context, payload) {
        context.apiToken = payload;
    },
    SET_OFFICE_ITEMS(context, payload) {
        context.officeItems = payload;
    },
};

const actions = {
    reset(context) {
        context.commit('SET_POPUP', { name: null });
        context.commit('SET_SHOWPOPUPCONTENT', null);
        context.commit('SET_OFFICE', null);
        context.commit('SET_OFFICE_LOCK', false);
        context.commit('SET_OFFICE_ITEMS', []);
    },
    async fetchOfficeItems({ state, commit }) {
        if (!state.office.id) {
            return;
        }
        const res = await officeResource.items.index(state.office.id, {
            _imageSizes: '200x200',
        });
        const { data } = res.data;
        commit('SET_OFFICE_ITEMS', data);
    },
};

export default {
    namespaced: true,
    state: initialState,
    getters,
    mutations,
    actions,
};

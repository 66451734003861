<template>
    <div ref="dropdownMenu" class="search">
        <label>Hae varausta</label>
        <span class="search__input-container">
            <input
                v-model="searchTerm"
                class="input"
                type="text"
                placeholder="Varausnro, nimi, puhelin, s-posti"
                @input="onSearch"
                @focus="onFocus"
            />
            <span class="icon icon--search"></span>
        </span>
        <div :class="['search__content-box', showDropdown ? 'is-active' : '']">
            <div v-if="searchResults.length">
                <div
                    v-for="item in searchResults"
                    :key="item.id"
                    class="search__search-item"
                    tabindex="0"
                    @click="goToReservation(item.id)"
                    @keyup="e => onKeyup(e, item.id)"
                >
                    <span class="captionbold">
                        {{ item.name }} / {{ item.unit }}
                    </span>
                    <br />
                    <span class="caption">
                        {{ item.realId }} {{ formatDate(item.dateFrom) }}
                    </span>
                </div>
            </div>

            <empty-resource v-else slot="content">
                Ei hakutuloksia
            </empty-resource>
        </div>
    </div>
</template>

<script>
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';
import EmptyResource from './EmptyResource.vue';

export default {
    name: 'ReservationSearch',
    components: {
        EmptyResource,
    },
    data() {
        return {
            searchTerm: '',
            showDropdown: false,
        };
    },
    computed: {
        ...mapGetters({
            searchResults: 'reservationSearch/searchResults',
        }),
        office() {
            return this.$store.state.base.office;
        },
    },
    mounted() {
        this.reset();
    },
    created() {
        document.addEventListener('click', this.documentClick);
    },
    destroyed() {
        document.removeEventListener('click', this.documentClick);
    },
    methods: {
        ...mapActions({
            reset: 'reservationSearch/reset',
            search: 'reservationSearch/search',
        }),
        documentClick(e) {
            const el = this.$refs.dropdownMenu;
            const { target } = e;
            if (el !== target && !el.contains(target)) {
                this.showDropdown = false;
            }
        },
        goToReservation(id) {
            this.$router.push({
                name: 'Reservation',
                params: { id },
            });
        },
        formatDate(timestamp) {
            return moment.unix(timestamp).format('D.M.Y H:mm');
        },
        onFocus() {
            this.showDropdown = true;
        },
        onKeyup(e, id) {
            if (e.key === 'Enter') {
                this.goToReservation(id);
            }
        },
        onSearch(e) {
            this.searchTerm = e.target.value;

            if (this.searchTerm !== '' && this.searchTerm.length >= 3) {
                this.search(this.searchTerm);
            }

            if (this.searchTerm === '') {
                this.reset();
            }
        },
    },
};
</script>

<template>
    <label class="toggle">
        <slot></slot>
        <span class="slider"></span>
    </label>
</template>

<script>
export default {
    name: 'Toggle',
};
</script>

<template>
    <transition name="fade" @after-enter="showContent = true">
        <div v-if="popupOpen" class="popup" @click="handleFadeClick($event)">
            <transition name="slide-from-top">
                <div v-if="showContent" class="popup__wrapper">
                    <div class="popup__header">
                        <h2 class="popup__header-title">{{ currentTitle }}</h2>
                        <button class="popup__close" @click="closePopup">
                            <i class="icon icon--times" />
                        </button>
                    </div>
                    <div
                        :is="currentView"
                        class="popup__body"
                        :data-props="popupProps"
                        @close="closePopup($event)"
                    ></div>
                </div>
            </transition>
        </div>
    </transition>
</template>

<script>
import { mapGetters } from 'vuex';
import PopupDashboardLog from './Popup/PopupDashboardLog.vue';
import PopupGeneral from './Popup/PopupGeneral.vue';
import PopupOfficeSelect from './Popup/PopupOfficeSelect.vue';
import PopupReservationCancelConfirm from './Popup/PopupReservationCancelConfirm.vue';
import PopupReservationLog from './Popup/PopupReservationLog.vue';
import PopupReservationWarn from './Popup/PopupReservationWarn.vue';
import PopupTrailerMaintenance from './Popup/PopupTrailerMaintenance.vue';
import PopupTrailerModifyMaintenance from './Popup/PopupTrailerModifyMaintenance.vue';

export default {
    name: 'Popup',
    components: {
        PopupDashboardLog,
        PopupGeneral,
        PopupOfficeSelect,
        PopupReservationCancelConfirm,
        PopupReservationLog,
        PopupReservationWarn,
        PopupTrailerMaintenance,
        PopupTrailerModifyMaintenance,
    },
    computed: {
        ...mapGetters({
            trailerMaintenanceSelected: 'trailers/selected',
        }),
        popupOpen() {
            return this.$store.state.base.popup;
        },
        popupProps() {
            return this.$store.state.base.popupProps;
        },
        currentView() {
            if (this.popupOpen === 'dashboardLog') {
                return 'PopupDashboardLog';
            }
            if (this.popupOpen === 'officeSelect') {
                return 'PopupOfficeSelect';
            }
            if (this.popupOpen === 'reservationCancelConfirm') {
                return 'PopupReservationCancelConfirm';
            }
            if (this.popupOpen === 'reservationLog') {
                return 'PopupReservationLog';
            }
            if (this.popupOpen === 'reservationWarn') {
                return 'PopupReservationWarn';
            }
            if (this.popupOpen === 'trailerMaintenance') {
                return 'PopupTrailerMaintenance';
            }
            if (this.popupOpen === 'trailerModifyMaintenance') {
                return 'PopupTrailerModifyMaintenance';
            }
            return 'PopupGeneral';
        },
        currentTitle() {
            const titles = {
                PopupDashboardLog: 'Viimeisimmät tapahtumat',
                PopupGeneral: 'Vahvistus',
                PopupOfficeSelect: 'Valitse myymälä',
                PopupReservationCancelConfirm: 'Oletko varma?',
                PopupReservationLog: 'Varauksen tapahtumat',
                PopupReservationWarnHours: 'Oletko varma?',
                PopupTrailerMaintenance: `Aseta huoltotila: ${
                    this.trailerMaintenanceSelected
                }`,
                PopupTrailerModifyMaintenance: `Muokkaa huoltotilaa: ${
                    this.trailerMaintenanceSelected
                }`,
            };
            return titles[this.currentView];
        },
        showContent: {
            get() {
                return this.$store.state.base.showPopupContent;
            },
            set(value) {
                this.$store.commit('base/SET_SHOWPOPUPCONTENT', value);
            },
        },
    },
    methods: {
        closePopup() {
            this.$store.commit('base/SET_POPUP', { name: null });
            this.showContent = false;
        },
        handleFadeClick(event) {
            if (event.target.className === 'popup') {
                this.closePopup();
            }
        },
    },
};
</script>

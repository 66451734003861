/* eslint-disable */
import axios from 'axios';
import Vue from 'vue';
import VueProgressBar from 'vue-progressbar';
import store from '../store'

Vue.use(VueProgressBar, {
    color: '#4095bf',
    failedColor: '#bb0004',
    thickness: '6px',
    autoRevert: true,
});

const progress = Vue.prototype.$Progress;
const latencyThreshold = 100;
const responseLatency = 50;
let requestsTotal = 0;
let requestsCompleted = 0;
let completed = 0;

function setComplete() {
    completed = 0;
    requestsTotal = 0;
    requestsCompleted = 0;
    progress.finish();
}

function addCompletion() {
    requestsCompleted += 1;

    if (requestsCompleted >= requestsTotal) {
        setComplete();
    } else {
        completed = (requestsCompleted / requestsTotal) * 100 - 10;
        progress.set(completed);
    }
}

const http = axios.create({
    baseURL: process.env.API_HOST,
    headers: {
        Apikey: process.env.API_KEY,
    },
});

const requestInterceptor = config => {
    if (requestsTotal === 0) {
        setTimeout(() => {
            // If not all requests finished during latency time, then start progressbar
            if (requestsTotal !== requestsCompleted) {
                progress.start();
            }
        }, latencyThreshold);
    }

    requestsTotal += 1;

    completed = (requestsCompleted / requestsTotal) * 100 + 1;
    progress.set(completed);

    const token = store.getters['base/apiToken'];
    if (token) {
        config.headers['Token'] = token;
    }

    return config;
};

const errorInterceptor = error => {
    progress.fail();
    setComplete();

    return Promise.reject(error);
};

const responseInterceptor = response => {
    setTimeout(addCompletion, responseLatency);

    return response;
};

http.interceptors.request.use(requestInterceptor, errorInterceptor);
http.interceptors.response.use(responseInterceptor, errorInterceptor);

export default http;

<template>
    <div class="card">
        <div v-if="heading" class="card__heading">{{ heading }}</div>
        <div class="card__content">
            <slot name="content"></slot>
        </div>
        <div v-if="$slots.footer" class="card__footer">
            <slot name="footer"></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Card',
    props: {
        heading: {
            type: String,
            default: '',
        },
    },
};
</script>

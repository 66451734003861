import moment from 'moment';
import { itemResource } from '../../api';
import { truncatedPagination as paginationHelper } from '../../helpers';

const emptyPagination = {
    total: 0,
    totalPages: 0,
};

const initialState = {
    currentPage: 1,
    currentState: 'active',
    customer: null,
    dateFrom: null,
    dateTo: null,
    limit: 10,
    notification: null,
    pagination: emptyPagination,
    processing: false,
    rows: [],
    sort: null,
    type: 'list',
    unit: null,
    calendar: {
        view: 'resourceTimelineDay',
        dates: {},
    },
};

const getters = {
    currentPage: (state) => state.currentPage,
    currentState: (state) => state.currentState,
    limit: (state) => state.limit,
    notification: (state) => state.notification,
    pagination: (state) => state.pagination,
    processing: (state) => state.processing,
    rows: (state) => state.rows,
    truncatedPagination: (state) => paginationHelper(state.pagination, state.currentPage),
    calendarView: (state) => state.calendar.view,
    calendarViewDates: (state) => (view) => state.calendar.dates[view] || { start: null, end: null },
};

const mutations = {
    SET_CURRENTPAGE(context, payload) {
        context.currentPage = payload;
    },
    SET_CURRENTSTATE(context, payload) {
        context.currentState = payload;
    },
    SET_CUSTOMER(context, payload) {
        context.customer = payload;
    },
    SET_DATEFROM(context, payload) {
        context.dateFrom = payload;
    },
    SET_DATETO(context, payload) {
        context.dateTo = payload;
    },
    SET_LIMIT(context, payload) {
        context.limit = payload;
    },
    SET_NOTIFICATION(context, payload) {
        context.notification = payload;
    },
    SET_PAGINATION(context, payload) {
        context.pagination = payload;
    },
    SET_PROCESSING(context, payload) {
        context.processing = payload;
    },
    SET_ROWS(context, payload) {
        context.rows = payload;
    },
    SET_SORT(context, payload) {
        context.sort = payload;
    },
    SET_TYPE(context, payload) {
        context.type = payload;
    },
    SET_CALENDAR_VIEW(context, payload) {
        context.calendar.view = payload;
    },
    SET_CALENDAR_VIEW_DATES(context, { view, dates }) {
        context.calendar.dates[view] = dates;
    },
    SET_UNIT(context, payload) {
        context.unit = payload;
    },
};

const actions = {
    reset({ commit }) {
        commit('SET_CURRENTPAGE', 1);
        commit('SET_CURRENTSTATE', 'active');
        commit('SET_CUSTOMER', null);
        commit('SET_DATEFROM', null);
        commit('SET_DATETO', null);
        commit('SET_LIMIT', 10);
        commit('SET_NOTIFICATION', null);
        commit('SET_PAGINATION', emptyPagination);
        commit('SET_PROCESSING', false);
        commit('SET_ROWS', []);
        commit('SET_UNIT', null);
    },
    softReset({ commit }) {
        commit('SET_PROCESSING', false);
        commit('SET_ROWS', []);
    },
    changePage({ commit, dispatch }, page) {
        if (page === '...') {
            return;
        }

        commit('SET_CURRENTPAGE', page);
        dispatch('load');
    },
    changeSort({ commit, dispatch, state }, field) {
        const sort = state.sort && state.sort === `+${field}`
            ? `-${field}`
            : `+${field}`;

        commit('SET_CURRENTPAGE', 1);
        commit('SET_SORT', sort);
        dispatch('load');
    },
    changeState({ commit, dispatch }, value) {
        commit('SET_CURRENTSTATE', value);
        commit('SET_PAGINATION', emptyPagination);
        commit('SET_CURRENTPAGE', 1);
        dispatch('load');
    },
    setFilters({ commit }, filters) {
        const filterMutations = {
            type: (val) => {
                commit('SET_TYPE', val);
            },
            customer: (val) => {
                commit('SET_CUSTOMER', val);
            },
            currentState: (val) => {
                commit('SET_CURRENTSTATE', val);
            },
            unit: (val) => {
                commit('SET_UNIT', val);
            },
            dateFrom: (val) => {
                commit('SET_DATEFROM', val);
            },
            dateTo: (val) => {
                commit('SET_DATETO', val);
            },
            sort: (val) => {
                commit('SET_SORT', val);
            },
            limit: (val) => {
                commit('SET_LIMIT', val);
            },
        };

        Object.keys(filters).forEach((key) => {
            const mutation = filterMutations[key];
            mutation(filters[key]);
        });
    },
    setNotification({ commit }, notification) {
        commit('SET_NOTIFICATION', notification);
        setTimeout(() => {
            commit('SET_NOTIFICATION', null);
        }, 10000);
    },
    // async actions
    async load({
        commit,
        state,
        rootState,
        dispatch,
    }) {
        commit('SET_PROCESSING', true);

        try {
            const options = {
                _with: 'user,ban',
                _offset: (state.currentPage - 1) * state.limit,
                _limit: state.limit,
                state: state.currentState,
                ...(state.customer && { _q: state.customer }),
                ...(state.unit && { capacityUnit: state.unit }),
                ...(state.dateFrom && {
                    dateFrom: `gt=${state.dateFrom}`,
                }),
                ...(state.dateTo && {
                    dateTo: `lt=${moment
                        .unix(state.dateTo)
                        .add(1, 'days')
                        .format('X')}`,
                }),
                ...(rootState.base.office && {
                    officeId: rootState.base.office.id,
                }),
                ...(state.sort && { _sort: state.sort }),
            };
            const res = await itemResource.index(options);
            const rows = res.data.data.map((r) => {
                const { user, items } = r;
                return {
                    ban: r.ban,
                    unit: items
                        ? items
                            .map((i) => i.capacityUnit)
                            .join(' ')
                            .trim()
                        : '',
                    dateFrom: r.dateFrom,
                    dateTo: r.dateTo,
                    created: r.created,
                    id: r.id,
                    realId: `#${r.realId}`,
                    name: `${
                        user && user.contact ? user.contact.firstName : ''
                    } ${user && user.contact ? user.contact.lastName : ''}`,
                    email: user ? user.email : '',
                    phone: user && user.contact ? user.contact.phone : '',
                };
            });

            commit('SET_ROWS', rows);
            commit(
                'SET_PAGINATION',
                res.data.pagination ? res.data.pagination : emptyPagination,
            );
        } catch (err) {
            console.log(err);

            dispatch('setNotification', {
                title: 'Varausten lataaminen epäonnistui',
                type: 'error',
            });
        }

        commit('SET_PROCESSING', false);
    },
};

export default {
    namespaced: true,
    state: initialState,
    getters,
    mutations,
    actions,
};

<template>
    <div>
        <span class="select-wrapper">
            <select
                v-model="selectedUnit"
                class="select select--small"
                style="width:100%;"
                @change="$emit('changed', selectedUnit)"
            >
                <option
                    v-for="unit in options"
                    :key="unit.name"
                    :disabled="unit.disabled"
                    :value="unit.name"
                >
                    {{ unit.name }}
                </option>
            </select>
            <span class="icon icon--chevron-down"></span>
        </span>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { itemResource } from '../../api';
import { eventBus } from '../../helpers';

export default {
    name: 'PopupTrailerMaintenanceSelect',
    props: {
        id: {
            type: String,
            required: true,
        },
        items: {
            type: Array,
            required: true,
        },
        dateFrom: {
            type: Number,
            required: true,
        },
        dateTo: {
            type: Number,
            required: true,
        },
        initial: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            options: [],
            selectedUnit: null,
        };
    },
    computed: {
        ...mapGetters({
            officeItems: 'base/officeItems',
        }),
    },
    mounted() {
        this.load();
        this.selectedUnit = this.initial;
        eventBus.$on('reservation-unit-change', this.load);
    },
    methods: {
        async load() {
            const { officeItems } = this;
            const officeItemIds = [];
            this.items.forEach((item) => {
                const { id } = officeItems.find((officeItem) => officeItem.shopItemId === item.shopItemId);
                if (item.capacityUnit) {
                    officeItemIds.push(id);
                }
            });

            const unitRes = await itemResource.units.index(this.id, {
                dateFrom: this.dateFrom,
                dateTo: this.dateTo,
                officeItemId: officeItemIds[0],
            });

            this.options = unitRes.data.data;
        },
    },
};
</script>

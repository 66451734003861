<template>
    <div class="container client-container">
        <div v-if="processing" class="overlay"></div>
        <div v-if="customer && dirtyCustomer" class="row">
            <div class="col-8">
                <card heading="Henkilötiedot">
                    <div slot="content" class="customer__details">
                        <div class="customer__details--left">
                            <div class="input--field">
                                <label>Etunimi</label>
                                <input
                                    class="input input--small"
                                    :value="firstName"
                                    type="text"
                                    name="firstName"
                                    @input="edit"
                                />
                            </div>
                            <div class="input--field">
                                <label>Sukunimi</label>
                                <input
                                    class="input input--small"
                                    :value="lastName"
                                    type="text"
                                    name="lastName"
                                    @input="edit"
                                />
                            </div>
                            <div class="input--field">
                                <label>Puhelin</label>
                                <input
                                    class="input input--small"
                                    :value="phone"
                                    name="phone"
                                    type="text"
                                    :disabled="activeBan"
                                    @input="edit"
                                />
                            </div>
                            <div class="input--field">
                                <label>Sähköposti</label>
                                <input
                                    class="input input--small"
                                    :value="email"
                                    name="email"
                                    type="text"
                                    @input="edit"
                                />
                            </div>
                            <div class="input--field">
                                <label>Katuosoite</label>
                                <input
                                    class="input input--small"
                                    :value="address"
                                    name="address"
                                    type="text"
                                    @input="edit"
                                />
                            </div>
                            <div class="input--field">
                                <label>Postinumero</label>
                                <input
                                    class="input input--small"
                                    :value="postCode"
                                    name="postCode"
                                    type="text"
                                    @input="edit"
                                />
                            </div>
                            <div class="input--field">
                                <label>Postitoimipaikka</label>
                                <input
                                    class="input input--small"
                                    :value="city"
                                    name="city"
                                    type="text"
                                    @input="edit"
                                />
                            </div>
                        </div>
                        <div class="customer__details--right">
                            <div class="customer__details--ban">
                                <label>Varauskielto</label>
                                <toggle v-if="!activeBan">
                                    <input v-model="banned" type="checkbox" />
                                </toggle>
                            </div>
                            <div
                                v-if="!activeBan && banned"
                                class="input--field"
                            >
                                <label>Kiellon syy / lisätiedot</label>
                                <textarea
                                    v-model="banComment"
                                    class="input input--textarea"
                                    placeholder="Lisätiedot kieltoon liittyen"
                                    rows="3"
                                ></textarea>
                            </div>
                            <div
                                v-if="activeBan"
                                class="customer-active-ban notification notification__error"
                            >
                                <div>
                                    <span class="captionbold">
                                        {{ formatDate(activeBan.created) }} -
                                        Varauskielto
                                    </span>
                                    <button
                                        class="button button--link"
                                        @click="removeBan(activeBan.id)"
                                    >
                                        Poista kielto
                                    </button>
                                </div>
                                <span>{{ activeBan.comment }}</span>
                            </div>
                            <h4 class="heading">Aiemmat varauskiellot</h4>
                            <div class="customer-ban-list">
                                <div
                                    v-for="ban in previousBans"
                                    :key="ban.id"
                                    class="customer-ban-list__item"
                                >
                                    <span class="caption">
                                        {{ formatDate(ban.created) }} -
                                        {{ formatDate(ban.modified) }}
                                    </span>
                                    {{ ban.comment }}
                                </div>
                            </div>
                        </div>
                    </div>
                </card>
            </div>
            <div class="col-4">
                <h3>Varaushistoria</h3>
                <ul
                    v-if="reservationHistory"
                    class="customer__reservations-list"
                >
                    <li
                        v-for="reservation in reservationHistory"
                        :key="reservation.id"
                        class="customer__reservations-list--item"
                        @click="
                            $router.push({
                                name: 'Reservation',
                                params: { id: reservation.id },
                            })
                        "
                    >
                        <span class="bodybold">
                            #{{ reservation.realId }}
                            {{
                                reservation.items
                                    .map(i => i.capacityUnit)
                                    .join(' ')
                                    .trim()
                            }}
                        </span>
                        <br />
                        <small>
                            {{ formatDate(reservation.dateFrom) }} -
                            {{ formatDate(reservation.dateTo) }}
                        </small>
                        <i class="icon icon--chevron-right"></i>
                    </li>
                </ul>
            </div>
        </div>

        <customer-action-bar
            :save-disabled="!isDirty || (banned && !banComment)"
            @cancel="handleCancel"
            @save="save"
        ></customer-action-bar>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import moment from 'moment';
import Card from '../components/Card.vue';
import CustomerActionBar from '../components/CustomerActionBar.vue';
import Toggle from '../components/Toggle.vue';

export default {
    name: 'Client',
    components: {
        Card,
        CustomerActionBar,
        Toggle,
    },
    props: { id: { required: true, type: String } },
    computed: {
        ...mapGetters({
            activeBan: 'client/activeBan',
            banned: 'client/banned',
            banComment: 'client/banComment',
            customer: 'client/customer',
            dirtyCustomer: 'client/dirtyCustomer',
            isDirty: 'client/isDirty',
            previousBans: 'client/previousBans',
            processing: 'client/processing',
            reservationHistory: 'client/reservationHistory',
        }),
        firstName() {
            return (
                this.dirtyCustomer.contact.firstName
                || (this.customer.contact && this.customer.contact.firstName)
                || ''
            );
        },
        lastName() {
            return (
                this.dirtyCustomer.contact.lastName
                || (this.customer.contact && this.customer.contact.lastName)
                || ''
            );
        },
        phone() {
            return (
                this.dirtyCustomer.contact.phone
                || (this.customer.contact && this.customer.contact.phone)
                || ''
            );
        },
        email() {
            return this.dirtyCustomer.email || this.customer.email;
        },
        address() {
            return (
                this.dirtyCustomer.contact.address
                    || (this.customer.contact && this.customer.contact.address)
                    || ''
            );
        },
        postCode() {
            return (
                this.dirtyCustomer.contact.postCode
                    || (this.customer.contact && this.customer.contact.postCode)
                    || ''
            );
        },
        city() {
            return (
                this.dirtyCustomer.contact.city
                    || (this.customer.contact && this.customer.contact.city)
                    || ''
            );
        },
        banned: {
            get() {
                return this.$store.state.client.banned;
            },
            set(value) {
                this.$store.commit('client/SET_BANNED', value);
                this.$store.commit('client/SET_ISDIRTY', true);
            },
        },
        banComment: {
            get() {
                return this.$store.state.client.banComment;
            },
            set(value) {
                this.$store.commit('client/SET_BANCOMMENT', value);
                this.$store.commit('client/SET_ISDIRTY', true);
            },
        },
    },
    mounted() {
        this.load(this.id);
    },
    beforeDestroy() {
        this.reset();
    },
    methods: {
        ...mapActions({
            cancel: 'client/cancel',
            deleteBan: 'client/deleteBan',
            editField: 'client/editField',
            load: 'client/load',
            reset: 'client/reset',
            save: 'client/save',
        }),
        edit(e) {
            this.editField({ field: e.target.name, value: e.target.value });
        },
        toggleBan() {},
        handleCancel() {
            this.cancel();
            this.$router.go(-1);
        },
        formatDate(timestamp) {
            return moment.unix(timestamp).format('D.M.Y H:mm');
        },
        removeBan(id) {
            this.deleteBan(id);
        },
    },
};
</script>

<template>
    <div class="popup--trailer-modify-maintenance">
        <div class="popup__content">
            <notification
                :notification="notification"
                @close="setNotification(null)"
            ></notification>
            <div>
                <div class="table-scroll">
                    <data-table slim>
                        <template slot="head">
                            <data-table-cell
                                v-for="(col, index) in columns"
                                :key="index"
                                :width="col.width"
                            >
                                {{ col.title }}
                            </data-table-cell>
                        </template>
                        <template slot="body">
                            <data-table-row v-for="row in rows" :key="row.id">
                                <data-table-cell>
                                    {{ format(row.dateFrom) }}
                                </data-table-cell>
                                <data-table-cell>
                                    {{ format(row.dateTo) }}
                                </data-table-cell>
                                <data-table-cell>
                                    {{
                                        row.comments
                                            .map(i => i.content)
                                            .join(', ')
                                    }}
                                </data-table-cell>
                                <data-table-cell
                                    class="data-table__cell--unpad ar"
                                >
                                    <button
                                        class="button button--link-secondary button--icon ar"
                                        @click="remove({ row, bans })"
                                    >
                                        <i class="icon icon--times-circle"></i>
                                        Poista
                                    </button>
                                </data-table-cell>
                            </data-table-row>
                        </template>
                    </data-table>
                </div>
                <div>
                    <empty-resource v-if="!rows.length && loaded">
                        Ei huoltoja
                    </empty-resource>
                    <div v-if="!loaded" class="popup__loader">
                        <span>Ladataan...</span>
                    </div>
                </div>
                <br />
                <button class="button button--link" @click="openCreatePopup">
                    <i class="icon icon--plus-circle"></i>
                    Lisää uusi
                </button>
            </div>
        </div>
        <div class="popup__buttons">
            <button class="button button--secondary" @click="$emit('close')">
                Sulje
            </button>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import { banResource, itemResource } from '../../api';
import { formatDate } from '../../helpers';
import DataTable from '../DataTable.vue';
import DataTableRow from '../DataTable/DataTableRow.vue';
import DataTableCell from '../DataTable/DataTableCell.vue';
import EmptyResource from '../EmptyResource.vue';
import Notification from '../Notification.vue';

export default {
    name: 'PopupTrailerModifyMaintenance',
    components: {
        DataTable,
        DataTableRow,
        DataTableCell,
        EmptyResource,
        Notification,
    },
    data() {
        return {
            columns: [
                { title: 'Alkaen', width: null },
                { title: 'Päättyen', width: null },
                { title: 'Kommentti', width: null },
                { title: '', width: null },
            ],
            bans: [],
            rows: [],
            loaded: false,
        };
    },
    computed: {
        ...mapGetters({
            selected: 'trailers/selected',
            notification: 'trailers/notification',
            office: 'base/office',
        }),
    },
    mounted() {
        this.reset();
        this.loadBans();
    },
    methods: {
        ...mapActions({
            reset: 'trailers/reset',
            removeMaintenance: 'trailers/removeMaintenance',
            setNotification: 'trailers/setNotification',
        }),
        ...mapMutations({
            setPopup: 'base/SET_POPUP',
        }),
        async loadBans() {
            let res = await banResource.trailerBans.index(this.office.id, {
                unit: this.selected,
                state: 'ban',
            });
            this.bans = res.data.data;
            if (this.bans.length) {
                this.bans.forEach(async (ban) => {
                    res = await itemResource.index({
                        dateTo: `ge=${ban.dateFrom}`,
                        dateFrom: `le=${ban.dateTo}`,
                        state: 'in=active|started|returned|completed',
                        capacityUnit: this.selected,
                    });
                    this.rows = [...this.rows, ...res.data.data];
                    this.loaded = true;
                });
            } else {
                this.rows = [];
                this.loaded = true;
            }
        },
        format(timestamp) {
            return formatDate(timestamp);
        },
        openCreatePopup() {
            this.setPopup({ name: 'trailerMaintenance' });
        },
        async remove(payload) {
            await this.removeMaintenance(payload);
            await this.loadBans();
        },
    },
};
</script>

<template>
    <div v-if="log" class="reservation-log">
        <div
            class="tooltip al"
            role="tooltip"
            aria-hidden="true"
            x-placement="right"
            :style="tooltipStyle"
        >
            <div class="tooltip-arrow" style="left: -5px;top:17px;"></div>
            <div class="tooltip-inner">
                <div v-for="(row, index) in tooltipRows" :key="index">
                    {{ row }}
                </div>
            </div>
        </div>
        <h3>Viimeisimmät tapahtumat</h3>
        <ul class="reservation-log__list">
            <li
                v-for="(item, index) in log.slice(0, 3)"
                :key="index"
                class="reservation-log__list--item"
                @mouseover="mouseEnter($event, item)"
                @mouseout="mouseLeave($event, item)"
            >
                <h4>{{ formatTitle(item) }}</h4>
                <small>{{ formatDescription(item) }}</small>
            </li>
            <li class="ar">
                <button
                    v-if="log.length > 3"
                    class="button button--link"
                    @click="openLogPopup"
                >
                    Näytä kaikki tapahtumat
                </button>
            </li>
        </ul>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import {
    formatReservationLogTitle,
    formatReservationLogDescription,
    formatReservationLogTooltip,
} from '../helpers';

export default {
    name: 'ReservationLog',
    components: {},
    data() {
        return {
            states: {
                active: 'ei käsitelty',
                draft: 'luonnos',
                started: 'alkanut',
                returned: 'palautettu',
                completed: 'valmis',
                cancelled: 'peruutettu',
            },
            tooltipPos: null,
            tooltipRows: [],
        };
    },
    computed: {
        ...mapGetters({
            log: 'reservation/reservationLog',
        }),
        tooltipStyle() {
            if (!this.tooltipPos) {
                return 'display: none;';
            }

            return `
                position: fixed;
                will-change: transform;
                top: 0px;
                left: 0px;
                width: 200px;
                min-height: 52px;
                transform: translate3d(${this.tooltipPos[1] + 2}px, ${this.tooltipPos[0]}px, 0px);
            `;
        },
    },
    methods: {
        formatTitle(log) {
            return formatReservationLogTitle(log);
        },
        formatDescription(log) {
            return formatReservationLogDescription(log);
        },
        openLogPopup() {
            this.$store.commit('base/SET_POPUP', { name: 'reservationLog' });
        },
        mouseEnter(event, log) {
            this.tooltipRows = formatReservationLogTooltip(log);
            const element = event.target.parentElement.className === 'reservation-log__list--item'
                ? event.target.parentElement
                : event.target;
            const rect = element.getBoundingClientRect();
            if (this.tooltipRows.length) {
                this.tooltipPos = [
                    rect.top,
                    rect.right,
                    rect.bottom,
                    rect.left,
                ];
            }
        },
        mouseLeave() {
            this.tooltipPos = null;
            this.tooltipRows = [];
        },
    },
};
</script>

<template>
    <li class="popup--reservation-log__list--item">
        <h4>{{ formatTitle(item) }}</h4>
        <transition name="grow">
            <ul v-if="isOpen" class="popup--reservation-log__list--sublist">
                <li
                    v-for="(title, index) in getLogTitles(item)"
                    :key="`${index}`"
                >
                    {{ title }}
                </li>
            </ul>
        </transition>
        <span class="caption">{{ formatDescription(item) }}</span>
        <div
            v-if="formatTitle(item).length > 35"
            class="popup--reservation-log__list--chevron"
        >
            <i
                class="icon icon--chevron-down"
                :class="{ 'icon--chevron-up': isOpen }"
                @click="isOpen = !isOpen"
            ></i>
        </div>
    </li>
</template>

<script>
import {
    formatReservationLogTitle,
    formatReservationLogDescription,
    formatReservationLogTooltip,
} from '../../helpers';

export default {
    name: 'PopupReservationLogItem',
    props: {
        item: {
            type: Object,
            required: true,
        },
    },
    data() {
        return { isOpen: false };
    },
    methods: {
        formatTitle(log) {
            return formatReservationLogTitle(log);
        },
        formatDescription(log) {
            return formatReservationLogDescription(log);
        },
        getLogTitles(log) {
            return formatReservationLogTooltip(log);
        },
    },
};
</script>

import moment from 'moment';
import { find } from 'lodash';
import { itemResource, logs } from '../../api';
import { formatDate, loaderLoop } from '../../helpers';

const state = {
    dashboardLog: [],
    dashboardLogProcessing: false,
    inboundReservations: [],
    inboundReservationsProcessing: false,
    notification: null,
    outboundReservations: [],
    outboundReservationsProcessing: false,
    uncheckedTrailers: [],
    uncheckedTrailersProcessing: false,
};

const getters = {
    dashboardLog: (ctx) => ctx.dashboardLog,
    dashboardLogProcessing: (ctx) => ctx.dashboardLogProcessing,
    inboundReservations: (ctx) => ctx.inboundReservations,
    inboundReservationsProcessing: (ctx) => ctx.inboundReservationsProcessing,
    notification: (ctx) => ctx.notification,
    outboundReservations: (ctx) => ctx.outboundReservations,
    outboundReservationsProcessing: (ctx) => ctx.outboundReservationsProcessing,
    uncheckedTrailers: (ctx) => ctx.uncheckedTrailers,
    uncheckedTrailersProcessing: (ctx) => ctx.uncheckedTrailersProcessing,
};

const mutations = {
    SET_DASHBOARDLOG(context, payload) {
        context.dashboardLog = payload;
    },
    SET_DASHBOARDLOGPROCESSING(context, payload) {
        context.dashboardLogProcessing = payload;
    },
    SET_INBOUNDRESERVATIONS(context, payload) {
        context.inboundReservations = payload;
    },
    SET_INBOUNDRESERVATIONSPROCESSING(context, payload) {
        context.inboundReservationsProcessing = payload;
    },
    SET_NOTIFICATION(context, payload) {
        context.notification = payload;
    },
    SET_OUTBOUNDRESERVATIONS(context, payload) {
        context.outboundReservations = payload;
    },
    SET_OUTBOUNDRESERVATIONSPROCESSING(context, payload) {
        context.outboundReservationsProcessing = payload;
    },
    SET_UNCHECKEDTRAILERS(context, payload) {
        context.uncheckedTrailers = payload;
    },
    SET_UNCHECKEDTRAILERSPROCESSING(context, payload) {
        context.uncheckedTrailersProcessing = payload;
    },
};

const actions = {
    reset({ commit }) {
        commit('SET_DASHBOARDLOG', []);
        commit('SET_DASHBOARDLOGPROCESSING', false);
        commit('SET_INBOUNDRESERVATIONS', []);
        commit('SET_INBOUNDRESERVATIONSPROCESSING', false);
        commit('SET_NOTIFICATION', null);
        commit('SET_OUTBOUNDRESERVATIONS', []);
        commit('SET_OUTBOUNDRESERVATIONSPROCESSING', false);
        commit('SET_UNCHECKEDTRAILERS', []);
        commit('SET_UNCHECKEDTRAILERSPROCESSING', false);
    },
    setNotification({ commit }, notification) {
        commit('SET_NOTIFICATION', notification);
        setTimeout(() => {
            commit('SET_NOTIFICATION', null);
        }, 10000);
    },
    // async actions
    async fetchLogs({ rootState, commit, dispatch }) {
        commit('SET_DASHBOARDLOGPROCESSING', true);

        try {
            // get client bans
            const logRes = await logs.index({
                _with: 'item',
                _limit: 50,
                action: 'ban/create',
            });
            // create rows
            const clientBanLogRows = logRes.data.data.map((r) => ({
                title: `Varauskielto: ${r.data.ban.phone}`,
                comment: r.data.ban.comment,
                added: r.added,
                link: '#',
            }));

            // get office trailer bans & reservation comments
            const officeLogRes = await logs.officeLog.index(
                rootState.base.office.id,
                {
                    _with: 'item',
                    _limit: 50,
                    action: 'in=office/ban/create|item/comment/create',
                },
            );
            // create trailer bans rows
            const officeBans = officeLogRes.data.data.filter((r) => r.data.ban);
            const officeBanLogRows = officeBans.map((r) => ({
                title: `Huolto: ${r.data.ban.unit}`,
                comment: r.data.ban.comment,
                added: r.added,
                link: '#',
            }));

            // collect reservationId's from comment logs
            const comments = officeLogRes.data.data.filter((r) => r.data.comment);
            const ids = [];
            const tempCommentLogRows = comments.map((r) => {
                const reservationId = r.data.comment.links.parent.href
                    .split('/')
                    .slice(-1)[0];

                if (reservationId) {
                    ids.push(reservationId);
                }

                return {
                    title: '',
                    comment: r.data.comment.content,
                    added: r.added,
                    link: r.data.comment.links.parent.href,
                    reservationId,
                };
            });
            // fetch & repopulate data
            const reservationsRes = await itemResource.get(ids.join(','), {});
            const commentLogRows = tempCommentLogRows.map((r) => {
                const reservation = Array.isArray(reservationsRes.data.data)
                    ? find(reservationsRes.data.data, (o) => o.id === r.reservationId)
                    : reservationsRes.data.data;

                return {
                    ...r,
                    title: reservation
                        ? `Varaus: #${reservation.realId}`
                        : 'Varausta ei löytynyt',
                };
            });

            const allLogs = [
                ...clientBanLogRows,
                ...officeBanLogRows,
                ...commentLogRows,
            ];

            allLogs.sort((d1, d2) => d2.added - d1.added);

            commit('SET_DASHBOARDLOG', allLogs);
        } catch (err) {
            console.log(err);

            dispatch('setNotification', {
                title: 'Viimeisimpien tapahtumien haku epäonnistui',
                type: 'error',
            });
        }

        commit('SET_DASHBOARDLOGPROCESSING', false);
    },
    fetchReservations: async ({ rootState, commit, dispatch }, resState) => {
        if (resState === 'active') {
            commit('SET_OUTBOUNDRESERVATIONSPROCESSING', true);
        } else {
            commit('SET_INBOUNDRESERVATIONSPROCESSING', true);
        }

        const options = {
            _with: 'user,ban',
            _offset: 0,
            _limit: 20,
            _sort: state === 'active' ? '+dateFrom' : '+dateTo',
            officeId: rootState.base.office.id,
            state: resState,
            ...(resState === 'active'
                ? {
                    dateFrom: `bw=${moment()
                        .startOf('day')
                        .unix()}|${moment()
                        .endOf('day')
                        .unix()}`,
                }
                : {
                    dateTo: `bw=${moment()
                        .startOf('day')
                        .unix()}|${moment()
                        .endOf('day')
                        .unix()}`,
                }),
        };

        try {
            const res = await loaderLoop(itemResource.index, options);

            const rows = res.map((r) => {
                const { user, items } = r;
                return {
                    ban: r.ban,
                    unit:
                        items && items[0].capacityUnit
                            ? items[0].capacityUnit
                            : '-',
                    product: items && items[0].title,
                    dateFrom: r.dateFrom,
                    dateTo: r.dateTo,
                    id: r.id,
                    realId: `#${r.realId}`,
                    name: `${
                        user && user.contact ? user.contact.firstName : ''
                    } ${user && user.contact ? user.contact.lastName : ''}`,
                };
            });

            if (resState === 'active') {
                commit('SET_OUTBOUNDRESERVATIONS', rows);
            } else {
                commit('SET_INBOUNDRESERVATIONS', rows);
            }
        } catch (err) {
            console.log(err);

            dispatch('setNotification', {
                title: 'Varausten lataaminen epäonnistui',
                type: 'error',
            });
        }

        if (resState === 'active') {
            commit('SET_OUTBOUNDRESERVATIONSPROCESSING', false);
        } else {
            commit('SET_INBOUNDRESERVATIONSPROCESSING', false);
        }
    },
    async fetchUncheckedTrailers({ rootState, commit }) {
        commit('SET_UNCHECKEDTRAILERSPROCESSING', true);

        try {
            const res = await itemResource.index({
                _sort: '+dateFrom',
                officeId: rootState.base.office.id,
                state: 'returned',
            });

            const rows = res.data.data
                .filter((r) => r.items.filter((i) => i.title.indexOf('Lisäturva') === -1).length)
                .map((r) => {
                    const { items } = r;
                    let product = '-';

                    if (items.filter((i) => i.capacityUnit).length) {
                        [product] = items.map((i) => i.capacityUnit && i.title);
                    } else if (items) {
                        [product] = items.map((i) => i.title);
                    }

                    return {
                        id: r.id,
                        dateTo: r.dateTo,
                        unit: items.filter((i) => i.capacityUnit).length
                            ? items
                                .map((i) => i.capacityUnit)
                                .join(' ')
                                .trim()
                            : null,
                        product,
                    };
                });

            const getNextReservation = async () => Promise.all(
                rows.map(async (row) => {
                    if (row.unit) {
                        const { data } = await itemResource.index({
                            _sort: '+dateFrom',
                            state: 'active',
                            capacityUnit: row.unit,
                            dateFrom: `ge=${row.dateTo}`,
                        });

                        return {
                            ...row,
                            nextReservation: data.data[0]
                                ? formatDate(data.data[0].dateFrom)
                                : 'Ei vielä varauksia',
                        };
                    }

                    return {
                        ...row,
                        nextReservation: 'Ei vielä varauksia',
                    };
                }),
            );

            const data = await getNextReservation();
            commit('SET_UNCHECKEDTRAILERS', data);
        } catch (err) {
            console.log(err);
        }

        commit('SET_UNCHECKEDTRAILERSPROCESSING', false);
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};

<template>
    <div class="popup--office-select">
        <div class="popup__content">
            <p>
                Ole hyvä, ja valitse myymälä alla olevasta valikosta.
                Järjestelmässä näytetään vain valitun myymälän varaukset,
                kärryt, myyjät ja asiakkaat.
            </p>
            <span v-if="loaded" class="select-wrapper">
                <select v-model="office" class="select col-12">
                    <option :value="null">- Valitse -</option>
                    <option v-for="row in rows" :key="row.id" :value="row">
                        {{ row.title }}
                    </option>
                </select>
                <span class="icon icon--chevron-down"></span>
            </span>
            <div v-if="!loaded" class="popup__loader">
                <span>Ladataan...</span>
            </div>
        </div>

        <div class="popup__buttons">
            <button class="button button--primary" @click="$emit('close')">
                OK
            </button>
        </div>
    </div>
</template>

<script>
import { officeResource } from '../../api';

export default {
    name: 'PopupOfficeSelect',
    data() {
        return {
            loaded: false,
            rows: [],
        };
    },
    computed: {
        office: {
            get() {
                return this.$store.state.base.office;
            },
            set(value) {
                this.$store.dispatch('base/reset');
                this.$store.dispatch('reservations/reset');
                this.$store.commit('base/SET_OFFICE', value);

                setTimeout(() => window.location.reload(), 300);
            },
        },
    },
    mounted() {
        officeResource.index({ _limit: 100 }).then((res) => {
            this.rows = res.data.data;
            this.loaded = true;
        });
    },
};
</script>

<template>
    <div
        v-if="notification"
        class="notification"
        :class="{
            notification__success: notification.type === 'success',
            notification__warning: notification.type === 'warning',
            notification__error: notification.type === 'error',
        }"
    >
        <span>
            <i
                class="icon"
                :class="{
                    'icon--check-circle': notification.type === 'success',
                    'icon--exclamation-circle':
                        notification.type === 'warning' ||
                        notification.type === 'error',
                }"
            ></i>
            {{ notification.title }}
        </span>
        <button class="button" @click="$emit('close')">
            <i class="icon icon--times"></i>
        </button>
    </div>
</template>

<script>
export default {
    name: 'Notification',
    props: {
        notification: {
            type: Object,
            default: null,
        },
    },
};
</script>

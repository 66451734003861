<template>
    <div class="empty-resource"><slot></slot></div>
</template>

<script>
export default {
    name: 'EmptyResource',
    props: {},
};
</script>

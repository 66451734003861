var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"data-table__filters sb"},[_c('div',{staticClass:"data-table__filters--states"},_vm._l((_vm.lists),function(list){return _c('a',{key:list.value,staticClass:"data-table__filters--state",class:{
                    'data-table__filters--active-state':
                        list.value === _vm.currentList,
                },attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.changeList(list.value)}}},[_vm._v("\n                "+_vm._s(list.title)+"\n            ")])}),0),_vm._v(" "),_c('div',{staticClass:"data-table__filters--inputs"},[_c('div',{staticClass:"input--field"},[_c('label',[_vm._v("Asiakas")]),_vm._v(" "),_c('div',{staticClass:"input__input-icon-container"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.customer),expression:"customer"}],staticClass:"input input--small",staticStyle:{"width":"210px"},attrs:{"type":"text","placeholder":"Nimi, puhelin, sähköposti"},domProps:{"value":(_vm.customer)},on:{"blur":function($event){return _vm.load()},"input":[function($event){if($event.target.composing){ return; }_vm.customer=$event.target.value},function($event){return _vm.input(_vm.customer)}]}}),_vm._v(" "),_c('span',{staticClass:"icon icon--search"})])])])]),_vm._v(" "),_c('data-table',{staticClass:"data-table--selectable",class:{
            'data-table--bottomless': _vm.currentList === 'bans',
        }},[_c('template',{slot:"head"},_vm._l((_vm.columns),function(col,index){return _c('data-table-cell',{key:index,attrs:{"width":col.width}},[(col.sort && _vm.currentList !== 'bans')?_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.changeSort(col.sort)}}},[_vm._v("\n                    "+_vm._s(col.title)+"\n                    "),_c('i',{staticClass:"icon icon--chevron-down",class:{
                            'icon--chevron-up':
                                _vm.sort && _vm.sort === ("+" + (col.sort)),
                        }})]):_c('span',[_vm._v(_vm._s(col.title))])])}),1),_vm._v(" "),_c('template',{slot:"body"},[(!_vm.rows.length && !_vm.processing)?_c('data-table-empty',{attrs:{"col-span":"8"}},[_c('empty-resource',[_c('span',{staticClass:"icon icon--customers"}),_vm._v("\n                    Ei yhtään asiakasta valituilla kriteereillä\n                ")])],1):(_vm.processing)?_c('data-table-empty',{attrs:{"col-span":"8"}},[_c('empty-resource',[_c('span',{staticClass:"icon icon--customers"}),_vm._v("\n                    Ladataan...\n                ")])],1):_vm._l((_vm.rows),function(row,index){return _c('data-table-row',{key:index,on:{"click":function($event){return _vm.$router.push({
                        name: 'Client',
                        params: { id: row.id },
                    })}}},[_c('data-table-cell',[_vm._v("\n                    "+_vm._s(row.firstName ? row.firstName : '-')+"\n                ")]),_vm._v(" "),_c('data-table-cell',[_vm._v("\n                    "+_vm._s(row.lastName ? row.lastName : '-')+"\n                ")]),_vm._v(" "),_c('data-table-cell',[_vm._v("\n                    "+_vm._s(row.email ? row.email : '-')+"\n                ")]),_vm._v(" "),_c('data-table-cell',[_vm._v("\n                    "+_vm._s(row.phone ? row.phone : '-')+"\n                ")]),_vm._v(" "),_c('data-table-cell',[_vm._v("\n                    "+_vm._s(row.address ? row.address : '-')+"\n                ")]),_vm._v(" "),_c('data-table-cell',[_vm._v("\n                    "+_vm._s(row.zip ? row.zip : '-')+"\n                ")]),_vm._v(" "),_c('data-table-cell',[_vm._v("\n                    "+_vm._s(row.city ? row.city : '-')+"\n                ")]),_vm._v(" "),_c('data-table-cell',[_vm._v("\n                    "+_vm._s(row.created ? _vm.formatDate(row.created) : '-')+"\n                ")])],1)})],2),_vm._v(" "),(_vm.pagination && _vm.currentList !== 'bans')?_c('template',{slot:"total"},[_vm._v("\n            Asiakkaita yhteensä: "+_vm._s(_vm.pagination.total)+"\n        ")]):_vm._e(),_vm._v(" "),(_vm.pagination && _vm.currentList !== 'bans')?_c('template',{slot:"pagination"},_vm._l((_vm.truncatedPagination),function(page,index){return _c('a',{key:index,staticClass:"data-table__pagination--item",class:{
                    'data-table__pagination--active': page === _vm.currentPage,
                },attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.changePage(page)}}},[_vm._v("\n                "+_vm._s(page)+"\n            ")])}),0):_vm._e()],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
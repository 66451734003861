<template>
    <li class="popup--dashboard-log__list--item">
        <h4>{{ item.title && item.title }}</h4>
        <transition name="grow">
            <ul v-if="isOpen" class="popup--dashboard-log__list--sublist">
                <li>
                    {{ item.comment && item.comment }}
                </li>
            </ul>
        </transition>
        <span class="caption">{{ item.added && formatDate(item.added) }}</span>
        <div class="popup--dashboard-log__list--chevron">
            <i
                class="icon icon--chevron-down"
                :class="{ 'icon--chevron-up': isOpen }"
                @click="isOpen = !isOpen"
            ></i>
        </div>
    </li>
</template>

<script>
import { formatDate as format } from '../../helpers';

export default {
    name: 'PopupDashboardLogItem',
    props: {
        item: {
            type: Object,
            required: true,
        },
    },
    data() {
        return { isOpen: false };
    },
    methods: {
        formatDate(timestamp) {
            return format(timestamp);
        },
    },
};
</script>

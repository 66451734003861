<template>
    <div id="app" class="app">
        <Login v-if="!isLoggedIn"></Login>
        <template v-if="isLoggedIn">
            <Popup></Popup>
            <NavigationBar></NavigationBar>
            <div class="app__container">
                <Header></Header>
                <vue-progress-bar></vue-progress-bar>
                <router-view></router-view>
            </div>
        </template>
    </div>
</template>

<script>
import Header from './Header.vue';
import NavigationBar from './NavigationBar.vue';
import Popup from './Popup.vue';
import Login from './Login.vue';
import { auth } from '../api';

export default {
    name: 'App',
    components: {
        Header,
        NavigationBar,
        Popup,
        Login,
    },
    computed: {
        isLoggedIn() {
            return this.$store.getters['base/apiToken'] || false;
        },
    },
    mounted() {
        this.checkLogin();
    },
    methods: {
        checkLogin() {
            if (this.isLoggedIn) {
                auth.check().catch(() => {
                    this.$store.commit('base/SET_TOKEN', false);
                });
            }
        },
    },
};
</script>

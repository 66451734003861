<template>
    <div class="login">
        <div class="login--container">
            <img
                src="../../img/logo-puuilo-transparent.png"
                height="42"
                alt="Puuilo"
            />
            <span class="login--title">Varaustenhallinta</span>
            <form @submit.prevent="login">
                <div class="login--form">
                    <h2>Kirjaudu sisään</h2>
                    <span v-if="message" class="login--message">
                        {{ message }}
                    </span>
                    <label>Tunnus</label>
                    <input v-model="email" type="email" class="input" />
                    <label>Salasana</label>
                    <input v-model="password" type="password" class="input" />

                    <div class="login--buttons">
                        <button type="submit" class="button button--primary">
                            Kirjaudu sisään
                        </button>
                    </div>
                </div>
            </form>
        </div>
        <i class="icon icon--trailers login--trailer-icon"></i>
    </div>
</template>
<script>
import { auth } from '../api';

export default {
    name: 'Login',
    data() {
        return {
            email: '',
            password: '',
            message: '',
            messageTimeout: null,
        };
    },
    methods: {
        login() {
            if (!this.email.length || !this.password.length) {
                this.setMessage('Tarkasta käyttäjätunnus ja salasana');
                return;
            }

            auth.login({
                type: 'base',
                email: this.email,
                password: this.password,
            })
                .then((res) => {
                    this.$store.commit('base/SET_TOKEN', res.data.data.token);
                })
                .catch(() => {
                    this.setMessage('Tarkasta käyttäjätunnus ja salasana');
                });
        },
        setMessage(message) {
            this.message = message;

            if (this.messageTimeout) {
                clearTimeout(this.messageTimeout);
            }

            this.messageTimeout = setTimeout(() => {
                this.message = '';
            }, 3000);
        },
    },
};
</script>

<template>
    <Card
        v-if="customer"
        heading="Asiakkaan tiedot"
        class="reservation-customer"
    >
        <template slot="content">
            <div
                v-if="ban"
                class="customer-active-ban notification notification__error"
            >
                <div>
                    <span class="captionbold">
                        {{ formatDate(ban.created) }} - Varauskielto
                    </span>
                    <router-link
                        :to="{ name: 'Client', params: { id: customer.id } }"
                        class="button button--link"
                    >
                        Poista kielto
                    </router-link>
                </div>
                <span>{{ ban.comment }}</span>
            </div>
            <div class="reservation-customer__row">
                <span class="reservation-customer__row--title">Nimi</span>
                <span class="reservation-customer__row--value">
                    {{ customer.contact.firstName }}
                    {{ customer.contact.lastName }}
                </span>
            </div>
            <div class="reservation-customer__row">
                <span class="reservation-customer__row--title">Puhelin</span>
                <span class="reservation-customer__row--value">
                    {{ customer.contact.phone }}
                </span>
            </div>
            <div class="reservation-customer__row">
                <span class="reservation-customer__row--title">Sähköposti</span>
                <span class="reservation-customer__row--value">
                    {{ customer.email }}
                </span>
            </div>
            <div class="reservation-customer__row">
                <span class="reservation-customer__row--title">Katuosoite</span>
                <span class="reservation-customer__row--value">
                    {{ customer.contact.address }}
                </span>
            </div>
            <div class="reservation-customer__row">
                <span class="reservation-customer__row--title">
                    Postitoimipaikka
                </span>
                <span class="reservation-customer__row--value">
                    {{ customer.contact.city }}
                </span>
            </div>
        </template>
        <template slot="footer">
            <router-link
                :to="{ name: 'Client', params: { id: customer.id } }"
                class="button button--link fr"
            >
                Muokkaa asiakastietoja
            </router-link>
        </template>
    </Card>
</template>
<script>
import { mapGetters } from 'vuex';
import { formatDate as format } from '../helpers';
import Card from './Card.vue';

export default {
    name: 'ReservationCustomer',
    components: {
        Card,
    },
    computed: {
        ...mapGetters({
            customer: 'reservation/reservationCustomer',
            ban: 'reservation/ban',
        }),
    },
    methods: {
        formatDate(timestamp) {
            return format(timestamp);
        },
    },
};
</script>

import moment from 'moment';
import {
    itemResource,
    officeResource,
    userResource,
    orderResource,
} from '../../api';

const createEmptyDirtyReservation = () => ({
    data: {},
    items: [],
});

const changeStateAllowed = (
    reservation,
    dirtyReservation,
    currentCapacityUnit,
) => {
    const dirtyData = dirtyReservation.data;
    const { data } = reservation;
    if (!currentCapacityUnit) {
        return false;
    }

    switch (reservation.state) {
    case 'active':
        return (
            (dirtyData['Henkilöllisyys tarkistettu'] === 'Kyllä'
                || data['Henkilöllisyys tarkistettu'] === 'Kyllä')
            && (dirtyData.trailerReleasedBy || data.trailerReleasedBy)
        );
    case 'started':
        return (
            (dirtyData.trailerReleasedBy || data.trailerReleasedBy)
            && (dirtyData.trailerReceivedBy || data.trailerReceivedBy)
        );
    case 'returned':
        return (
            (dirtyData.trailerReleasedBy || data.trailerReleasedBy)
            && (dirtyData.trailerCheckedAfter || data.trailerCheckedAfter)
        );
    default:
        return true;
    }
};

const setDateTo = (dateFrom, dateTo) => {
    let date;

    if (moment.unix(dateTo).isAfter(moment())) {
        if (moment().subtract(1, 'hours') < moment.unix(dateFrom)) {
            date = moment().format('X');
        } else {
            date = moment()
                .subtract(1, 'hours')
                .startOf('hour')
                .add(59, 'minutes')
                .format('X');
        }
    } else {
        date = dateTo;
    }

    return date;
};

const initialState = {
    additionalItems: [],
    ban: null,
    capacityUnits: [],
    currentCapacityUnit: null,
    currentComment: null,
    dirtyReservation: createEmptyDirtyReservation(),
    isDirty: false,
    notification: null,
    payment: null,
    processing: false,
    processingAdditionalItems: false,
    processingBans: false,
    processingCapacityUnits: false,
    processingReservation: false,
    reservation: null,
    stateChangeLock: true,
};

const getters = {
    additionalItems: (state) => state.additionalItems,
    ban: (state) => state.ban,
    capacityUnits: (state) => state.capacityUnits,
    currentCapacityUnit: (state) => state.currentCapacityUnit,
    currentComment: (state) => state.currentComment,
    dirtyReservation: (state) => state.dirtyReservation,
    isDirty: (state) => state.isDirty,
    notification: (state) => state.notification,
    payment: (state) => state.payment,
    processing: (state) => state.processing
        || state.processingAdditionalItems
        || state.processingBans
        || state.processingCapacityUnits
        || state.processingReservation,
    reservation: (state) => state.reservation,
    reservationCustomer: (state) => (state.reservation ? state.reservation.user : null),
    reservationLog: (state) => {
        if (!state.reservation) {
            return null;
        }

        let log = state.reservation.log.map((entry) => {
            entry.type = 'reservation';

            return entry;
        });

        if (state.reservation.payments !== undefined) {
            state.reservation.payments.forEach((payment) => {
                log = [
                    ...log,
                    ...payment.log.map((entry) => {
                        entry.type = 'payment';

                        return entry;
                    }),
                ];
            });
        }

        log.sort((a, b) => parseInt(b.created, 10) - parseInt(a.created, 10));

        return log;
    },
    reservationState: (state) => (state.reservation ? state.reservation.state : null),
    stateChangeLock: (state) => state.stateChangeLock,
};

const mutations = {
    SET_ADDITIONALITEMS(context, payload) {
        context.additionalItems = payload;
    },
    SET_BAN(context, payload) {
        context.ban = payload;
    },
    SET_CAPACITYUNITS(context, payload) {
        context.capacityUnits = payload;
    },
    SET_CURRENTCAPACITYUNIT(context, payload) {
        context.currentCapacityUnit = payload;
    },
    SET_CURRENTCOMMENT(context, payload) {
        context.currentComment = payload;
    },
    SET_DIRTYRESERVATION(context, payload) {
        context.dirtyReservation = payload;
    },
    SET_ISDIRTY(context, payload) {
        context.isDirty = payload;
    },
    SET_NOTIFICATION(context, payload) {
        context.notification = payload;
    },
    SET_PAYMENT(context, payload) {
        context.payment = payload;
    },
    SET_PROCESSING(context, payload) {
        context.processing = payload;
    },
    SET_PROCESSINGADDITIONALITEMS(context, payload) {
        context.processingAdditionalItems = payload;
    },
    SET_PROCESSINGBANS(context, payload) {
        context.processingBans = payload;
    },
    SET_PROCESSINGCAPACITYUNITS(context, payload) {
        context.processingCapacityUnits = payload;
    },
    SET_PROCESSINGRESERVATION(context, payload) {
        context.processingReservation = payload;
    },
    SET_RESERVATION(context, payload) {
        context.reservation = payload;
    },
    SET_STATECHANGELOCK(context, payload) {
        context.stateChangeLock = payload;
    },
};

const actions = {
    reset: ({ commit }) => {
        commit('SET_ADDITIONALITEMS', []);
        commit('SET_BAN', null);
        commit('SET_CAPACITYUNITS', []);
        commit('SET_CURRENTCAPACITYUNIT', null);
        commit('SET_CURRENTCOMMENT', null);
        commit('SET_DIRTYRESERVATION', createEmptyDirtyReservation());
        commit('SET_ISDIRTY', false);
        commit('SET_NOTIFICATION', null);
        commit('SET_PAYMENT', null);
        commit('SET_PROCESSING', false);
        commit('SET_PROCESSINGADDITIONALITEMS', false);
        commit('SET_PROCESSINGBANS', false);
        commit('SET_PROCESSINGCAPACITYUNITS', false);
        commit('SET_PROCESSINGRESERVATION', false);
        commit('SET_RESERVATION', null);
        commit('SET_STATECHANGELOCK', true);
    },
    cancelReservation({ state, dispatch }, { name }) {
        state.dirtyReservation = {
            ...state.dirtyReservation,
            state: 'cancelled',
            logData: {
                name,
            },
        };

        dispatch('save').then(
            // eslint-disable-next-line
            result => {
                dispatch('setNotification', {
                    title: 'Varaus mitätöity',
                    type: 'success',
                });
            },
            (error) => {
                console.log(error);

                dispatch('setNotification', {
                    title: 'Varauksen mitätöinti epäonnistui',
                    type: 'error',
                });
            },
        );
    },
    editField({ commit, state, dispatch }, { field, value }) {
        if (['dateFrom', 'dateTo'].includes(field)) {
            state.dirtyReservation = {
                ...state.dirtyReservation,
                [field]: value,
            };
            dispatch('fetchCapacityUnits');
        } else if (
            [
                'trailerReleasedBy',
                'trailerReceivedBy',
                'trailerCheckedAfter',
                'Henkilöllisyys tarkistettu',
            ].includes(field)
        ) {
            state.dirtyReservation.data = {
                ...state.dirtyReservation.data,
                [field]: value,
            };
        }

        commit('SET_ISDIRTY', true);
        commit(
            'SET_STATECHANGELOCK',
            !changeStateAllowed(
                state.reservation,
                state.dirtyReservation,
                state.currentCapacityUnit,
            ),
        );
    },
    setCapacityUnit({ commit, state }, value) {
        commit('SET_CURRENTCAPACITYUNIT', value);
        commit(
            'SET_STATECHANGELOCK',
            !changeStateAllowed(
                state.reservation,
                state.dirtyReservation,
                state.currentCapacityUnit,
            ),
        );
        commit('SET_ISDIRTY', true);
    },
    setComment({ commit }, value) {
        commit('SET_CURRENTCOMMENT', value);
        commit('SET_ISDIRTY', true);
    },
    setNotification({ commit }, notification) {
        commit('SET_NOTIFICATION', notification);
        setTimeout(() => {
            commit('SET_NOTIFICATION', null);
        }, 10000);
    },
    stepForward({ state, dispatch }) {
        const { reservation, dirtyReservation } = state;
        // set state based on current state
        const stateMap = {
            active: {
                next: 'started',
                notification: 'Varaus aloitettu',
                error: 'Varauksen aloittaminen epäonnistui',
            },
            started: {
                next: 'returned',
                notification: 'Kärry palautettu',
                error: 'Kärryn palautus epäonnistui',
            },
            returned: {
                next: 'completed',
                notification: 'Varaus merkitty valmiiksi',
                error: 'Varauksen lopettaminen epäonnistui',
            },
            completed: { next: 'completed', notification: null },
            cancelled: { next: 'cancelled', notification: null },
        };

        const dateFrom = (dirtyReservation && dirtyReservation.dateFrom) || reservation.dateFrom;
        const dateTo = (dirtyReservation && dirtyReservation.dateTo) || reservation.dateTo;
        state.dirtyReservation = {
            ...state.dirtyReservation,
            state: stateMap[reservation.state].next,
        };

        if (reservation.state === 'started') {
            state.dirtyReservation = {
                ...state.dirtyReservation,
                dateTo: setDateTo(dateFrom, dateTo),
            };
        }

        dispatch('save').then(
            // eslint-disable-next-line
            result => {
                if (stateMap[reservation.state].notification) {
                    dispatch('setNotification', {
                        title: stateMap[reservation.state].notification,
                        type: 'success',
                    });
                }

                if (reservation.state === 'returned') {
                    itemResource.notifications.save(reservation.id, {
                        type: 'completed',
                    });
                }

                if (stateMap[reservation.state].next === 'started') {
                    window.open(reservation.links.contract.href, '_blank');
                }
            },
            (error) => {
                console.log(error);
                if (stateMap[reservation.state].error && !error.msg) {
                    dispatch('setNotification', {
                        title: stateMap[reservation.state].error,
                        type: 'error',
                    });
                }
            },
        );
    },
    updateAdditionalItem({ state, commit }, item) {
        const additionals = state.additionalItems.map((additional) => {
            additional.selected = additional.id === item.id ? item.selected : additional.selected;
            return additional;
        });

        commit('SET_ADDITIONALITEMS', additionals);
        commit('SET_ISDIRTY', true);
    },
    // async actions
    async fetchReservation({
        commit,
        dispatch,
        state,
        getters: gtrs,
    }, id) {
        dispatch('reset');
        commit('SET_PROCESSINGRESERVATION', true);

        const res = await itemResource.get(id, { _with: 'user,log' });
        const reservation = res.data.data;
        reservation.payments = [];

        if (reservation.links.payments !== undefined) {
            for (let i = 0; i < reservation.links.payments.length; i += 1) {
                const link = reservation.links.payments[i];
                const url = link.href.split('/');
                const orderId = url[url.length - 3];
                const paymentId = url[url.length - 1];

                // eslint-disable-next-line no-await-in-loop
                const res2 = await orderResource.payments.get(
                    orderId,
                    paymentId,
                    {
                        _with: 'log',
                    },
                );

                reservation.payments.push(res2.data.data);
            }
        }

        commit('SET_RESERVATION', reservation);
        const trailerItem = reservation.items.find((i) => !!i.capacityUnit);
        if (trailerItem) {
            commit(
                'SET_CURRENTCAPACITYUNIT',
                trailerItem.capacityUnit,
            );
        }

        dispatch('fetchCapacityUnits');
        dispatch('fetchPayments');
        dispatch('fetchAdditionalItems');
        dispatch('fetchBans', gtrs.reservationCustomer);

        commit(
            'SET_STATECHANGELOCK',
            !changeStateAllowed(
                state.reservation,
                state.dirtyReservation,
                state.currentCapacityUnit,
            ),
        );
        commit('SET_PROCESSINGRESERVATION', false);
    },
    async fetchCapacityUnits({
        commit,
        dispatch,
        state,
        rootState,
    }) {
        commit('SET_PROCESSINGCAPACITYUNITS', true);

        const { reservation, dirtyReservation } = state;
        const { officeItems } = rootState.base;
        const officeItemIds = [];
        reservation.items.forEach((item) => {
            const { id } = officeItems.find((officeItem) => officeItem.shopItemId === item.shopItemId);
            if (item.capacityUnit) {
                officeItemIds.push(id);
            }
        });

        try {
            const unitRes = await itemResource.units.index(reservation.id, {
                dateFrom: dirtyReservation.dateFrom || reservation.dateFrom,
                dateTo: dirtyReservation.dateTo || reservation.dateTo,
                officeItemId: officeItemIds[0],
            });
            const units = unitRes.data.data;

            commit('SET_CAPACITYUNITS', units);

            if (
                state.currentCapacityUnit
                && units.find((u) => u.name === state.currentCapacityUnit).disabled
            ) {
                dispatch('setCapacityUnit', null);
            }

            commit('SET_PROCESSINGCAPACITYUNITS', false);
        } catch (err) {
            console.log(err);

            commit('SET_PROCESSINGCAPACITYUNITS', false);
        }
    },
    async fetchPayments({ commit, state }) {
        const orderRes = await itemResource.orders.index(state.reservation.id);
        const { data } = orderRes.data;

        commit('SET_PAYMENT', data.map((p) => p.payments.join('')).join(''));
    },
    async fetchAdditionalItems({ rootState, state, commit }) {
        commit('SET_PROCESSINGADDITIONALITEMS', true);

        const groupRes = await officeResource.groups.index(
            rootState.base.office.id,
        );
        const { data } = groupRes.data;
        const additionals = data.find((group) => group.title === 'Lisäpalvelut');
        const { officeItems } = rootState.base;
        const additionalItems = additionals.items.map(({ id, title }) => {
            const officeItemIds = [];
            state.reservation.items.forEach((item) => {
                const itemId = officeItems.find((officeItem) => officeItem.shopItemId === item.shopItemId).id;
                officeItemIds.push(itemId);
            });
            return {
                id,
                title,
                selected: !!officeItemIds.includes(id),
            };
        });

        commit('SET_ADDITIONALITEMS', additionalItems);
        commit('SET_PROCESSINGADDITIONALITEMS', false);
    },
    async fetchBans({ commit }, customer) {
        if (customer.contact && customer.contact.phone) {
            commit('SET_PROCESSINGBANS', true);

            const banResponse = await userResource.bans.index({
                phone: customer.contact.phone,
            });
            const rows = banResponse.data.data.map((r) => r);

            rows.map((r) => {
                if (r.state === 'ban') {
                    commit('SET_BAN', r);
                }

                return r;
            });

            commit('SET_PROCESSINGBANS', false);
        }
    },
    async removeComment({ dispatch, state }, id) {
        await itemResource.comments.remove(state.reservation.id, id);

        dispatch('fetchReservation', state.reservation.id);
    },
    async save({
        state,
        rootState,
        commit,
        dispatch,
    }) {
        if (!state.currentCapacityUnit) {
            dispatch('setNotification', {
                title: 'Kärryä ei valittuna!',
                type: 'error',
            });
            throw new Error('No trailer selected!');
        }
        commit('SET_PROCESSING', true);

        const { reservation, dirtyReservation, additionalItems } = state;
        const { officeItems } = rootState.base;

        // populate selected capacityUnits
        let officeItemIds = [];
        const capacityUnits = {};
        reservation.items.forEach((item) => {
            const officeItem = officeItems.find((oi) => oi.capacityUnits.length
                && oi.shopItemId === item.shopItemId);

            if (officeItem) {
                capacityUnits[officeItem.id] = state.currentCapacityUnit;
                officeItemIds.push(officeItem.id);
            }
        });

        // append or remove additional items
        additionalItems.forEach((additional) => {
            if (
                additional.selected
                && !officeItemIds.includes(additional.id)
            ) {
                officeItemIds.push(additional.id);
            }
            if (
                !additional.selected
                && officeItemIds.includes(additional.id)
            ) {
                officeItemIds = officeItemIds.filter((id) => id !== additional.id);
            }
        });

        // populate request body
        const body = {
            userId: reservation.user.id,
            userContactId: reservation.user.contact.id,
            hash: reservation.id,
            officeId: rootState.base.office.id,
            dateFrom:
                (dirtyReservation && dirtyReservation.dateFrom)
                || reservation.dateFrom,
            dateTo:
                (dirtyReservation && dirtyReservation.dateTo)
                || reservation.dateTo,
            promoCode: null,
            data: { ...reservation.data, ...dirtyReservation.data },
            logData: dirtyReservation.logData || {},
            items: officeItemIds,
            capacityUnits,
            state:
                (dirtyReservation && dirtyReservation.state)
                || reservation.state,
            _with: 'user,log',
        };

        if (body.dateTo <= body.dateFrom) {
            const error = new Error();
            error.msg = 'Varauksen päättymisaika ei voi olla ennen alkamisaikaa!';

            dispatch('setNotification', {
                title: error.msg,
                type: 'warning',
            });

            commit('SET_PROCESSING', false);

            throw error;
        }

        if (state.currentComment) {
            try {
                await itemResource.comments.save(reservation.id, {
                    content: state.currentComment,
                });

                commit('SET_CURRENTCOMMENT', null);
            } catch (err) {
                dispatch('setNotification', {
                    title: 'Varauksen muistiinpanoa ei tallennettu!',
                    type: 'error',
                });
            }
        }

        try {
            const updateRes = await itemResource.update(
                reservation.id,
                body,
            );

            commit('SET_RESERVATION', updateRes.data.data);
            commit('SET_ISDIRTY', false);
            commit(
                'SET_STATECHANGELOCK',
                !changeStateAllowed(
                    state.reservation,
                    state.dirtyReservation,
                ),
            );
            commit('SET_PROCESSING', false);
        } catch (err) {
            commit('SET_PROCESSING', false);

            throw new Error(err.msg);
        }
    },
};

export default {
    namespaced: true,
    state: initialState,
    getters,
    mutations,
    actions,
};

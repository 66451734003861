/* eslint-disable */
import http from './common';

export default {
    index: params => http.get('reservation/v1/bans', { params }),
    save: (data, params) => http.post('reservation/v1/bans', data, { params }),
    update: (id, data, params) =>
        http.put(`reservation/v1/bans/${id}`, data, { params }),
    delete: id => http.delete(`reservation/v1/bans/${id}`),
    trailerBans: {
        index: (officeId, params) =>
            http.get(`reservation/v1/offices/${officeId}/bans`, { params }),
        save: (officeId, data, params) =>
            http.post(`reservation/v1/offices/${officeId}/bans`, data, {
                params,
            }),
        update: (officeId, id, data, params) =>
            http.put(`reservation/v1/offices/${officeId}/bans/${id}`, data, {
                params,
            }),
    },
};

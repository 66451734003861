/* eslint-disable no-prototype-builtins */
import { Finnish } from 'flatpickr/dist/l10n/fi.js';
import Vue from 'vue';
import moment from 'moment';

export const formatDate = (timestamp) => moment.unix(parseInt(timestamp)).format('DD.MM.Y H:mm');

export const truncatedPagination = (pagination, currentPage) => {
    if (!pagination) {
        return [];
    }
    const nrOfPages = pagination.totalPages;

    const delta = 1;
    const range = [];
    const rangeWithDots = [];
    let l = null;

    range.push(1);

    if (nrOfPages <= 1) {
        return range;
    }

    for (let i = currentPage - delta; i <= currentPage + delta; i += 1) {
        if (i < nrOfPages && i > 1) {
            range.push(i);
        }
    }
    range.push(nrOfPages);

    // eslint-disable-next-line no-restricted-syntax
    for (const i of range) {
        if (l) {
            if (i - l === 2) {
                rangeWithDots.push(l + 1);
            } else if (i - l !== 1) {
                rangeWithDots.push('...');
            }
        }
        rangeWithDots.push(i);
        l = i;
    }

    return rangeWithDots;
};

// eslint-disable-next-line arrow-body-style
export const noop = () => {
    // eslint-disable-next-line no-useless-return
    return;
};

export const pickerConfigDateTime = {
    altFormat: 'd.m.Y H:i',
    altInput: true,
    dateFormat: 'U',
    enableTime: true,
    locale: Finnish,
    wrap: true,
};

export const pickerConfigDate = {
    altFormat: 'd.m.Y',
    altInput: true,
    dateFormat: 'U',
    enableTime: false,
    locale: Finnish,
    wrap: true,
};

export const eventBus = new Vue();

export const getReservationStateTitle = (state) => {
    const states = {
        active: 'ei käsitelty',
        draft: 'luonnos',
        started: 'alkanut',
        returned: 'palautettu',
        completed: 'valmis',
        cancelled: 'peruutettu',
    };
    return states[state];
};

export const formatReservationLogTitle = (log) => {
    if (log.type === undefined || log.type === 'reservation') {
        if (log.action === 'patched') {
            const ops = log.data.operations;
            if (!ops) {
                return 'Muutos: kuvausta ei saatavilla';
            }
            const title = ops
                .map((op) => {
                    if (op.path === '/state') {
                        return `Tila: ${getReservationStateTitle([op.value])}`;
                    }
                    if (op.path === '/userId') {
                        return 'Asiakas lisätty';
                    }
                    if (op.path === '/userContactId') {
                        return 'Yhteystieto lisätty';
                    }
                    if (op.path === '/officeId') {
                        return 'Myymälä asetettu';
                    }
                    if (op.path === '/dateFrom') {
                        return 'Alkuaika asetettu';
                    }
                    if (op.path === '/dateTo') {
                        return 'Päättymisaika asetettu';
                    }

                    return '?';
                })
                .join(' & ');
            return `${title.substring(0, 35)}${title.length > 35 ? '...' : ''}`;
        }
        if (log.action === 'updated' || log.action === 'created') {
            const { data } = log;
            const dataData = data.data;
            const rows = [];
            if (data.state) {
                rows.push(`Tila: ${getReservationStateTitle(data.state)}`);
            }
            if (data.capacityUnits) {
                rows.push(`Kärry: ${Object.values(data.capacityUnits).join(', ')}`);
            }
            if (data.dateFrom) {
                rows.push(`Alkaen: ${formatDate(data.dateFrom)}`);
            }
            if (data.dateTo) {
                rows.push(`Päättyen: ${formatDate(data.dateTo)}`);
            }
            if (dataData && dataData.trailerReleasedBy) {
                rows.push(`Kärryn luovuttaja: ${dataData.trailerReleasedBy}`);
            }
            if (dataData && dataData.trailerReceivedBy) {
                rows.push(`Kärryn vastaanottaja: ${dataData.trailerReceivedBy}`);
            }
            if (dataData && dataData.hasOwnProperty('newsletter')) {
                rows.push(
                    `Uutiskirjeen tilaus: ${
                        dataData.newsletter === 'no' ? 'Ei' : 'Kyllä'
                    }`,
                );
            }
            if (dataData && dataData.hasOwnProperty('trailerCheckedAfter')) {
                rows.push(
                    `Palautuskunto tarkastettu: ${
                        dataData.trailerCheckedAfter ? 'Kyllä' : 'Ei'
                    }`,
                );
            }
            if (
                dataData
                && dataData.hasOwnProperty('Henkilöllisyys tarkistettu')
            ) {
                rows.push(
                    `Henkilöllisyys tarkistettu: ${
                        dataData['Henkilöllisyys tarkistettu'] === 'Kyllä'
                            ? 'Kyllä'
                            : 'Ei'
                    }`,
                );
            }
            if (data.items) {
                rows.push(`Tuotteet: ${data.items.length}kpl`);
            }
            const str = rows.join(' / ');

            if (rows.length) {
                return `${str.substring(0, 35)}${str.length > 35 ? '...' : ''}`;
            }

            return log.action === 'updated'
                ? 'Varaus päivitetty'
                : 'Varaus luotu';
        }
        if (log.action === 'deleted') {
            return 'Varaus poistettu';
        }
        if (log.action === 'update-failed') {
            return 'Virhe varauksen päivityksessä';
        }
    }

    if (log.type === 'payment') {
        return `Maksu: ${log.title}`;
    }

    return '';
};

export const formatReservationLogDescription = (log) => {
    const created = moment.unix(log.created).local();
    if (log.type === undefined || log.type === 'reservation') {
        if (log.data.handlerUser) {
            return `${created.format('D.M.Y H:mm')} - ${log.data.handlerUser}`;
        }
    }
    return `${created.format('D.M.Y H:mm')} - Varausjärjestelmä`;
};

export const formatReservationLogTooltip = (log) => {
    const rows = [];
    if (log.type === undefined || log.type === 'reservation') {
        if (log.action === 'patched') {
            const ops = log.data.operations;
            if (!ops) {
                rows.push('Muutos: kuvausta ei saatavilla');
            } else {
                ops.forEach((op) => {
                    if (op.path === '/state') {
                        rows.push(`Tila: ${getReservationStateTitle([op.value])}`);
                    }
                    if (op.path === '/userId') {
                        rows.push('Asiakas lisätty');
                    }
                    if (op.path === '/userContactId') {
                        rows.push('Yhteystieto lisätty');
                    }
                    if (op.path === '/officeId') {
                        rows.push('Myymälä asetettu');
                    }
                    if (op.path === '/dateFrom') {
                        rows.push('Alkuaika asetettu');
                    }
                    if (op.path === '/dateTo') {
                        rows.push('Päättymisaika asetettu');
                    }
                });
            }
        }
        if (log.action === 'updated' || log.action === 'created') {
            const { data } = log;
            const dataData = data.data;
            if (data.state) {
                rows.push(`Tila: ${getReservationStateTitle(data.state)}`);
            }
            if (data.capacityUnits) {
                rows.push(`Kärry: ${Object.values(data.capacityUnits).join(', ')}`);
            }
            if (data.dateFrom) {
                rows.push(`Alkaen: ${formatDate(data.dateFrom)}`);
            }
            if (data.dateTo) {
                rows.push(`Päättyen: ${formatDate(data.dateTo)}`);
            }
            if (dataData && dataData.trailerReleasedBy) {
                rows.push(`Kärryn luovuttaja: ${dataData.trailerReleasedBy}`);
            }
            if (dataData && dataData.trailerReceivedBy) {
                rows.push(`Kärryn vastaanottaja: ${dataData.trailerReceivedBy}`);
            }
            if (
                dataData
                && dataData.hasOwnProperty('Henkilöllisyys tarkistettu')
            ) {
                rows.push(
                    `Henkilöllisyys tarkistettu: ${
                        dataData['Henkilöllisyys tarkistettu'] === 'Kyllä'
                            ? 'Kyllä'
                            : 'Ei'
                    }`,
                );
            }
            if (dataData && dataData.hasOwnProperty('newsletter')) {
                rows.push(
                    `Uutiskirjeen tilaus: ${
                        dataData.newsletter === 'no' ? 'Ei' : 'Kyllä'
                    }`,
                );
            }
            if (dataData && dataData.hasOwnProperty('trailerCheckedAfter')) {
                rows.push(
                    `Palautuskunto tarkastettu: ${
                        dataData.trailerCheckedAfter ? 'Kyllä' : 'Ei'
                    }`,
                );
            }
            if (data.items) {
                rows.push(
                    `Tuotteet: ${data.items.map((item) => item.title).join(', ')}`,
                );
            }
            if (data.extra && data.extra.name) {
                rows.push(`Peruuttajan nimi: ${data.extra.name}`);
            }
        }
        if (log.action === 'deleted') {
            return ['Varaus poistettu'];
        }
        if (log.action === 'update-failed') {
            return ['Virhe varauksen päivityksessä'];
        }
    }

    return rows;
};

export const loaderLoop = (resource, data = {}) => resource(data).then((response) => {
    let items = response.data.data;
    const pageCount = parseInt(response.data.pagination.totalPages);

    if (pageCount > 1) {
        const requests = [];
        const itemsPerPage = parseInt(response.data.pagination.itemsPerPage);

        for (let pageNum = 1; pageNum < pageCount; pageNum += 1) {
            requests.push(
                resource({
                    ...data,
                    _offset: itemsPerPage * pageNum,
                    _limit: itemsPerPage,
                }),
            );
        }

        return Promise.all(requests).then((responses) => {
            responses.forEach((res) => {
                items = items.concat(res.data.data);
            });

            return Promise.resolve(items);
        });
    }

    return Promise.resolve(items);
});

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('transition',{attrs:{"name":"slide-from-top"}},[_c('notification',{attrs:{"notification":_vm.notification},on:{"close":function($event){return _vm.setNotification(null)}}})],1),_vm._v(" "),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-8"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('card',{staticClass:"card--unpad",attrs:{"heading":"Tarkastamattomat kärryt"}},[(
                                _vm.uncheckedTrailers.length &&
                                    !_vm.uncheckedTrailersProcessing
                            )?_c('data-table',{staticClass:"data-table--selectable data-table--naked",attrs:{"slot":"content"},slot:"content"},[_c('template',{slot:"body"},_vm._l((_vm.uncheckedTrailers),function(row){return _c('data-table-row',{key:row.id,on:{"click":function($event){return _vm.$router.push({
                                            name: 'Reservation',
                                            params: { id: row.id },
                                        })}}},[_c('data-table-cell',[(row.unit)?_c('strong',[_vm._v(_vm._s(row.unit))]):_vm._e(),_vm._v("\n                                        "+_vm._s(row.product)+"\n                                    ")]),_vm._v(" "),_c('data-table-cell',{staticClass:"ar"},[_vm._v("\n                                        Seuraava varaus:\n                                        "),_c('strong',[_vm._v("\n                                            "+_vm._s(row.nextReservation)+"\n                                        ")])])],1)}),1)],2):(
                                !_vm.uncheckedTrailers.length &&
                                    !_vm.uncheckedTrailersProcessing
                            )?_c('empty-resource',{attrs:{"slot":"content"},slot:"content"},[_c('span',{staticClass:"icon icon--trailers"}),_vm._v("\n                            Ei tarkastamattomia kärryjä\n                        ")]):_c('empty-resource',{attrs:{"slot":"content"},slot:"content"},[_c('span',{staticClass:"icon icon--trailers"}),_vm._v("\n                            Ladataan...\n                        ")])],1)],1)]),_vm._v(" "),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6"},[_c('card',{staticClass:"card--unpad reservation-card",attrs:{"heading":"Tänään lähtevät"}},[(
                                _vm.outboundReservations.length &&
                                    !_vm.outboundReservationsProcessing
                            )?_c('data-table',{staticClass:"data-table--selectable data-table--slim data-table--naked",attrs:{"slot":"content"},slot:"content"},[_c('template',{slot:"body"},_vm._l((_vm.outboundReservations),function(row){return _c('data-table-row',{key:row.id,on:{"click":function($event){return _vm.goToReservation(row.id)}}},[_c('data-table-cell',{class:{
                                            'data-table__cell--error':
                                                row.ban &&
                                                row.ban.state === 'ban',
                                        }},[_c('strong',[_vm._v(_vm._s(row.unit))]),_vm._v(" "),_c('br'),_vm._v(" "),_c('span',{staticClass:"caption"},[_vm._v("\n                                            "+_vm._s(row.product)+"\n                                        ")])]),_vm._v(" "),(
                                            row.ban &&
                                                row.ban.state === 'ban'
                                        )?_c('data-table-cell',{staticClass:"data-table__cell--unpad banned"},[_c('span',{attrs:{"title":"Varauskielto"}},[_c('i',{staticClass:"icon icon--times-circle banned__icon"}),_vm._v(" "),_c('strong',[_vm._v("\n                                                "+_vm._s(row.name
                                                        ? row.name
                                                        : '-')+"\n                                            ")])]),_vm._v(" "),_c('br'),_vm._v(" "),_c('span',{staticClass:"caption"},[_vm._v("\n                                            "+_vm._s(row.realId)+"\n                                        ")])]):_c('data-table-cell',[_vm._v("\n                                        "+_vm._s(row.name ? row.name : '-')+"\n                                        "),_c('br'),_vm._v(" "),_c('span',{staticClass:"caption"},[_vm._v("\n                                            "+_vm._s(row.realId)+"\n                                        ")])]),_vm._v(" "),_c('data-table-cell',{staticClass:"fs-sm"},[_c('span',{staticClass:"caption"},[_vm._v("\n                                            Varaus alkaa:\n                                        ")]),_vm._v(" "),_c('br'),_vm._v("\n                                        "+_vm._s(_vm.formatDate(row.dateFrom))+"\n                                    ")])],1)}),1)],2):(
                                !_vm.outboundReservations.length &&
                                    !_vm.outboundReservationsProcessing
                            )?_c('empty-resource',{attrs:{"slot":"content"},slot:"content"},[_c('span',{staticClass:"icon icon--trailers"}),_vm._v("\n                            Ei yhtään lähtöä tänään\n                        ")]):_c('empty-resource',{attrs:{"slot":"content"},slot:"content"},[_c('span',{staticClass:"icon icon--trailers"}),_vm._v("\n                            Ladataan...\n                        ")]),_vm._v(" "),_c('div',{attrs:{"slot":"footer"},slot:"footer"},[_c('button',{staticClass:"button button--primary",on:{"click":function($event){return _vm.navigate('outbound-list')}}},[_vm._v("\n                                Näytä kaikki\n                            ")]),_vm._v(" "),_c('button',{staticClass:"button button--secondary",on:{"click":function($event){return _vm.navigate('cal')}}},[_vm._v("\n                                Varauskalenteri\n                            ")])])],1)],1),_vm._v(" "),_c('div',{staticClass:"col-6"},[_c('card',{staticClass:"card--unpad reservation-card",attrs:{"heading":"Tänään palautuvat"}},[(
                                _vm.inboundReservations.length &&
                                    !_vm.inboundReservationsProcessing
                            )?_c('data-table',{staticClass:"data-table--selectable data-table--slim data-table--naked",attrs:{"slot":"content"},slot:"content"},[_c('template',{slot:"body"},_vm._l((_vm.inboundReservations),function(row){return _c('data-table-row',{key:row.id,on:{"click":function($event){return _vm.goToReservation(row.id)}}},[_c('data-table-cell',{class:{
                                            'data-table__cell--error':
                                                row.ban &&
                                                row.ban.state === 'ban',
                                        }},[_c('strong',[_vm._v(_vm._s(row.unit))]),_vm._v(" "),_c('br'),_vm._v(" "),_c('span',{staticClass:"caption"},[_vm._v("\n                                            "+_vm._s(row.product)+"\n                                        ")])]),_vm._v(" "),(
                                            row.ban &&
                                                row.ban.state === 'ban'
                                        )?_c('data-table-cell',{staticClass:"data-table__cell--unpad banned"},[_c('span',{attrs:{"title":"Varauskielto"}},[_c('i',{staticClass:"icon icon--times-circle banned__icon"}),_vm._v(" "),_c('strong',[_vm._v("\n                                                "+_vm._s(row.name
                                                        ? row.name
                                                        : '-')+"\n                                            ")])]),_vm._v(" "),_c('br'),_vm._v(" "),_c('span',{staticClass:"caption"},[_vm._v("\n                                            "+_vm._s(row.realId)+"\n                                        ")])]):_c('data-table-cell',[_vm._v("\n                                        "+_vm._s(row.name ? row.name : '-')+"\n                                        "),_c('br'),_vm._v(" "),_c('span',{staticClass:"caption"},[_vm._v("\n                                            "+_vm._s(row.realId)+"\n                                        ")])]),_vm._v(" "),_c('data-table-cell',{staticClass:"fs-sm"},[_c('span',{staticClass:"caption"},[_vm._v("\n                                            Varaus päättyy:\n                                        ")]),_vm._v(" "),_c('br'),_vm._v("\n                                        "+_vm._s(_vm.formatDate(row.dateTo))+"\n                                    ")])],1)}),1)],2):(
                                !_vm.inboundReservations.length &&
                                    !_vm.inboundReservationsProcessing
                            )?_c('empty-resource',{attrs:{"slot":"content"},slot:"content"},[_c('span',{staticClass:"icon icon--trailers"}),_vm._v("\n                            Ei yhtään palautusta tänään\n                        ")]):_c('empty-resource',{attrs:{"slot":"content"},slot:"content"},[_c('span',{staticClass:"icon icon--trailers"}),_vm._v("\n                            Ladataan...\n                        ")]),_vm._v(" "),_c('div',{attrs:{"slot":"footer"},slot:"footer"},[_c('button',{staticClass:"button button--primary",on:{"click":function($event){return _vm.navigate('inbound-list')}}},[_vm._v("\n                                Näytä kaikki\n                            ")])])],1)],1)])]),_vm._v(" "),_c('div',{staticClass:"col-4"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('card',{staticClass:"card--overflow",attrs:{"heading":"Varausten pikahaku"}},[_c('reservation-search',{attrs:{"slot":"content"},slot:"content"})],1)],1)]),_vm._v(" "),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"tooltip al",style:(_vm.tooltipStyle),attrs:{"role":"tooltip","aria-hidden":"true","x-placement":"left"}},[_c('div',{staticClass:"tooltip-arrow"}),_vm._v(" "),_c('div',{staticClass:"tooltip-inner"},_vm._l((_vm.tooltipRows),function(row,index){return _c('div',{key:index},[_vm._v("\n                                "+_vm._s(row)+"\n                            ")])}),0)]),_vm._v(" "),_c('h2',{staticClass:"display2"},[_vm._v("Viimeisimmät tapahtumat")]),_vm._v(" "),(
                            _vm.dashboardLog.length && !_vm.dashboardLogProcessing
                        )?_c('ul',{staticClass:"dashboard-log__list"},[_vm._l((_vm.dashboardLog.slice(
                                0,
                                8
                            )),function(item,index){return _c('li',{key:index,staticClass:"dashboard-log__list--item",on:{"mouseover":function($event){return _vm.mouseEnter($event, item)},"mouseout":function($event){return _vm.mouseLeave($event, item)}}},[_c('h4',[_vm._v(_vm._s(item.title))]),_vm._v(" "),_c('small',[_vm._v(_vm._s(_vm.formatDate(item.added)))])])}),_vm._v(" "),_c('li',{staticClass:"ar"},[(_vm.dashboardLog.length > 8)?_c('button',{staticClass:"button button--link",on:{"click":_vm.openLogPopup}},[_vm._v("\n                                Näytä kaikki tapahtumat\n                            ")]):_vm._e()])],2):_vm._e(),_vm._v(" "),(_vm.dashboardLogProcessing)?_c('ul',{staticClass:"dashboard-log__list"},[_c('li',[_vm._v("Ladataan...")])]):_vm._e()])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }
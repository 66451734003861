import { flatten } from 'lodash';
import { userResource } from '../../api';
import { truncatedPagination as paginationHelper } from '../../helpers';

const emptyPagination = {
    total: 0,
    totalPages: 0,
};

const initialState = {
    currentList: 'clients',
    currentPage: 1,
    customer: null,
    limit: 10,
    pagination: emptyPagination,
    processing: false,
    rows: [],
    sort: '-created',
};

const getters = {
    currentList: (state) => state.currentList,
    currentPage: (state) => state.currentPage,
    limit: (state) => state.limit,
    pagination: (state) => state.pagination,
    processing: (state) => state.processing,
    rows: (state) => state.rows,
    sort: (state) => state.sort,
    truncatedPagination: (state) => paginationHelper(state.pagination, state.currentPage),
};

const mutations = {
    SET_CURRENTLIST(context, payload) {
        context.currentList = payload;
    },
    SET_CURRENTPAGE(context, payload) {
        context.currentPage = payload;
    },
    SET_CUSTOMER(context, payload) {
        context.customer = payload;
    },
    SET_LIMIT(context, payload) {
        context.limit = payload;
    },
    SET_PAGINATION(context, payload) {
        context.pagination = payload;
    },
    SET_PROCESSING(context, payload) {
        context.processing = payload;
    },
    SET_ROWS(context, payload) {
        context.rows = payload;
    },
    SET_SORT(context, payload) {
        context.sort = payload;
    },
};

const actions = {
    reset({ commit }) {
        commit('SET_CURRENTPAGE', 1);
        commit('SET_LIMIT', 10);
        commit('SET_PAGINATION', emptyPagination);
        commit('SET_PROCESSING', false);
        commit('SET_ROWS', []);
    },
    softReset({ commit }) {
        commit('SET_PROCESSING', false);
        commit('SET_ROWS', []);
    },
    changeList({ commit, dispatch }, value) {
        commit('SET_CURRENTLIST', value);
        commit('SET_CURRENTPAGE', 1);

        if (value === 'bans') {
            dispatch('loadBanned');
        } else {
            dispatch('load');
        }
    },
    changePage({ commit, dispatch }, page) {
        if (page === '...') {
            return;
        }

        commit('SET_CURRENTPAGE', page);
        dispatch('load');
    },
    changeSort({ commit, dispatch, state }, field) {
        const sort = state.sort && state.sort === `+${field}`
            ? `-${field}`
            : `+${field}`;

        commit('SET_CURRENTPAGE', 1);
        commit('SET_SORT', sort);
        dispatch('load');
    },
    // async actions
    async load({ commit, state }) {
        commit('SET_PROCESSING', true);
        commit('SET_CURRENTLIST', 'clients');

        const options = {
            _offset: (state.currentPage - 1) * state.limit,
            _limit: state.limit,
            ...(state.sort && { _sort: state.sort }),
            ...(state.customer && { _q: state.customer }),
        };
        const res = await userResource.index(options);
        const rows = res.data.data.map((r) => {
            const { contact } = r;
            return {
                id: r.id,
                firstName: contact.firstName,
                lastName: contact.lastName,
                email: r.email,
                phone: contact.phone,
                address: contact.address,
                zip: contact.postCode,
                city: contact.city,
                created: r.created,
            };
        });

        commit('SET_ROWS', rows);
        commit('SET_PAGINATION', res.data.pagination);
        commit('SET_PROCESSING', false);
    },
    async loadBanned({ commit }) {
        commit('SET_PROCESSING', true);
        commit('SET_CUSTOMER', null);

        const options = {
            phone: '*',
            _with: 'user',
            state: 'ban',
        };

        const res = await userResource.bans.index(options);

        const rows = flatten(res.data.data.map((r) => r.users));
        const bannedClients = rows.map((r) => ({
            id: r.id,
            firstName: r.contact.firstName,
            lastName: r.contact.lastName,
            email: r.email,
            phone: r.contact.phone,
            address: r.contact.address,
            zip: r.contact.postCode,
            city: r.contact.city,
            created: r.created,
        }));

        commit('SET_ROWS', bannedClients);
        commit('SET_PROCESSING', false);
    },
};

export default {
    namespaced: true,
    state: initialState,
    getters,
    mutations,
    actions,
};

<template>
    <div class="popup--reservation-cancel-confirm">
        <div class="popup__content">
            <p>
                Tahdotko varmasti mitätöidä varauksen? Varausta ei voi enää
                tämän jälkeen palauttaa aktiiviseksi.
            </p>

            <div class="field">
                <div>
                    <label>
                        Varauksen peruuttaja
                        <span class="asterisk">*</span>
                    </label>
                    <input v-model="name" class="input" type="text" />
                </div>
            </div>
        </div>

        <div class="popup__buttons">
            <button class="button button--link" @click="$emit('close')">
                Peruuta
            </button>
            <button
                class="button button--primary"
                :disabled="isDisabled"
                @click="sendCancelRequest()"
            >
                Mitätöi varaus
            </button>
        </div>
    </div>
</template>

<script>
import { eventBus } from '../../helpers';

export default {
    name: 'PopupReservationCancelConfirm',
    data() {
        return {
            name: '',
        };
    },
    computed: {
        isDisabled() {
            return !this.name.length;
        },
    },
    methods: {
        sendCancelRequest() {
            eventBus.$emit('CANCEL_RESERVATION', { name: this.name });
            this.$emit('close');
        },
    },
};
</script>

import { flatten } from 'lodash';
import moment from 'moment';
import { itemResource, userResource, banResource } from '../../api';
import { eventBus, formatDate } from '../../helpers';

const initialState = {
    bans: [],
    comment: null,
    dateFrom: null,
    dateTo: null,
    reservationApprovedRows: [],
    reservationRows: [],
    selected: null,
};

const getters = {
    bans: (state) => state.bans,
    comment: (state) => state.comment,
    dateFrom: (state) => state.dateFrom,
    dateTo: (state) => state.dateTo,
    notification: (state) => state.notification,
    reservationApprovedRows: (state) => state.reservationApprovedRows,
    reservationRows: (state) => state.reservationRows,
    rows: (state, gtrs, rootState) => (
        rootState.base.officeItems
            ? flatten(rootState.base.officeItems.map((item) => item.capacityUnits.map((unit) => ({
                trailer: unit,
                description: item.title,
            }))))
            : []
    ),
    selected: (state) => state.selected,
};

const mutations = {
    SET_BANS(context, payload) {
        context.bans = payload;
    },
    SET_COMMENT(context, payload) {
        context.comment = payload;
    },
    SET_DATEFROM(context, payload) {
        context.dateFrom = payload;
    },
    SET_DATETO(context, payload) {
        context.dateTo = payload;
    },
    SET_NOTIFICATION(context, payload) {
        context.notification = payload;
    },
    SET_RESERVATION_ROWS(context, payload) {
        context.reservationRows = payload;
    },
    SET_RESERVATION_APPROVED_ROWS(context, payload) {
        context.reservationApprovedRows = payload;
    },
    SET_SELECTED(context, payload) {
        context.selected = payload;
    },
};

const actions = {
    reset({ commit }) {
        commit('SET_COMMENT', null);
        commit('SET_DATEFROM', null);
        commit('SET_DATETO', null);
        commit('SET_NOTIFICATION', null);
        commit('SET_RESERVATION_APPROVED_ROWS', []);
        commit('SET_RESERVATION_ROWS', []);
    },
    editField({ dispatch, commit }, { field, value }) {
        if (field === 'dateFrom') {
            commit('SET_DATEFROM', value);
            dispatch('load');
        } else if (field === 'dateTo') {
            commit('SET_DATETO', value);
            dispatch('load');
        } else if (field === 'comment') {
            commit('SET_COMMENT', value);
        }
    },
    setNotification({ commit }, notification) {
        commit('SET_NOTIFICATION', notification);
        setTimeout(() => {
            commit('SET_NOTIFICATION', null);
        }, 10000);
    },
    // async actions
    async load({ commit, state, rootState }) {
        const options = {
            _with: 'user',
            state: 'in=active|started|returned|completed',
            capacityUnit: state.selected,
            ...state.dateFrom ? {
                dateFrom: `le=${state.dateTo}`,
            } : {},
            ...state.dateTo ? {
                dateTo: `ge=${moment
                    .unix(state.dateFrom)
                    .subtract(1, 'hours')
                    .format('X')}`,
            } : {},
            ...(rootState.base.office && {
                officeId: rootState.base.office.id,
            }),
        };
        const res = await itemResource.index(options);
        const rows = res.data.data.map((r) => {
            const { user, items } = r;
            return {
                unit: items
                    ? items
                        .map((i) => i.capacityUnit)
                        .join(' ')
                        .trim()
                    : '',
                dateFrom: r.dateFrom,
                dateTo: r.dateTo,
                id: r.id,
                realId: `#${r.realId}`,
                name: `${user && user.contact ? user.contact.firstName : ''} ${
                    user && user.contact ? user.contact.lastName : ''
                }`,
                email: user ? user.email : '',
                phone: user && user.contact ? user.contact.phone : '',
                items,
            };
        });
        commit('SET_RESERVATION_APPROVED_ROWS', []);
        commit('SET_RESERVATION_ROWS', rows);
    },
    async loadBans({ commit, rootState }) {
        const res = await banResource.trailerBans.index(
            rootState.base.office.id,
            { state: 'ban' },
        );
        const now = moment().unix();
        commit(
            'SET_BANS',
            res.data.data.filter((i) => i.dateFrom && i.dateTo && i.dateFrom <= now <= i.dateTo),
        );
    },
    async updateUnit({
        rootState,
        state,
        commit,
        dispatch,
    }, { row, unit }) {
        // fetch the reservation to make sure we have fresh data
        const res = await itemResource.get(row.id);
        const reservation = res.data.data;
        const { officeItems } = rootState.base;

        // set reservation items and capacityUnits for the request
        const officeItemIds = [];
        const capacityUnits = {};
        reservation.items.forEach((item) => {
            const { id } = officeItems.find((officeItem) => officeItem.shopItemId === item.shopItemId);
            capacityUnits[id] = unit;
            officeItemIds.push(id);
        });

        // construct request body
        const body = {
            userId: reservation.user.id,
            userContactId: reservation.user.contact.id,
            hash: reservation.id,
            officeId: rootState.base.office.id,
            dateFrom: reservation.dateFrom,
            dateTo: reservation.dateTo,
            promoCode: null,
            data: reservation.data,
            items: officeItemIds,
            capacityUnits,
            state: reservation.state,
        };

        // update the reservation with new capacityUnit
        try {
            await itemResource.update(reservation.id, body);

            // mark reservation as "ok" if it really is
            const approvedRows = state.reservationApprovedRows;
            if (unit === state.selected) {
                commit(
                    'SET_RESERVATION_APPROVED_ROWS',
                    approvedRows.filter((r) => r.id !== row.id),
                );
            } else {
                commit('SET_RESERVATION_APPROVED_ROWS', [...approvedRows, row]);
            }
            eventBus.$emit('reservation-unit-change');
        } catch (err) {
            // if something went wrong give warning
            dispatch('setNotification', {
                title: 'Kärryn vaihtamisessa tapahtui virhe!',
                type: 'error',
            });
        }
    },
    async createMaintenance({
        state,
        rootState,
        commit,
        dispatch,
    }) {
        if (state.dateTo <= state.dateFrom) {
            dispatch('setNotification', {
                title:
                    'Huoltoajan päättymisaika ei voi olla ennen alkamisaikaa!',
                type: 'warning',
            });

            throw new Error();
        }

        const maintenanceEmail = 'huoltotila@ktrl.fi';

        // create user for maintenance reservation if needed
        let userRes = await userResource.index({ email: maintenanceEmail });
        let user = userRes.data.data;

        if (!user.length) {
            userRes = await userResource.save({
                email: 'huoltotila@ktrl.fi',
                company: null,
                companyIdent: null,
                contact: {
                    firstName: 'Kärry',
                    lastName: 'Huollossa',
                    phone: '',
                    address: '',
                    city: '',
                    postCode: '',
                    countryId: 207,
                },
            });
            user = userRes.data.data;
        } else {
            [user] = user;
        }
        // set reservation items and capacityUnits
        const items = [];
        const capacityUnits = {};
        rootState.base.officeItems.forEach((item) => {
            if (item.capacityUnits.find((i) => i === state.selected)) {
                items.push(item.id);
                capacityUnits[item.id] = state.selected;
            }
        });
        // construct request body
        const body = {
            userId: user.id,
            userContactId: user.contact.id,
            officeId: rootState.base.office.id,
            dateFrom: state.dateFrom,
            dateTo: state.dateTo,
            promoCode: null,
            data: { isMaintenanceReservation: true },
            state: 'completed',
            items,
            capacityUnits,
        };
        // create maintenance reservation
        const res = await itemResource.save(body);
        const maintenanceRes = res.data.data;

        // save comment to reservation
        if (state.comment) {
            await itemResource.comments.save(maintenanceRes.id, {
                content: state.comment,
            });
        }
        // create ban record for trailer
        const banRes = await banResource.trailerBans.save(
            rootState.base.office.id,
            {
                dateFrom: maintenanceRes.dateFrom,
                dateTo: maintenanceRes.dateTo,
                unit: state.selected,
                comment: state.comment,
            },
        );

        if (maintenanceRes.dateFrom <= moment().unix() <= maintenanceRes.dateTo) {
            commit('SET_BANS', [...state.bans, banRes.data.data]);
        }
        setTimeout(() => {
            dispatch('setNotification', {
                title: `Huoltotila lisätty kärrylle ${
                    state.selected
                }: ${formatDate(maintenanceRes.dateFrom)} – ${formatDate(
                    maintenanceRes.dateTo,
                )}`,
                type: 'success',
            });
        }, 500);
    },
    async removeMaintenance({ rootState, dispatch }, maintenance) {
        // populate selected capacityUnits
        const { officeItems } = rootState.base;
        const officeItemIds = [];
        const capacityUnits = {};
        maintenance.row.items.forEach((item) => {
            const { id } = officeItems.find((officeItem) => officeItem.shopItemId === item.shopItemId);
            capacityUnits[id] = item.capacityUnit;
            officeItemIds.push(id);
        });

        // Cancel maintenance reservation
        await itemResource.update(maintenance.row.id, {
            userId: maintenance.row.user.id,
            userContactId: maintenance.row.user.contact.id,
            hash: maintenance.row.id,
            officeId: rootState.base.office.id,
            dateFrom: maintenance.row.dateFrom,
            dateTo: maintenance.row.dateTo,
            promoCode: null,
            data: maintenance.row.data,
            items: officeItemIds,
            capacityUnits,
            state: 'cancelled',
        });

        // remove ban
        maintenance.bans.forEach(async (ban) => {
            await banResource.trailerBans.update(
                rootState.base.office.id,
                ban.id,
                { state: 'inactive' },
            );
        });
        dispatch('setNotification', {
            title: 'Huoltotila poistettu',
            type: 'success',
        });
        eventBus.$emit('maintenance-removed');
    },
};

export default {
    namespaced: true,
    state: initialState,
    getters,
    mutations,
    actions,
};

<template>
    <div class="container reservations-container">
        <transition name="slide-from-top">
            <notification
                :notification="notification"
                @close="setNotification(null)"
            ></notification>
        </transition>
        <div class="reservations-switch">
            <button
                class="reservations-switch--button"
                :class="{ 'reservations-switch--active': type === 'list' }"
                @click="type = 'list'"
            >
                <i class="icon icon--list"></i>
                Lista
            </button>
            <button
                class="reservations-switch--button"
                :class="{ 'reservations-switch--active': type === 'calendar' }"
                @click="type = 'calendar'"
            >
                <i class="icon icon--calendar-alt"></i>
                Kalenteri
            </button>
        </div>
        <reservations-list v-if="type === 'list'"></reservations-list>
        <reservations-cal v-if="type === 'calendar'"></reservations-cal>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ReservationsList from '../components/ReservationsList.vue';
import ReservationsCal from '../components/ReservationsCal.vue';
import Notification from '../components/Notification.vue';

export default {
    name: 'Reservations',
    components: {
        ReservationsList,
        ReservationsCal,
        Notification,
    },
    computed: {
        ...mapGetters({
            notification: 'reservations/notification',
        }),
        type: {
            get() {
                return this.$store.state.reservations.type;
            },
            set(value) {
                this.$store.commit('reservations/SET_TYPE', value);
            },
        },
    },
    methods: {
        ...mapActions({
            setNotification: 'reservations/setNotification',
        }),
    },
};
</script>

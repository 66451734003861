import Vue from 'vue';
import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';
import store from './store';
import router from './router';
import App from './components/App.vue';
import '../scss/main.scss';

if (process.env.SENTRY_KEY !== undefined) {
    Sentry.init({
        release: process.env.RELEASE,
        dsn: process.env.SENTRY_KEY,
        environment: 'production',
        integrations: [new Integrations.Vue({ Vue, attachProps: true })],
    });
}

document.addEventListener('DOMContentLoaded', () => {
    if (document.getElementById('app')) {
        new Vue({
            store,
            router,
            render: (h) => h(App),
        }).$mount('#app');
    }
});

<template>
    <card
        v-if="reservation"
        heading="Muut tiedot"
        class="card--unpad reservation-other-info"
    >
        <div slot="content">
            <div v-if="reservation" class="reservation-other-info__item">
                <label>
                    Henkilöllisyys tarkistettu
                    <span
                        v-if="reservation.state === 'active' && !readOnly"
                        class="asterisk"
                    >
                        *
                    </span>
                </label>
                <toggle v-if="reservation.state === 'active' && !readOnly">
                    <input v-model="identityChecked" type="checkbox" />
                </toggle>
                <span v-if="reservation.state !== 'active' || readOnly">
                    {{ identityChecked ? 'Kyllä' : 'Ei' }}
                </span>
            </div>
            <div
                v-for="item in items"
                :key="item.id"
                class="reservation-other-info__item"
            >
                <label>
                    {{ item.title }}
                    <br />
                    <span class="reservation-other-info__item__description">
                        Omavastuu 0€ (norm. 150 euroa)
                    </span>
                </label>

                <toggle
                    v-if="['active'].includes(reservation.state) && !readOnly"
                >
                    <input
                        type="checkbox"
                        :checked="item.selected"
                        @change="setItem($event, item)"
                    />
                </toggle>
                <span v-else>{{ item.selected ? 'Kyllä' : 'Ei' }}</span>
            </div>
            <div v-if="showAfterActions" class="reservation-other-info__item">
                <label>
                    Kärryn palautuskunto tarkastettu (Tarkastuslista)
                    <span
                        v-if="reservation.state === 'returned' && !readOnly"
                        class="asterisk"
                    >
                        *
                    </span>
                </label>
                <div class="reservation-other-info__item--right">
                    <button
                        v-if="
                            ['completed'].includes(reservation.state) &&
                                !isBanned &&
                                !readOnly
                        "
                        class="button button--link"
                        @click="openCreatePopup()"
                    >
                        <i class="icon icon--plus-circle" />
                        Aseta huoltotila
                    </button>
                    <button
                        v-if="
                            ['completed'].includes(reservation.state) &&
                                isBanned &&
                                !readOnly
                        "
                        class="button button--link-secondary"
                        @click="
                            $router.push({
                                name: 'Trailers',
                            })
                        "
                    >
                        Muokkaa huoltotilaa
                    </button>
                    <toggle
                        v-if="reservation.state !== 'completed' && !readOnly"
                    >
                        <input v-model="trailerCheckedAfter" type="checkbox" />
                    </toggle>
                    <span v-else>
                        {{ trailerCheckedAfter ? 'Kyllä' : 'Ei' }}
                    </span>
                </div>
            </div>
            <div
                v-if="showAfterActions && !readOnly"
                class="reservation-other-info__item"
            >
                <label>Varauskielto</label>
                <router-link
                    :to="{
                        name: 'Client',
                        params: { id: reservation.user.id },
                    }"
                    class="button button--link button--icon danger"
                >
                    <i class="icon icon--plus-circle" />
                    Aseta varauskielto
                </router-link>
            </div>
        </div>
        <div slot="footer">
            <span class="reservation-other-info__footer--payment">
                <strong>Maksutapa: {{ payment }}</strong>
            </span>
            <div
                v-if="reservation"
                class="reservation-other-info__footer--content"
            >
                <div class="reservation-other-info__footer--price">
                    <span
                        class="reservation-other-info__footer--price--caption"
                    >
                        Varauksen hinta
                    </span>
                    <br />
                    <span
                        class="reservation-other-info__footer--price--display1"
                    >
                        {{ formatPrice(reservation.price) }}€
                    </span>
                </div>
                <div
                    v-if="!['active'].includes(reservation.state) && !readOnly"
                >
                    <a
                        :href="reservation.links.receipt.href"
                        target="_blank"
                        class="button button--link"
                    >
                        Tulosta kuitti
                    </a>
                    <a
                        :href="reservation.links.contract.href"
                        target="_blank"
                        class="button button--link"
                    >
                        Tulosta varaustosite
                    </a>
                </div>
            </div>
            <div
                v-if="!['cancelled'].includes(reservation.state) && !readOnly"
                class="reservation-other-info__footer-cancel"
            >
                <div>
                    <span class="reservation-other-info__footer-cancel--title">
                        Huom!
                    </span>
                    <span
                        class="reservation-other-info__footer-cancel--description"
                    >
                        Varauksen mitätöinti
                    </span>
                </div>
                <button class="button button--icon" @click="cancelConfirmation">
                    <i class="icon icon--times-circle"></i>
                    Mitätöi
                </button>
            </div>
        </div>
    </card>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import Card from './Card.vue';
import Toggle from './Toggle.vue';

export default {
    name: 'ReservationOtherInfo',
    components: {
        Card,
        Toggle,
    },
    props: {
        readOnly: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        ...mapGetters({
            items: 'reservation/additionalItems',
            payment: 'reservation/payment',
            reservation: 'reservation/reservation',
            dirtyReservation: 'reservation/dirtyReservation',
            currentCapacityUnit: 'reservation/currentCapacityUnit',
            bans: 'trailers/bans',
        }),
        isBanned() {
            return this.bans.map((i) => i.unit === this.currentCapacityUnit)[0];
        },
        identityChecked: {
            get() {
                return (
                    this.dirtyReservation.data['Henkilöllisyys tarkistettu'] === 'Kyllä'
                    || this.reservation.data['Henkilöllisyys tarkistettu'] === 'Kyllä'
                );
            },
            set(value) {
                this.edit({
                    field: 'Henkilöllisyys tarkistettu',
                    value: value ? 'Kyllä' : 'Ei',
                });
            },
        },
        showAfterActions() {
            return (
                this.reservation
                && (this.reservation.state === 'returned'
                    || this.reservation.state === 'completed')
            );
        },
        trailerCheckedAfter: {
            get() {
                return (
                    this.dirtyReservation.data.trailerCheckedAfter
                    || this.reservation.data.trailerCheckedAfter
                );
            },
            set(value) {
                this.edit({
                    field: 'trailerCheckedAfter',
                    value,
                });
            },
        },
    },
    methods: {
        ...mapActions({
            edit: 'reservation/editField',
            updateAdditionalItem: 'reservation/updateAdditionalItem',
        }),
        ...mapMutations({
            cancelConfirm: 'base/SET_POPUP',
            setPopup: 'base/SET_POPUP',
            setSelected: 'trailers/SET_SELECTED',
        }),
        addClientNotice() {
            this.$emit('addClientNotice');
        },
        addDamageReport() {
            this.$emit('addDamageReport');
        },
        formatPrice(price) {
            return price.toFixed(2).replace('.', ',');
        },
        setItem(event, item) {
            item.selected = !!event.target.checked;
            this.updateAdditionalItem(item);
        },
        cancelConfirmation() {
            this.cancelConfirm({ name: 'reservationCancelConfirm' });
        },
        openCreatePopup() {
            this.setSelected(this.currentCapacityUnit);
            this.setPopup({ name: 'trailerMaintenance' });
        },
    },
};
</script>

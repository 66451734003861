<template>
    <div class="data-table__empty">
        <td align="center" :colspan="colSpan">
            <slot></slot>
        </td>
    </div>
</template>
<script>
export default {
    name: 'DataTableEmpty',
    props: {
        colSpan: {
            type: String,
            required: true,
        },
    },
};
</script>

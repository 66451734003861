/* eslint-disable */
import http from './common';

export default {
    index: params => http.get('extranet/v2/users', { params }),
    get: (id, params) => http.get(`extranet/v2/users/${id}`, { params }),
    save: (data, params) => http.post('extranet/v2/users', data, { params }),
    update: (id, data, params) =>
        http.put(`extranet/v2/users/${id}`, data, { params }),
    contacts: {
        index: (userId, params) =>
            http.get(`extranet/v2/users/${userId}/contacts`, { params }),
        get: (userId, id, params) =>
            http.get(`extranet/v2/users/${userId}/contacts/${id}`, { params }),
        save: (userId, data, params) =>
            http.post(`extranet/v2/users/${userId}/contacts`, data, { params }),
        update: (userId, id, data, params) =>
            http.put(`extranet/v2/users/${userId}/contacts/${id}`, data, {
                params,
            }),
    },
    bans: {
        index: params => (http.get('reservation/v1/bans', { params })),
        save: (data, params) => (http.post('reservation/v1/bans', data, { params })),
        update: (id, data, params) => (http.put(`reservation/v1/bans/${id}`, data, { params })),
        delete: (id) => (http.delete(`reservation/v1/bans/${id}`)),
    }
};

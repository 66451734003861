<template>
    <div class="popup--dashboard-log">
        <ul class="popup--dashboard-log__list">
            <popup-dashboard-log-item
                v-for="(item, index) in log"
                :key="index"
                :item="item"
            ></popup-dashboard-log-item>
        </ul>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import PopupDashboardLogItem from './PopupDashboardLogItem.vue';

export default {
    name: 'PopupDashboardLog',
    components: {
        PopupDashboardLogItem,
    },
    computed: {
        ...mapGetters({
            log: 'dashboard/dashboardLog',
        }),
    },
    methods: {},
};
</script>

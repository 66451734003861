<template>
    <div v-if="state !== 'cancelled'" class="stepper caption">
        <span class="stepper__line"></span>
        <div
            v-for="(step, index) in steps"
            :key="step"
            :class="['stepper__step', progress >= index ? 'active' : '']"
        >
            <span class="stepper__circle"></span>
            {{ step }}
        </div>
    </div>
    <div v-else class="stepper stepper--cancelled">
        Varaus peruutettu
    </div>
</template>

<script>
const stepperProgress = {
    active: 0,
    started: 1,
    returned: 2,
    completed: 3,
};

export default {
    name: 'Stepper',
    props: {
        state: {
            type: String,
            default: 'active',
        },
    },
    data() {
        return {
            steps: ['Ei käsitelty', 'Alkanut', 'Palautettu', 'valmis'],
        };
    },
    computed: {
        progress() {
            const progress = stepperProgress[this.state] === undefined
                ? -1
                : stepperProgress[this.state];

            return progress;
        },
    },
};
</script>

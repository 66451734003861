<template>
    <header class="header">
        <h1 class="header__title display1">{{ title }}</h1>

        <nav class="header-nav">
            <ul class="nav-list">
                <li class="nav-list__item">
                    <a
                        :href="
                            `${appUrl}/#/${office && `?office=${office.id}`}`
                        "
                        target="_blank"
                        class="nav-list__link bodybold"
                    >
                        <span class="icon-wrapper">
                            <i class="icon icon--plus-circle" />
                        </span>
                        Uusi varaus
                    </a>
                </li>
                <li class="nav-list__item">
                    <button
                        class="nav-list__link bodybold"
                        @click="chooseOffice"
                    >
                        <span class="icon-wrapper">
                            <i class="icon icon--map-marker-alt" />
                        </span>
                        {{
                            office
                                ? `Myymälä: ${office.title}`
                                : 'Valitse myymälä'
                        }}
                    </button>
                </li>
                <li class="nav-list__item">
                    <button class="nav-list__link bodybold" @click="onLogout">
                        <span class="icon-wrapper">
                            <i class="icon icon--sign-out-alt" />
                        </span>
                        Kirjaudu ulos
                    </button>
                </li>
            </ul>
        </nav>
    </header>
</template>

<script>
import { mapActions } from 'vuex';
import { auth, officeResource } from '../api';

export default {
    name: 'Header',
    data() {
        return {
            appUrl: process.env.RESERVATION_APP_LINK,
        };
    },
    computed: {
        title() {
            const titles = {
                Dashboard: 'Etusivu',
                Reservations: 'Varaukset',
                Reservation: 'Muokkaa varausta',
                Clients: 'Asiakkaat',
                Client: 'Muokkaa asiakasta',
                Trailers: 'Kärryt',
                Salesmen: 'Myyjät',
            };
            return titles[this.$route.name];
        },
        office() {
            return this.$store.state.base.office || null;
        },
        officeLock() {
            return this.$store.state.base.officeLock;
        },
    },
    mounted() {
        officeResource
            .get('default')
            .then((res) => {
                this.$store.dispatch('base/reset');
                this.$store.dispatch('reservations/reset');
                this.$store.commit('base/SET_OFFICE', res.data.data);
                this.$store.commit('base/SET_OFFICE_LOCK', true);
            })
            .catch(() => {
                this.$store.commit('base/SET_OFFICE_LOCK', false);
                if (!this.office) {
                    this.chooseOffice();
                }
            });
        this.fetchOfficeItems();
    },
    methods: {
        ...mapActions({
            fetchOfficeItems: 'base/fetchOfficeItems',
        }),
        onLogout() {
            auth.logout().finally(() => {
                this.$store.commit('base/SET_TOKEN', false);
            });
        },
        chooseOffice() {
            if (this.officeLock) {
                return;
            }
            this.$store.commit('base/SET_POPUP', {
                name: 'officeSelect',
            });
        },
    },
};
</script>

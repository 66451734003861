<template>
    <div class="container">
        <transition name="slide-from-top">
            <notification
                :notification="notification"
                @close="setNotification(null)"
            ></notification>
        </transition>
        <div class="row">
            <div class="col-8">
                <div class="row">
                    <div class="col-12">
                        <card
                            heading="Tarkastamattomat kärryt"
                            class="card--unpad"
                        >
                            <data-table
                                v-if="
                                    uncheckedTrailers.length &&
                                        !uncheckedTrailersProcessing
                                "
                                slot="content"
                                class="data-table--selectable data-table--naked"
                            >
                                <template slot="body">
                                    <data-table-row
                                        v-for="row in uncheckedTrailers"
                                        :key="row.id"
                                        @click="
                                            $router.push({
                                                name: 'Reservation',
                                                params: { id: row.id },
                                            })
                                        "
                                    >
                                        <data-table-cell>
                                            <strong v-if="row.unit">{{ row.unit }}</strong>
                                            {{ row.product }}
                                        </data-table-cell>
                                        <data-table-cell class="ar">
                                            Seuraava varaus:
                                            <strong>
                                                {{ row.nextReservation }}
                                            </strong>
                                        </data-table-cell>
                                    </data-table-row>
                                </template>
                            </data-table>

                            <empty-resource
                                v-else-if="
                                    !uncheckedTrailers.length &&
                                        !uncheckedTrailersProcessing
                                "
                                slot="content"
                            >
                                <span class="icon icon--trailers"></span>
                                Ei tarkastamattomia kärryjä
                            </empty-resource>

                            <empty-resource v-else slot="content">
                                <span class="icon icon--trailers"></span>
                                Ladataan...
                            </empty-resource>
                        </card>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6">
                        <card
                            class="card--unpad reservation-card"
                            heading="Tänään lähtevät"
                        >
                            <data-table
                                v-if="
                                    outboundReservations.length &&
                                        !outboundReservationsProcessing
                                "
                                slot="content"
                                class="data-table--selectable data-table--slim data-table--naked"
                            >
                                <template slot="body">
                                    <data-table-row
                                        v-for="row in outboundReservations"
                                        :key="row.id"
                                        @click="goToReservation(row.id)"
                                    >
                                        <data-table-cell
                                            :class="{
                                                'data-table__cell--error':
                                                    row.ban &&
                                                    row.ban.state === 'ban',
                                            }"
                                        >
                                            <strong>{{ row.unit }}</strong>
                                            <br />
                                            <span class="caption">
                                                {{ row.product }}
                                            </span>
                                        </data-table-cell>
                                        <data-table-cell
                                            v-if="
                                                row.ban &&
                                                    row.ban.state === 'ban'
                                            "
                                            class="data-table__cell--unpad banned"
                                        >
                                            <span title="Varauskielto">
                                                <i
                                                    class="icon icon--times-circle banned__icon"
                                                />
                                                <strong>
                                                    {{
                                                        row.name
                                                            ? row.name
                                                            : '-'
                                                    }}
                                                </strong>
                                            </span>
                                            <br />
                                            <span class="caption">
                                                {{ row.realId }}
                                            </span>
                                        </data-table-cell>
                                        <data-table-cell v-else>
                                            {{ row.name ? row.name : '-' }}
                                            <br />
                                            <span class="caption">
                                                {{ row.realId }}
                                            </span>
                                        </data-table-cell>
                                        <data-table-cell class="fs-sm">
                                            <span class="caption">
                                                Varaus alkaa:
                                            </span>
                                            <br />
                                            {{ formatDate(row.dateFrom) }}
                                        </data-table-cell>
                                    </data-table-row>
                                </template>
                            </data-table>

                            <empty-resource
                                v-else-if="
                                    !outboundReservations.length &&
                                        !outboundReservationsProcessing
                                "
                                slot="content"
                            >
                                <span class="icon icon--trailers"></span>
                                Ei yhtään lähtöä tänään
                            </empty-resource>

                            <empty-resource v-else slot="content">
                                <span class="icon icon--trailers"></span>
                                Ladataan...
                            </empty-resource>

                            <div slot="footer">
                                <button
                                    class="button button--primary"
                                    @click="navigate('outbound-list')"
                                >
                                    Näytä kaikki
                                </button>
                                <button
                                    class="button button--secondary"
                                    @click="navigate('cal')"
                                >
                                    Varauskalenteri
                                </button>
                            </div>
                        </card>
                    </div>
                    <div class="col-6">
                        <card
                            class="card--unpad reservation-card"
                            heading="Tänään palautuvat"
                        >
                            <data-table
                                v-if="
                                    inboundReservations.length &&
                                        !inboundReservationsProcessing
                                "
                                slot="content"
                                class="data-table--selectable data-table--slim data-table--naked"
                            >
                                <template slot="body">
                                    <data-table-row
                                        v-for="row in inboundReservations"
                                        :key="row.id"
                                        @click="goToReservation(row.id)"
                                    >
                                        <data-table-cell
                                            :class="{
                                                'data-table__cell--error':
                                                    row.ban &&
                                                    row.ban.state === 'ban',
                                            }"
                                        >
                                            <strong>{{ row.unit }}</strong>
                                            <br />
                                            <span class="caption">
                                                {{ row.product }}
                                            </span>
                                        </data-table-cell>
                                        <data-table-cell
                                            v-if="
                                                row.ban &&
                                                    row.ban.state === 'ban'
                                            "
                                            class="data-table__cell--unpad banned"
                                        >
                                            <span title="Varauskielto">
                                                <i
                                                    class="icon icon--times-circle banned__icon"
                                                />
                                                <strong>
                                                    {{
                                                        row.name
                                                            ? row.name
                                                            : '-'
                                                    }}
                                                </strong>
                                            </span>
                                            <br />
                                            <span class="caption">
                                                {{ row.realId }}
                                            </span>
                                        </data-table-cell>
                                        <data-table-cell v-else>
                                            {{ row.name ? row.name : '-' }}
                                            <br />
                                            <span class="caption">
                                                {{ row.realId }}
                                            </span>
                                        </data-table-cell>
                                        <data-table-cell class="fs-sm">
                                            <span class="caption">
                                                Varaus päättyy:
                                            </span>
                                            <br />
                                            {{ formatDate(row.dateTo) }}
                                        </data-table-cell>
                                    </data-table-row>
                                </template>
                            </data-table>

                            <empty-resource
                                v-else-if="
                                    !inboundReservations.length &&
                                        !inboundReservationsProcessing
                                "
                                slot="content"
                            >
                                <span class="icon icon--trailers"></span>
                                Ei yhtään palautusta tänään
                            </empty-resource>

                            <empty-resource v-else slot="content">
                                <span class="icon icon--trailers"></span>
                                Ladataan...
                            </empty-resource>

                            <div slot="footer">
                                <button
                                    class="button button--primary"
                                    @click="navigate('inbound-list')"
                                >
                                    Näytä kaikki
                                </button>
                            </div>
                        </card>
                    </div>
                </div>
            </div>
            <div class="col-4">
                <div class="row">
                    <div class="col-12">
                        <card
                            class="card--overflow"
                            heading="Varausten pikahaku"
                        >
                            <reservation-search
                                slot="content"
                            ></reservation-search>
                        </card>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div
                            class="tooltip al"
                            role="tooltip"
                            aria-hidden="true"
                            x-placement="left"
                            :style="tooltipStyle"
                        >
                            <div class="tooltip-arrow"></div>
                            <div class="tooltip-inner">
                                <div
                                    v-for="(row, index) in tooltipRows"
                                    :key="index"
                                >
                                    {{ row }}
                                </div>
                            </div>
                        </div>
                        <h2 class="display2">Viimeisimmät tapahtumat</h2>
                        <ul
                            v-if="
                                dashboardLog.length && !dashboardLogProcessing
                            "
                            class="dashboard-log__list"
                        >
                            <li
                                v-for="(item, index) in dashboardLog.slice(
                                    0,
                                    8
                                )"
                                :key="index"
                                class="dashboard-log__list--item"
                                @mouseover="mouseEnter($event, item)"
                                @mouseout="mouseLeave($event, item)"
                            >
                                <h4>{{ item.title }}</h4>
                                <small>{{ formatDate(item.added) }}</small>
                            </li>
                            <li class="ar">
                                <button
                                    v-if="dashboardLog.length > 8"
                                    class="button button--link"
                                    @click="openLogPopup"
                                >
                                    Näytä kaikki tapahtumat
                                </button>
                            </li>
                        </ul>
                        <ul
                            v-if="dashboardLogProcessing"
                            class="dashboard-log__list"
                        >
                            <li>Ladataan...</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';
import Pusher from 'pusher-js';
import { formatDate as format } from '../helpers';
import Card from '../components/Card.vue';
import EmptyResource from '../components/EmptyResource.vue';
import ReservationSearch from '../components/ReservationSearch.vue';
import DataTable from '../components/DataTable.vue';
import DataTableRow from '../components/DataTable/DataTableRow.vue';
import DataTableCell from '../components/DataTable/DataTableCell.vue';
import Notification from '../components/Notification.vue';

export default {
    name: 'Dashboard',
    components: {
        Card,
        EmptyResource,
        ReservationSearch,
        DataTable,
        DataTableRow,
        DataTableCell,
        Notification,
    },
    data() {
        return {
            tooltipPos: null,
            tooltipRows: [],
        };
    },
    computed: {
        ...mapGetters({
            dashboardLog: 'dashboard/dashboardLog',
            dashboardLogProcessing: 'dashboard/dashboardLogProcessing',
            inboundReservations: 'dashboard/inboundReservations',
            inboundReservationsProcessing:
                'dashboard/inboundReservationsProcessing',
            notification: 'dashboard/notification',
            office: 'base/office',
            outboundReservations: 'dashboard/outboundReservations',
            outboundReservationsProcessing:
                'dashboard/outboundReservationsProcessing',
            uncheckedTrailers: 'dashboard/uncheckedTrailers',
            uncheckedTrailersProcessing:
                'dashboard/uncheckedTrailersProcessing',
        }),
        tooltipStyle() {
            if (!this.tooltipPos) {
                return 'display: none;';
            }

            return `
                position: fixed;
                will-change: transform;
                top: 0px;
                left: -202px;
                width: 200px;
                min-height: 52px;
                transform: translate3d(${this.tooltipPos[1] - 2}px, ${this.tooltipPos[0]}px, 0px);
            `;
        },
    },
    mounted() {
        this.fetchLogs();
        this.fetchReservations('active');
        this.fetchReservations('in=active|started');
        this.fetchUncheckedTrailers();

        if (process.env.PUSHER_KEY !== undefined) {
            const pusher = new Pusher(process.env.PUSHER_KEY, {
                cluster: 'eu',
                encrypted: true,
            });
            const officeId = this.$store.state.base.office.id;

            const channel = pusher.subscribe('reservation');
            channel.bind('reservation:item:change', (res) => {
                if (
                    res.message.officeId === officeId
                    && res.message.state === 'active'
                ) {
                    this.fetchReservations('active');
                    this.fetchReservations('in=active|started');
                }
            });

            channel.bind('reservation:log', (res) => {
                if (
                    res.message.action === 'ban/create'
                    || res.message.action === 'ban/update'
                    || (res.message.action === 'office/ban/create'
                        && res.message.officeId === officeId)
                ) {
                    this.fetchLogs();
                    this.fetchReservations('active');
                    this.fetchReservations('in=active|started');
                }
            });
        }
    },
    beforeDestroy() {
        this.reset();
    },
    methods: {
        ...mapActions({
            fetchLogs: 'dashboard/fetchLogs',
            fetchReservations: 'dashboard/fetchReservations',
            fetchUncheckedTrailers: 'dashboard/fetchUncheckedTrailers',
            reset: 'dashboard/reset',
            setNotification: 'dashboard/setNotification',
            setReservationsFilters: 'reservations/setFilters',
        }),
        formatDate(timestamp) {
            return format(timestamp);
        },
        goToReservation(id) {
            this.$router.push({
                name: 'Reservation',
                params: { id },
            });
        },
        mouseEnter(event, log) {
            this.tooltipRows = log.comment ? [log.comment] : [];
            const element = event.target.parentElement.className === 'dashboard-log__list--item'
                ? event.target.parentElement
                : event.target;
            const rect = element.getBoundingClientRect();
            if (this.tooltipRows.length) {
                this.tooltipPos = [
                    rect.top,
                    rect.left,
                    rect.bottom,
                    rect.right,
                ];
            }
        },
        mouseLeave() {
            this.tooltipPos = null;
            this.tooltipRows = [];
        },
        navigate(to) {
            switch (to) {
            case 'outbound-list':
                this.setReservationsFilters({
                    type: 'list',
                    sort: '+dateFrom',
                    dateFrom: moment()
                        .startOf('day')
                        .unix()
                        .toString(),
                    dateTo: moment()
                        .endOf('day')
                        .unix()
                        .toString(),
                    currentState: 'active',
                });
                break;
            case 'inbound-list':
                this.setReservationsFilters({
                    type: 'list',
                    sort: '+dateFrom',
                    dateFrom: moment()
                        .startOf('day')
                        .unix()
                        .toString(),
                    dateTo: moment()
                        .endOf('day')
                        .unix()
                        .toString(),
                    currentState: 'started',
                });
                break;
            default:
                this.setReservationsFilters({
                    type: 'calendar',
                });
            }
            this.$router.push({ name: 'Reservations' });
        },
        openLogPopup() {
            this.$store.commit('base/SET_POPUP', { name: 'dashboardLog' });
        },
    },
};
</script>

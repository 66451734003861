/* eslint-disable */
import http from './common';

export default {
    index: params => (http.get('reservation/v1/offices', { params })),
    get: (id, params) => (http.get(`reservation/v1/offices/${id}`, { params })),
    items: {
        index: (id, params) => (http.get(`reservation/v1/offices/${id}/items`, { params })),
    },
    groups: {
        index: (id, params) => (http.get(`reservation/v1/offices/${id}/groups`, { params })),
    },
};
<template>
    <div class="data-table__row" @click="$emit('click')">
        <slot></slot>
    </div>
</template>
<script>
export default {
    name: 'DataTableRow',
};
</script>

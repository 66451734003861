<template>
    <div class="popup--reservation-log">
        <ul class="popup--reservation-log__list">
            <popup-reservation-log-item
                v-for="(item, index) in log"
                :key="index"
                :item="item"
            ></popup-reservation-log-item>
        </ul>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import PopupReservationLogItem from './PopupReservationLogItem.vue';

export default {
    name: 'PopupReservationLog',
    components: {
        PopupReservationLogItem,
    },
    computed: {
        ...mapGetters({
            log: 'reservation/reservationLog',
        }),
    },
    methods: {},
};
</script>

<template>
    <div class="reservation-action-bar">
        <div v-if="reservation" class="reservation-action-bar__info">
            <div class="reservation-action-bar__info-title">
                <h2 class="display2">Varaus: #{{ reservation.realId }}</h2>
                <span class="caption">
                    Vastaanotettu: {{ formatDate(reservation.created) }}
                </span>
            </div>
            <stepper :state="reservation.state"></stepper>
        </div>
        <div v-if="reservation" class="reservation-action-bar__actions">
            <button
                v-if="showCancelBtn"
                class="button button--link-secondary"
                @click="goBack"
            >
                Peruuta
            </button>
            <button
                v-if="!showGoBackBtn"
                class="button button--primary"
                :disabled="changeStateDisabled"
                @click="changeState"
            >
                {{ actionMsg }}
            </button>
            <button
                v-if="showGoBackBtn"
                class="button button--primary"
                @click="goBack"
            >
                {{ actionMsg }}
            </button>
        </div>
    </div>
</template>

<script>
import moment from 'moment';
import { mapGetters } from 'vuex';
import Stepper from './Stepper.vue';

export default {
    name: 'ReservationActionBar',
    components: {
        Stepper,
    },
    props: {
        changeStateDisabled: {
            type: Boolean,
            default: true,
        },
    },
    computed: {
        ...mapGetters({
            reservation: 'reservation/reservation',
        }),
        actionMsg() {
            switch (this.reservation.state) {
            case 'active':
                return 'Tallenna ja aloita vuokraus';
            case 'started':
                return 'Kärry palautettu';
            case 'returned':
                return 'Merkitse valmiiksi';
            default:
                return 'Palaa listaukseen';
            }
        },
        showCancelBtn() {
            return ['active', 'started', 'returned'].includes(
                this.reservation.state,
            );
        },
        showGoBackBtn() {
            return !['active', 'started', 'returned'].includes(
                this.reservation.state,
            );
        },
    },
    methods: {
        changeState() {
            if (
                ['active', 'started', 'returned'].includes(
                    this.reservation.state,
                )
            ) {
                this.$emit('changeState');
            } else {
                this.goBack();
            }
        },
        formatDate(timestamp) {
            return moment.unix(timestamp).format('D.M.Y H:mm');
        },
        goBack() {
            this.$router.go(-1);
        },
    },
};
</script>

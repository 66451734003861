var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.reservation)?_c('card',{staticClass:"reservation-form-card",attrs:{"heading":"Varauksen tiedot"}},[_c('form',{attrs:{"slot":"content"},slot:"content"},[_c('div',{staticClass:"field"},[_c('div',[_c('label',[_vm._v("Peräkärry")]),_vm._v(" "),_c('span',{staticClass:"select-wrapper"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.capacityUnit),expression:"capacityUnit"}],staticClass:"select",attrs:{"disabled":!['active', 'started'].includes(
                                _vm.reservation.state
                            ) || _vm.readOnly},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.capacityUnit=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},_vm._l((_vm.capacityUnits),function(unit){return _c('option',{key:unit.name,attrs:{"disabled":unit.disabled},domProps:{"value":unit.name}},[_vm._v("\n                            "+_vm._s(unit.name)+"\n                        ")])}),0),_vm._v(" "),_c('span',{staticClass:"icon icon--chevron-down"})])])]),_vm._v(" "),_c('div',{staticClass:"field"},[_c('div',[_c('label',[_vm._v("Varaus alkaa")]),_vm._v(" "),_c('div',{staticClass:"input-group"},[_c('flat-pickr',{staticClass:"input",attrs:{"config":_vm.datePickerConfig,"disabled":!['active', 'started'].includes(
                                _vm.reservation.state
                            ) || _vm.readOnly},model:{value:(_vm.dateFrom),callback:function ($$v) {_vm.dateFrom=$$v},expression:"dateFrom"}}),_vm._v(" "),_c('button',{staticClass:"data-toggle",attrs:{"data-toggle":""}},[_c('i',{staticClass:"icon icon--calendar-alt"})])],1)]),_vm._v(" "),_c('div',[_c('label',[_vm._v("Varaus päättyy")]),_vm._v(" "),_c('div',{staticClass:"input-group"},[_c('flat-pickr',{staticClass:"input",attrs:{"config":_vm.datePickerConfig,"disabled":_vm.reservation.state === 'cancelled' || _vm.readOnly},model:{value:(_vm.dateTo),callback:function ($$v) {_vm.dateTo=$$v},expression:"dateTo"}}),_vm._v(" "),_c('button',{staticClass:"data-toggle",attrs:{"data-toggle":""}},[_c('i',{staticClass:"icon icon--calendar-alt"})])],1)])]),_vm._v(" "),_c('div',{staticClass:"field"},[_c('div',[_c('label',[_vm._v("\n                    Kärryn luovuttaja (Puuilo)\n                    "),(_vm.reservation.state === 'active' && !_vm.readOnly)?_c('span',{staticClass:"asterisk"},[_vm._v("\n                        *\n                    ")]):_vm._e()]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.trailerReleasedBy),expression:"trailerReleasedBy"}],staticClass:"input",attrs:{"type":"text","disabled":_vm.reservation.state !== 'active' || _vm.readOnly},domProps:{"value":(_vm.trailerReleasedBy)},on:{"input":function($event){if($event.target.composing){ return; }_vm.trailerReleasedBy=$event.target.value}}})]),_vm._v(" "),_c('div',[_c('label',[_vm._v("\n                    Kärryn vastaanottaja (Puuilo)\n                    "),(
                            ['started', 'returned'].includes(
                                _vm.reservation.state
                            ) && !_vm.readOnly
                        )?_c('span',{staticClass:"asterisk"},[_vm._v("\n                        *\n                    ")]):_vm._e()]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.trailerReceivedBy),expression:"trailerReceivedBy"}],staticClass:"input",attrs:{"type":"text","disabled":!['started', 'returned'].includes(
                            _vm.reservation.state
                        ) || _vm.readOnly},domProps:{"value":(_vm.trailerReceivedBy)},on:{"input":function($event){if($event.target.composing){ return; }_vm.trailerReceivedBy=$event.target.value}}})])]),_vm._v(" "),_c('div',{staticClass:"field"},[_c('div',[_c('label',[_vm._v("Varauksen muistiinpanot")]),_vm._v(" "),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.comment),expression:"comment"}],staticClass:"input input--textarea",attrs:{"disabled":_vm.readOnly,"placeholder":"Lisätiedot varaukseen liittyen","rows":"3"},domProps:{"value":(_vm.comment)},on:{"input":function($event){if($event.target.composing){ return; }_vm.comment=$event.target.value}}})])]),_vm._v(" "),_c('div',{staticClass:"field ar"},[_c('div',[_c('button',{staticClass:"button button--secondary",attrs:{"disabled":_vm.isDisabled},on:{"click":_vm.saveReservation}},[_vm._v("\n                    Tallenna\n                ")])])]),_vm._v(" "),_c('div',{staticClass:"reservation-form__comments"},_vm._l((_vm.reservation.comments),function(reservationComment){return _c('div',{key:reservationComment.id,staticClass:"reservation-form__comment"},[_c('div',{staticClass:"reservation-form__comment--title"},[_vm._v("\n                    Muistiinpano /\n                    "+_vm._s(_vm.formatDate(reservationComment.created))+"\n                ")]),_vm._v(" "),_c('div',{staticClass:"reservation-form__comment--content"},[_vm._v("\n                    "+_vm._s(reservationComment.content)+"\n                ")]),_vm._v(" "),(!_vm.readOnly)?_c('div',{staticClass:"reservation-form__comment--delete",on:{"click":function($event){return _vm.removeComment(reservationComment.id)}}},[_c('i',{staticClass:"icon icon--times-circle"})]):_vm._e()])}),0)])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }
/* eslint-disable */
import http from './common';

export default {
    index: params => http.get('reservation/v1/items', { params }),
    get: (id, params) => http.get(`reservation/v1/items/${id}`, { params }),
    save: (data, params) => http.post('reservation/v1/items', data, { params }),
    update: (id, data, params) =>
        http.put(`reservation/v1/items/${id}`, data, { params }),
    remove: id => http.delete(`reservation/v1/items/${id}`),
    patch: (id, data, params) =>
        http.patch(`reservation/v1/items/${id}`, data, {
            headers: {
                'Content-Type': 'application/json-patch+json',
            },
            params,
        }),
    orders: {
        index: (itemId, params) =>
            http.get(`reservation/v1/items/${itemId}/orders`, { params }),
        get: (itemId, id, params) =>
            http.get(`reservation/v1/items/${itemId}/orders/${id}`, { params }),
        save: (itemId, data, params) =>
            http.post(`reservation/v1/items/${itemId}/orders`, data, {
                params,
            }),
    },
    notifications: {
        save: (itemId, data, params) =>
            http.post(`reservation/v1/items/${itemId}/notifications`, data, {
                params,
            }),
    },
    units: {
        index: (id, params) =>
            http.get(`reservation/v1/items/${id}/units`, { params }),
    },
    comments: {
        index: (id, params) =>
            http.get(`reservation/v1/items/${id}/comments`, { params }),
        save: (id, data, params) =>
            http.post(`reservation/v1/items/${id}/comments`, data, {
                params,
            }),
        remove: (itemId, commentId) =>
            http.delete(`reservation/v1/items/${itemId}/comments/${commentId}`),
    },
};

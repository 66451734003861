<template>
    <div class="container">
        <transition name="slide-from-top">
            <notification
                v-if="!popup"
                :notification="notification"
                @close="setNotification(null)"
            ></notification>
        </transition>
        <data-table class="data-table--bottomless">
            <template slot="head">
                <data-table-cell
                    v-for="(col, index) in columns"
                    :key="index"
                    :width="col.width"
                >
                    {{ col.title }}
                </data-table-cell>
            </template>
            <template slot="body">
                <data-table-row v-for="row in rows" :key="row.id">
                    <data-table-cell
                        :class="{
                            'data-table__cell--error': bans.find(
                                i => i.unit === row.trailer
                            ),
                        }"
                    >
                        {{ row.trailer }}
                    </data-table-cell>
                    <data-table-cell>{{ row.description }}</data-table-cell>
                    <data-table-cell class="data-table__cell--unpad ar">
                        <button
                            v-if="!bans.find(i => i.unit === row.trailer)"
                            class="button button--link-secondary"
                            @click="openCreatePopup(row.trailer)"
                        >
                            <i class="icon icon--plus-circle"></i>
                            Aseta huoltotila
                        </button>
                        <button
                            v-else
                            class="button button--slim button--secondary-small"
                            @click="openModifyPopup(row.trailer)"
                        >
                            Muokkaa huoltotilaa
                        </button>
                    </data-table-cell>
                </data-table-row>
            </template>
        </data-table>
    </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import DataTable from '../components/DataTable.vue';
import DataTableRow from '../components/DataTable/DataTableRow.vue';
import DataTableCell from '../components/DataTable/DataTableCell.vue';
import Notification from '../components/Notification.vue';
import { eventBus } from '../helpers';

export default {
    name: 'Trailers',
    components: {
        DataTable,
        DataTableRow,
        DataTableCell,
        Notification,
    },
    data() {
        return {
            columns: [
                { title: 'Rekisterinumero', width: null },
                { title: 'Kuvaus', width: null },
                { title: '', width: null },
            ],
        };
    },
    computed: {
        ...mapGetters({
            rows: 'trailers/rows',
            bans: 'trailers/bans',
            notification: 'trailers/notification',
            popup: 'base/popup',
        }),
    },
    mounted() {
        this.loadBans();
        eventBus.$on('maintenance-removed', this.loadBans);
    },
    beforeDestroy() {
        this.reset();
    },
    methods: {
        ...mapMutations({
            setPopup: 'base/SET_POPUP',
            setSelected: 'trailers/SET_SELECTED',
        }),
        ...mapActions({
            loadBans: 'trailers/loadBans',
            reset: 'trailers/reset',
            setNotification: 'trailers/setNotification',
        }),
        openCreatePopup(trailer) {
            this.setSelected(trailer);
            this.setPopup({ name: 'trailerMaintenance' });
        },
        openModifyPopup(trailer) {
            this.setSelected(trailer);
            this.setPopup({ name: 'trailerModifyMaintenance' });
        },
    },
};
</script>

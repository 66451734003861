/* eslint-disable */
import http from './common';

export default {
    index: params => http.get(`reservation/v1/logs`, { params }),
    officeLog: {
        index: (officeId, params) =>
            http.get(`reservation/v1/offices/${officeId}/logs`, { params }),
    },
};

<template>
    <div class="popup--reservation-warn-hours">
        <div class="popup__content">
            <p>
                {{
                    dataProps.warnHours
                        ? 'Varauksen alkuun on yli 30 minuuttia.'
                        : ''
                }}
                {{ dataProps.additionalItems ? 'Lisäturva puuttuu.' : '' }}
                Haluatko varmasti aloittaa vuokrauksen?
            </p>
        </div>

        <div class="popup__buttons">
            <button class="button button--link" @click="$emit('close')">
                Peruuta
            </button>
            <button class="button button--primary" @click="startReservation()">
                Aloita vuokraus
            </button>
        </div>
    </div>
</template>

<script>
import { eventBus } from '../../helpers';

export default {
    name: 'PopupReservationWarn',
    props: {
        dataProps: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {};
    },
    methods: {
        startReservation() {
            eventBus.$emit('START_RESERVATION');
            this.$emit('close');
        },
    },
};
</script>

import Vue from 'vue';
import VueRouter from 'vue-router';
import Dashboard from '../pages/Dashboard.vue';
import Reservations from '../pages/Reservations.vue';
import Reservation from '../pages/Reservation.vue';
import Clients from '../pages/Clients.vue';
import Client from '../pages/Client.vue';
import Trailers from '../pages/Trailers.vue';

Vue.use(VueRouter);

export default new VueRouter({
    routes: [
        {
            path: '/',
            component: Dashboard,
            name: 'Dashboard',
        },
        {
            path: '/reservations',
            component: Reservations,
            name: 'Reservations',
        },
        {
            path: '/reservations/:id',
            component: Reservation,
            name: 'Reservation',
            props: true,
        },
        {
            path: '/clients',
            component: Clients,
            name: 'Clients',
        },
        {
            path: '/clients/:id',
            component: Client,
            name: 'Client',
            props: true,
        },
        {
            path: '/trailers',
            component: Trailers,
            name: 'Trailers',
        },
    ],
});

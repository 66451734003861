<template>
    <div class="customer__action-bar">
        <div class="customer__action-bar--actions">
            <button class="button button--secondary" @click="$emit('cancel')">
                Peruuta
            </button>
            <button
                class="button button--primary"
                :disabled="saveDisabled"
                @click="$emit('save')"
            >
                Tallenna muutokset
            </button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'CustomerActionBar',
    props: {
        saveDisabled: {
            required: true,
            type: Boolean,
        },
    },
    methods: {},
};
</script>

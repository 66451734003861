import { itemResource } from '../../api';

const initialState = {
    searchResults: null,
};

const getters = {
    searchResults: (state) => state.searchResults,
};

const mutations = {
    SET_SEARCH_RESULTS(context, payload) {
        context.searchResults = payload;
    },
};

const actions = {
    reset: ({ commit }) => commit('SET_SEARCH_RESULTS', []),
    search: async ({ rootState, commit }, searchTerm) => {
        const res = await itemResource.index({
            _q: searchTerm,
            _with: 'user',
            _offset: 0,
            _limit: 8,
            officeId: rootState.base.office.id,
        });

        const rows = res.data.data.map((r) => {
            const { user, items } = r;
            return {
                unit:
                    items && items[0].capacityUnit
                        ? items[0].capacityUnit
                        : '-',
                product: items && items[0].title,
                dateFrom: r.dateFrom,
                dateTo: r.dateTo,
                id: r.id,
                realId: `#${r.realId}`,
                name: `${user && user.contact ? user.contact.firstName : ''} ${
                    user && user.contact ? user.contact.lastName : ''
                }`,
            };
        });

        commit('SET_SEARCH_RESULTS', rows);
    },
};

export default {
    namespaced: true,
    state: initialState,
    getters,
    mutations,
    actions,
};

import { userResource, itemResource } from '../../api';
import router from '../../router';

const emptyCustomer = {
    email: null,
    contact: {
        firstName: null,
        lastName: null,
        phone: null,
        address: null,
        postCode: null,
        city: null,
        countryId: 207,
    },
};

const emptyDirtyCustomer = { contact: { countryId: 207 } };

const initialState = {
    activeBan: null,
    banned: false,
    banComment: '',
    customer: emptyCustomer,
    dirtyCustomer: emptyDirtyCustomer,
    isDirty: false,
    previousBans: [],
    processing: false,
    reservationHistory: [],
};

const getters = {
    activeBan: (state) => state.activeBan,
    banned: (state) => state.banned,
    banComment: (state) => state.banComment,
    customer: (state) => state.customer,
    dirtyCustomer: (state) => state.dirtyCustomer,
    isDirty: (state) => state.isDirty,
    previousBans: (state) => state.previousBans,
    processing: (state) => state.processing,
    reservationHistory: (state) => state.reservationHistory,
};

const mutations = {
    SET_ACTIVEBAN(context, payload) {
        context.activeBan = payload;
    },
    SET_BANNED(context, payload) {
        context.banned = payload;
    },
    SET_BANCOMMENT(context, payload) {
        context.banComment = payload;
    },
    SET_CUSTOMER(context, payload) {
        context.customer = payload;
    },
    SET_DIRTYCUSTOMER(context, payload) {
        context.dirtyCustomer = payload;
    },
    SET_ISDIRTY(context, payload) {
        context.isDirty = payload;
    },
    SET_PREVIOUSBANS(context, payload) {
        context.previousBans = payload;
    },
    SET_PROCESSING(context, payload) {
        context.processing = payload;
    },
    SET_RESERVATIONHISTORY(context, payload) {
        context.reservationHistory = payload;
    },
};

const actions = {
    reset({ commit }) {
        commit('SET_ACTIVEBAN', null);
        commit('SET_BANNED', false);
        commit('SET_BANCOMMENT', '');
        commit('SET_CUSTOMER', emptyCustomer);
        commit('SET_DIRTYCUSTOMER', { contact: { countryId: 207 } });
        commit('SET_ISDIRTY', null);
        commit('SET_PREVIOUSBANS', []);
        commit('SET_PROCESSING', false);
        commit('SET_RESERVATIONHISTORY', []);
    },
    editField({ commit, state }, { field, value }) {
        const parentFields = ['email'];

        if (parentFields.includes(field)) {
            state.dirtyCustomer[field] = value;
        } else {
            state.dirtyCustomer.contact[field] = value;
        }

        commit('SET_ISDIRTY', true);
    },
    cancel({ commit }) {
        commit('SET_DIRTYCUSTOMER', emptyDirtyCustomer);
        commit('SET_ISDIRTY', false);
    },
    // async actions
    async load({ commit, dispatch }, id) {
        commit('SET_PROCESSING', true);

        const userResponse = await userResource.get(id);
        const { data } = userResponse.data;

        commit('SET_CUSTOMER', data);

        const reservationResponse = await itemResource.index({
            state: 'in=active|started|returned|completed',
            userId: data.id,
        });

        commit('SET_RESERVATIONHISTORY', reservationResponse.data.data);

        if (data.contact && data.contact.phone) {
            dispatch('fetchBans', data.contact.phone);
        } else commit('SET_PROCESSING', false);
    },
    async fetchBans({ commit }, phone) {
        const banResponse = await userResource.bans.index({
            phone,
        });
        const rows = banResponse.data.data.map((r) => r);

        let activeBan = null;
        const previousBans = [];
        rows.map((r) => {
            if (r.state === 'ban') {
                activeBan = r;
            } else {
                previousBans.push(r);
            }

            return r;
        });

        commit('SET_PREVIOUSBANS', previousBans);
        commit('SET_ACTIVEBAN', activeBan);
        commit('SET_PROCESSING', false);
    },
    async deleteBan({ commit, state }, id) {
        commit('SET_PROCESSING', true);

        const res = await userResource.bans.update(id, {
            state: 'inactive',
        });

        const bans = state.previousBans;
        bans.unshift(res.data.data);

        commit('SET_BANNED', false);
        commit('SET_BANCOMMENT', '');
        commit('SET_ACTIVEBAN', null);
        commit('SET_PREVIOUSBANS', bans);
        commit('SET_PROCESSING', false);
    },
    async save({ commit, dispatch, state }) {
        commit('SET_PROCESSING', true);

        const { dirtyCustomer, customer } = state;
        const contactPayload = {
            ...customer.contact,
            ...dirtyCustomer.contact,
        };
        const customerPayload = {
            ...customer,
            ...dirtyCustomer,
        };

        const res = await userResource.update(customerPayload.id, {
            email: customerPayload.email,
            contact: contactPayload,
        });

        if (state.banned && state.banComment) {
            await userResource.bans.save({
                phone: contactPayload.phone,
                comment: state.banComment,
            });
        }

        const updatedCustomer = res.data.data;

        if (updatedCustomer.contact && updatedCustomer.contact.phone) {
            dispatch('fetchBans', updatedCustomer.contact.phone);
        } else commit('SET_PROCESSING', false);

        commit('SET_CUSTOMER', updatedCustomer);
        commit('SET_DIRTYCUSTOMER', emptyDirtyCustomer);
        commit('SET_ISDIRTY', false);

        router.go(-1);
    },
};

export default {
    namespaced: true,
    state: initialState,
    getters,
    mutations,
    actions,
};

<template>
    <div class="popup--trailer-maintenance" :class="{'popup--trailer-maintenance--loading': isLoading}">
        <div v-if="isLoading" class="popup--trailer-maintenance--loading-message">
            Odota hetki, asetetaan huoltotilaa...
        </div>
        <div
            v-if="notification"
            class="notification"
            :class="{
                notification__success: notification.type === 'success',
                notification__warning: notification.type === 'warning',
                notification__error: notification.type === 'error',
            }"
        >
            <span>
                <i
                    class="icon"
                    :class="{
                        'icon--check-circle': notification.type === 'success',
                        'icon--exclamation-circle':
                            notification.type === 'warning' ||
                            notification.type === 'error',
                    }"
                ></i>
                {{ notification.title }}
            </span>
            <button class="button" @click="setNotification(null)">
                <i class="icon icon--times"></i>
            </button>
        </div>
        <div class="row">
            <div class="col-3">
                <div class="field">
                    <div>
                        <label>
                            Huoltotila alkaa
                            <span class="asterisk">*</span>
                        </label>
                        <div class="input-group">
                            <flat-pickr
                                v-model="dateFrom"
                                :config="datePickerConfig"
                                placeholder="pp.kk.vvvv TT:MM"
                                class="input"
                            ></flat-pickr>
                            <button data-toggle class="data-toggle">
                                <i class="icon icon--calendar-alt" />
                            </button>
                        </div>
                    </div>
                </div>
                <div class="field">
                    <div>
                        <label>
                            Huoltotila päättyy
                            <span class="asterisk">*</span>
                        </label>
                        <div class="input-group">
                            <flat-pickr
                                v-model="dateTo"
                                :config="datePickerConfig"
                                placeholder="pp.kk.vvvv TT:MM"
                                class="input"
                            ></flat-pickr>
                            <button data-toggle class="data-toggle">
                                <i class="icon icon--calendar-alt" />
                            </button>
                        </div>
                    </div>
                </div>

                <div class="field">
                    <div>
                        <label>Vikakuvaus</label>
                        <textarea
                            v-model="comment"
                            class="input input--textarea"
                            placeholder=""
                            rows="4"
                        ></textarea>
                    </div>
                </div>
            </div>
            <div class="col-9">
                <h3>Kärryn {{ selected }} varaukset huollon aikavälillä</h3>
                <span class="description">
                    Valitse varauksille vaihtoehtoinen kärry, jotta huoltotila
                    voidaan asettaa. Huoltotilan aikana kärryä ei voi varata
                    varauspalvelusta.
                </span>
                <div class="table-scroll">
                    <data-table slim>
                        <template slot="head">
                            <data-table-cell
                                v-for="(col, index) in columns"
                                :key="index"
                                :width="col.width"
                            >
                                {{ col.title }}
                            </data-table-cell>
                        </template>
                        <template slot="body">
                            <data-table-row v-for="row in rows" :key="row.id">
                                <data-table-cell>
                                    {{ row.realId }}
                                </data-table-cell>
                                <data-table-cell>
                                    {{ row.name }}
                                </data-table-cell>
                                <data-table-cell>
                                    {{ format(row.dateFrom) }}
                                </data-table-cell>
                                <data-table-cell>
                                    {{ format(row.dateTo) }}
                                </data-table-cell>
                                <data-table-cell
                                    class="data-table__cell--unpad ar"
                                >
                                    <popup-trailer-maintenance-select
                                        :id="row.id"
                                        :items="row.items"
                                        :date-from="row.dateFrom"
                                        :date-to="row.dateTo"
                                        :initial="row.unit"
                                        @changed="
                                            unit =>
                                                handleTrailerSelect(row, unit)
                                        "
                                    ></popup-trailer-maintenance-select>
                                </data-table-cell>
                                <data-table-cell>
                                    <i
                                        v-if="approvedRows"
                                        class="icon"
                                        :class="{
                                            'icon--exclamation-circle': !approvedRows.find(
                                                i => i.id === row.id
                                            ),
                                            'icon--check-circle': approvedRows.find(
                                                i => i.id === row.id
                                            ),
                                        }"
                                    ></i>
                                </data-table-cell>
                            </data-table-row>
                        </template>
                    </data-table>
                </div>
                <div>
                    <empty-resource v-if="!rows.length">
                        Ei varauksia
                    </empty-resource>
                </div>
            </div>
        </div>
        <div class="popup__buttons">
            <button class="button button--link" @click="$emit('close')">
                Peruuta
            </button>
            <button
                :disabled="isDisabled"
                class="button button--primary"
                @click="handleMaintenanceClick"
            >
                Aseta huoltotila
            </button>
        </div>
    </div>
</template>

<script>
import FlatPickr from 'vue-flatpickr-component';
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';
import { pickerConfigDateTime, formatDate } from '../../helpers';
import DataTable from '../DataTable.vue';
import DataTableRow from '../DataTable/DataTableRow.vue';
import DataTableCell from '../DataTable/DataTableCell.vue';
import EmptyResource from '../EmptyResource.vue';
import PopupTrailerMaintenanceSelect from './PopupTrailerMaintenanceSelect.vue';

export default {
    name: 'PopupTrailerMaintenanceVue',
    components: {
        FlatPickr,
        DataTable,
        DataTableRow,
        DataTableCell,
        EmptyResource,
        PopupTrailerMaintenanceSelect,
    },
    data() {
        return {
            datePickerConfig: pickerConfigDateTime,
            columns: [
                { title: 'Varausnro', width: null },
                { title: 'Asiakas', width: null },
                { title: 'Alkaen', width: null },
                { title: 'Päättyen', width: null },
                { title: 'Kärry', width: null },
                { title: '', width: '40px' },
            ],
            isLoading: false,
        };
    },
    computed: {
        ...mapGetters({
            from: 'trailers/dateFrom',
            to: 'trailers/dateTo',
            rows: 'trailers/reservationRows',
            approvedRows: 'trailers/reservationApprovedRows',
            selected: 'trailers/selected',
            notification: 'trailers/notification',
            maintenanceComment: 'trailers/comment',
        }),
        dateFrom: {
            get() {
                return this.from ? this.from.toString() : null;
            },
            set(value) {
                this.edit({
                    field: 'dateFrom',
                    value: !this.from
                        ? moment()
                            .add(1, 'hours')
                            .startOf('hour')
                            .format('X')
                        : value,
                });
            },
        },
        dateTo: {
            get() {
                return this.to ? this.to.toString() : null;
            },
            set(value) {
                this.edit({ field: 'dateTo', value });
            },
        },
        comment: {
            get() {
                return this.maintenanceComment;
            },
            set(value) {
                this.edit({ field: 'comment', value });
            },
        },
        isDisabled() {
            return (
                !this.dateTo
                || !this.dateFrom
                || this.rows.length !== this.approvedRows.length
            );
        },
    },
    mounted() {
        this.reset();
        this.dateFrom = moment().format('X');
        this.load();
    },
    beforeDestroy() {
        this.reset();
    },
    methods: {
        ...mapActions({
            edit: 'trailers/editField',
            load: 'trailers/load',
            reset: 'trailers/reset',
            updateUnit: 'trailers/updateUnit',
            createMaintenance: 'trailers/createMaintenance',
            setNotification: 'trailers/setNotification',
        }),
        handleInput(e) {
            console.log(e);
        },
        format(timestamp) {
            return formatDate(timestamp);
        },
        async handleTrailerSelect(row, unit) {
            try {
                await this.updateUnit({ row, unit });
            } catch (err) {
                // error handling
            }
        },
        async handleMaintenanceClick() {
            this.isLoading = true;
            await this.createMaintenance();
            this.isLoading = false;
            this.$emit('close');
        },
    },
};
</script>

import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import dashboard from './modules/dashboard';
import reservation from './modules/reservation';
import reservationSearch from './modules/reservationSearch';
import reservations from './modules/reservations';
import base from './modules/base';
import clients from './modules/clients';
import client from './modules/client';
import trailers from './modules/trailers';

Vue.use(Vuex);

const plugins = [
    createPersistedState({
        key: 'puuilo-hallinta',
        reducer: (persistedState) => {
            const persist = { ...persistedState };
            delete persist.reservation;
            return persist;
        },
    }),
];

export default new Vuex.Store({
    modules: {
        base,
        dashboard,
        reservation,
        reservations,
        reservationSearch,
        clients,
        client,
        trailers,
    },
    plugins,
});

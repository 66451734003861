<template>
    <div class="container">
        <div class="data-table__filters sb">
            <div class="data-table__filters--states">
                <a
                    v-for="list in lists"
                    :key="list.value"
                    href="#"
                    class="data-table__filters--state"
                    :class="{
                        'data-table__filters--active-state':
                            list.value === currentList,
                    }"
                    @click.prevent="changeList(list.value)"
                >
                    {{ list.title }}
                </a>
            </div>
            <div class="data-table__filters--inputs">
                <div class="input--field">
                    <label>Asiakas</label>
                    <div class="input__input-icon-container">
                        <input
                            v-model="customer"
                            class="input input--small"
                            type="text"
                            placeholder="Nimi, puhelin, sähköposti"
                            style="width:210px;"
                            @blur="load()"
                            @input="input(customer)"
                        />
                        <span class="icon icon--search"></span>
                    </div>
                </div>
            </div>
        </div>
        <data-table
            :class="{
                'data-table--bottomless': currentList === 'bans',
            }"
            class="data-table--selectable"
        >
            <template slot="head">
                <data-table-cell
                    v-for="(col, index) in columns"
                    :key="index"
                    :width="col.width"
                >
                    <a
                        v-if="col.sort && currentList !== 'bans'"
                        href="#"
                        @click.prevent="changeSort(col.sort)"
                    >
                        {{ col.title }}
                        <i
                            class="icon icon--chevron-down"
                            :class="{
                                'icon--chevron-up':
                                    sort && sort === `+${col.sort}`,
                            }"
                        ></i>
                    </a>
                    <span v-else>{{ col.title }}</span>
                </data-table-cell>
            </template>
            <template slot="body">
                <data-table-empty
                    v-if="!rows.length && !processing"
                    col-span="8"
                >
                    <empty-resource>
                        <span class="icon icon--customers"></span>
                        Ei yhtään asiakasta valituilla kriteereillä
                    </empty-resource>
                </data-table-empty>

                <data-table-empty v-else-if="processing" col-span="8">
                    <empty-resource>
                        <span class="icon icon--customers"></span>
                        Ladataan...
                    </empty-resource>
                </data-table-empty>

                <data-table-row
                    v-for="(row, index) in rows"
                    v-else
                    :key="index"
                    @click="
                        $router.push({
                            name: 'Client',
                            params: { id: row.id },
                        })
                    "
                >
                    <data-table-cell>
                        {{ row.firstName ? row.firstName : '-' }}
                    </data-table-cell>
                    <data-table-cell>
                        {{ row.lastName ? row.lastName : '-' }}
                    </data-table-cell>
                    <data-table-cell>
                        {{ row.email ? row.email : '-' }}
                    </data-table-cell>
                    <data-table-cell>
                        {{ row.phone ? row.phone : '-' }}
                    </data-table-cell>
                    <data-table-cell>
                        {{ row.address ? row.address : '-' }}
                    </data-table-cell>
                    <data-table-cell>
                        {{ row.zip ? row.zip : '-' }}
                    </data-table-cell>
                    <data-table-cell>
                        {{ row.city ? row.city : '-' }}
                    </data-table-cell>
                    <data-table-cell>
                        {{ row.created ? formatDate(row.created) : '-' }}
                    </data-table-cell>
                </data-table-row>
            </template>
            <template v-if="pagination && currentList !== 'bans'" slot="total">
                Asiakkaita yhteensä: {{ pagination.total }}
            </template>
            <template
                v-if="pagination && currentList !== 'bans'"
                slot="pagination"
            >
                <a
                    v-for="(page, index) in truncatedPagination"
                    :key="index"
                    href="#"
                    class="data-table__pagination--item"
                    :class="{
                        'data-table__pagination--active': page === currentPage,
                    }"
                    @click.prevent="changePage(page)"
                >
                    {{ page }}
                </a>
            </template>
        </data-table>
    </div>
</template>

<script>
import moment from 'moment';
import { mapGetters, mapActions } from 'vuex';
import DataTable from '../components/DataTable.vue';
import DataTableRow from '../components/DataTable/DataTableRow.vue';
import DataTableCell from '../components/DataTable/DataTableCell.vue';
import DataTableEmpty from '../components/DataTable/DataTableEmpty.vue';
import EmptyResource from '../components/EmptyResource.vue';

export default {
    name: 'Clients',
    components: {
        DataTable,
        DataTableRow,
        DataTableCell,
        DataTableEmpty,
        EmptyResource,
    },
    data() {
        return {
            columns: [
                { title: 'Etunimi', sort: 'firstName', width: null },
                { title: 'Sukunimi', sort: 'lastName', width: null },
                { title: 'Sähköposti', sort: 'email', width: null },
                { title: 'Puhelin', sort: null, width: null },
                { title: 'Osoite', sort: null, width: null },
                { title: 'Postinumero', width: null },
                { title: 'Postitoimipaikka', sort: 'city', width: '180px' },
                { title: 'Tunnus luotu', sort: 'created', width: '180px' },
            ],
            lists: [
                { title: 'Kaikki asiakkaat', value: 'clients' },
                { title: 'Varauskieltolista', value: 'bans' },
            ],
        };
    },
    computed: {
        ...mapGetters({
            currentList: 'clients/currentList',
            currentPage: 'clients/currentPage',
            limit: 'clients/limit',
            pagination: 'clients/pagination',
            processing: 'clients/processing',
            rows: 'clients/rows',
            sort: 'clients/sort',
            truncatedPagination: 'clients/truncatedPagination',
        }),
        customer: {
            get() {
                return this.$store.state.clients.customer;
            },
            set(value) {
                this.$store.commit('clients/SET_CURRENTPAGE', 1);
                this.$store.commit('clients/SET_CUSTOMER', value);
            },
        },
    },
    mounted() {
        if (this.currentList === 'clients') {
            this.load();
        } else {
            this.loadBanned();
        }
    },
    beforeDestroy() {
        this.softReset();
    },
    methods: {
        ...mapActions({
            changeList: 'clients/changeList',
            changePage: 'clients/changePage',
            changeSort: 'clients/changeSort',
            load: 'clients/load',
            loadBanned: 'clients/loadBanned',
            softReset: 'clients/softReset',
        }),
        input(field) {
            if (!field || field.length >= 3) {
                this.load();
            }
        },
        formatDate(timestamp) {
            return moment.unix(timestamp).format('D.M.Y H:mm');
        },
    },
};
</script>

<template>
    <div class="container reservation-container">
        <div v-if="processing" class="overlay"></div>
        <transition name="slide-from-top">
            <notification
                :notification="notification"
                @close="setNotification(null)"
            ></notification>
        </transition>
        <div v-if="readOnly" class="notification notification__warning">
            <span>
                <i class="icon icon--exclamation-circle"></i>
                Varausta ei voi muokata
            </span>
        </div>
        <div class="row">
            <div class="col-4">
                <reservation-form
                    :read-only="readOnly"
                    :is-disabled="isDisabled"
                    @saveReservation="save"
                ></reservation-form>
            </div>
            <div class="col-3">
                <reservation-customer></reservation-customer>
                <reservation-log></reservation-log>
            </div>
            <div class="col-5">
                <reservation-other-info
                    :read-only="readOnly"
                ></reservation-other-info>
            </div>
        </div>
        <reservation-action-bar
            :change-state-disabled="
                stateChangeLock
                    || (ban && ['started', 'returned'].indexOf(reservation.state) === -1)
                    || processing
                    || readOnly
            "
            @changeState="onStepForward"
        ></reservation-action-bar>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import Pusher from 'pusher-js';
import moment from 'moment';
import ReservationActionBar from '../components/ReservationActionBar.vue';
import ReservationForm from '../components/ReservationForm.vue';
import ReservationCustomer from '../components/ReservationCustomer.vue';
import ReservationLog from '../components/ReservationLog.vue';
import ReservationOtherInfo from '../components/ReservationOtherInfo.vue';
import Notification from '../components/Notification.vue';
import { eventBus } from '../helpers';

export default {
    name: 'Reservation',
    components: {
        ReservationActionBar,
        ReservationForm,
        ReservationCustomer,
        ReservationLog,
        ReservationOtherInfo,
        Notification,
    },
    props: {
        id: {
            required: true,
            type: String,
        },
    },
    computed: {
        ...mapGetters({
            additionalItems: 'reservation/additionalItems',
            ban: 'reservation/ban',
            currentCapacityUnit: 'reservation/currentCapacityUnit',
            dirtyReservation: 'reservation/dirtyReservation',
            isDirty: 'reservation/isDirty',
            notification: 'reservation/notification',
            office: 'base/office',
            officeItems: 'base/officeItems',
            processing: 'reservation/processing',
            reservation: 'reservation/reservation',
            stateChangeLock: 'reservation/stateChangeLock',
        }),
        readOnly() {
            return (
                this.reservation
                && this.office.id !== this.reservation.office.id
            );
        },
        isDisabled() {
            return (
                !this.isDirty
                || !this.currentCapacityUnit
                || this.processing
                || this.readOnly
            );
        },
    },
    watch: {
        officeItems(newVal, oldVal) {
            if (!oldVal.length && newVal.length) {
                this.fetchReservation(this.id);
            }
        },
    },
    mounted() {
        if (this.officeItems.length) {
            this.fetchReservation(this.id);
        }
        eventBus.$on('CANCEL_RESERVATION', this.cancelReservation);
        eventBus.$on('START_RESERVATION', this.startReservation);

        if (process.env.PUSHER_KEY !== undefined) {
            const pusher = new Pusher(process.env.PUSHER_KEY, {
                cluster: 'eu',
                encrypted: true,
            });
            const officeId = this.$store.state.base.office.id;

            const channel = pusher.subscribe('reservation');
            channel.bind('reservation:item:change', (res) => {
                if (
                    res.message.officeId === officeId
                    && ['draft', 'active'].includes(res.message.state)
                ) {
                    this.fetchCapacityUnits();
                }
            });
        }
    },
    beforeDestroy() {
        this.reset();
    },
    methods: {
        ...mapActions({
            cancelReservation: 'reservation/cancelReservation',
            fetchCapacityUnits: 'reservation/fetchCapacityUnits',
            fetchReservation: 'reservation/fetchReservation',
            reset: 'reservation/reset',
            save: 'reservation/save',
            setNotification: 'reservation/setNotification',
            stepForward: 'reservation/stepForward',
        }),
        ...mapMutations({
            setPopup: 'base/SET_POPUP',
        }),
        onStepForward() {
            const popupData = {
                warnHours: false,
                additionalItems: false,
            };

            const dateFrom = (this.dirtyReservation && this.dirtyReservation.dateFrom)
                || this.reservation.dateFrom;

            const suggestedItem = this.additionalItems.find((i) => i.title === 'Lisäturva (5€/pv)');

            if (
                this.reservation.state === 'active'
                && (suggestedItem && !suggestedItem.selected)
            ) {
                popupData.additionalItems = true;
            }

            if (
                this.reservation.state === 'active'
                && moment().add(30, 'minutes') < moment.unix(dateFrom)
            ) {
                popupData.warnHours = true;
            }

            if (popupData.warnHours || popupData.additionalItems) {
                this.setPopup({ name: 'reservationWarn', data: popupData });
            } else this.stepForward();
        },
        startReservation() {
            this.stepForward();
        },
    },
};
</script>

<template>
    <div class="data-table" :class="{ 'data-table--slim': slim }">
        <div class="data-table__heading">
            <div class="data-table__row">
                <slot name="head"></slot>
            </div>
        </div>
        <div class="data-table__body">
            <slot name="body"></slot>
        </div>
        <div v-if="$slots.total || $slots.pagination" class="data-table__foot">
            <data-table-cell class="data-table__total">
                <slot name="total"></slot>
            </data-table-cell>
            <data-table-cell class="data-table__pagination">
                <slot name="pagination"></slot>
            </data-table-cell>
        </div>
    </div>
</template>

<script>
import DataTableCell from './DataTable/DataTableCell.vue';

export default {
    name: 'DataTable',
    components: {
        DataTableCell,
    },
    props: {
        slim: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.notification)?_c('div',{staticClass:"notification",class:{
        notification__success: _vm.notification.type === 'success',
        notification__warning: _vm.notification.type === 'warning',
        notification__error: _vm.notification.type === 'error',
    }},[_c('span',[_c('i',{staticClass:"icon",class:{
                'icon--check-circle': _vm.notification.type === 'success',
                'icon--exclamation-circle':
                    _vm.notification.type === 'warning' ||
                    _vm.notification.type === 'error',
            }}),_vm._v("\n        "+_vm._s(_vm.notification.title)+"\n    ")]),_vm._v(" "),_c('button',{staticClass:"button",on:{"click":function($event){return _vm.$emit('close')}}},[_c('i',{staticClass:"icon icon--times"})])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <nav class="navigation-bar">
        <router-link class="navigation-bar__logo" :to="{ name: 'Dashboard' }">
            <img src="../../img/logo-puuilo.svg" alt="Puuilo" />
        </router-link>
        <ul class="nav-list">
            <li class="nav-list__root-item">
                <router-link
                    class="nav-list__link captionbold"
                    :to="{ name: 'Dashboard' }"
                    :active="isExactActive"
                >
                    <span class="icon-wrapper">
                        <i class="icon icon--home" />
                    </span>
                    Etusivu
                </router-link>
            </li>
            <li class="nav-list__item">
                <router-link
                    class="nav-list__link captionbold"
                    :to="{ name: 'Reservations' }"
                    :active="isActive"
                >
                    <span class="icon-wrapper">
                        <i class="icon icon--reservations" />
                    </span>
                    Varaukset
                </router-link>
            </li>
            <li class="nav-list__item">
                <router-link
                    class="nav-list__link captionbold"
                    :to="{ name: 'Clients' }"
                    :active="isActive"
                >
                    <span class="icon-wrapper">
                        <i class="icon icon--customers" />
                    </span>
                    Asiakkaat
                </router-link>
            </li>
            <li class="nav-list__item">
                <router-link
                    class="nav-list__link captionbold"
                    :to="{ name: 'Trailers' }"
                    :active="isActive"
                >
                    <span class="icon-wrapper">
                        <i class="icon icon--trailers" />
                    </span>
                    Kärryt
                </router-link>
            </li>
        </ul>
    </nav>
</template>

<script>
export default {
    name: 'NavigationBar',
    props: {
        isActive: {
            type: Boolean,
            default: false,
        },
        isExactActive: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<template>
    <div class="reservations-cal">
        <div
            class="tooltip al"
            role="tooltip"
            aria-hidden="true"
            x-placement="bottom"
            :style="tooltipStyle"
        >
            <div class="tooltip-arrow" style="left: 65px;"></div>
            <div class="tooltip-inner">
                <div v-for="(row, index) in tooltipRows" :key="index">
                    {{ row }}
                </div>
            </div>
        </div>
        <full-calendar
            v-if="views && loaded"
            ref="fullcalendar"
            :key="calendarKey"
            :default-view="defaultView"
            :default-date="defaultDate"
            height="auto"
            :plugins="calendarPlugins"
            scheduler-license-key="GPL-My-Project-Is-Open-Source"
            :views="views"
            :locales="locales"
            locale="fi"
            :header="{
                left: 'title',
                center: 'today prev,next',
                right:
                    'resourceTimelineDay,resourceTimelineWeek,resourceTimelineMonth',
            }"
            resource-label-text="Kärryt"
            resource-area-width="250px"
            resource-group-field="itemTitle"
            :resources="fetchResources"
            :events="fetchEvents"
            :view-skeleton-render="updateCalendarView"
            :dates-render="renderDates"
            @eventClick="eventSelected"
            @eventMouseEnter="mouseEnter"
            @eventMouseLeave="mouseLeave"
        ></full-calendar>
    </div>
</template>
<script>
import FullCalendar from '@fullcalendar/vue';
import Pusher from 'pusher-js';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import fiLocale from '@fullcalendar/core/locales/fi';
import moment from 'moment';
import { mapGetters } from 'vuex';
import { calendarResource } from '../api';

export default {
    name: 'ReservationsCal',
    components: {
        FullCalendar,
    },
    data() {
        return {
            calendarKey: 0,
            calendarPlugins: [resourceTimelinePlugin],
            views: null,
            loaded: false,
            locales: [fiLocale],
            tooltipPos: null,
            tooltipRows: [],
        };
    },
    computed: {
        ...mapGetters({
            office: 'base/office',
            defaultView: 'reservations/calendarView',
        }),
        tooltipStyle() {
            if (!this.tooltipPos) {
                return 'display: none;';
            }

            const x = (this.tooltipPos[1] + this.tooltipPos[3]) / 2 - 75;
            const y = (this.tooltipPos[0] + this.tooltipPos[2]) / 2 + 15;

            return `
                position: fixed;
                will-change: transform;
                top: 0px;
                left: 0px;
                transform: translate3d(${x}px, ${y}px, 0px);
            `;
        },
        minTime() {
            if (!this.office) {
                return '06:00:00';
            }
            const dayOfWeek = moment().format('E');
            const time = moment.utc(this.office.openHours[dayOfWeek].from.date);
            return time.local().format('HH:mm:ss');
        },
        maxTime() {
            if (!this.office) {
                return '21:00:00';
            }
            const dayOfWeek = moment().format('E');
            const time = moment.utc(this.office.openHours[dayOfWeek].to.date);
            return time.local().format('HH:mm:ss');
        },
        defaultDate() {
            const dates = this.$store.getters['reservations/calendarViewDates'](
                this.defaultView,
            );

            return dates.start
                ? moment(dates.start).toDate()
                : moment().toDate();
        },
    },
    mounted() {
        this.views = {
            resourceTimelineDay: {
                nowIndicator: true,
                minTime: this.minTime,
                maxTime: this.maxTime,
                scrollTime: '06:00:00',
                slotLabelFormat: {
                    hour: 'numeric',
                    minute: '2-digit',
                    omitZeroMinute: false,
                    meridiem: 'short',
                },
            },
            resourceTimelineWeek: {
                nowIndicator: true,
                slotDuration: '12:00',
                slotLabelFormat: [
                    { week: 'short' },
                    { weekday: 'long' },
                    {
                        hour: 'numeric',
                        minute: '2-digit',
                        omitZeroMinute: false,
                    },
                ],
            },
            resourceTimelineMonth: {
                nowIndicator: true,
                slotDuration: '12:00',
                slotLabelFormat: [
                    { week: 'short' },
                    { day: 'numeric', month: 'numeric' },
                    {
                        hour: 'numeric',
                        minute: '2-digit',
                        omitZeroMinute: false,
                    },
                ],
            },
        };
        this.$nextTick(() => {
            this.loaded = true;
        });

        if (process.env.PUSHER_KEY !== undefined) {
            const pusher = new Pusher(process.env.PUSHER_KEY, {
                cluster: 'eu',
                encrypted: true,
            });
            const officeId = this.$store.state.base.office.id;

            const channel = pusher.subscribe('reservation');
            channel.bind('reservation:item:change', (res) => {
                if (
                    res.message.officeId === officeId
                    && [
                        'active',
                        'started',
                        'returned',
                        'completed',
                        'cancelled',
                    ].includes(res.message.state)
                ) {
                    if (
                        this.views
                        && this.loaded
                        && this.$refs.fullcalendar !== undefined
                    ) {
                        const calendarApi = this.$refs.fullcalendar.getApi();
                        calendarApi.refetchResources();
                        this.calendarKey += 1;
                    }
                }
            });
        }
    },
    methods: {
        fetchResources(fetchInfo, successCallback, failureCallback) {
            calendarResource.resources
                .index(this.office.id)
                .then((res) => {
                    successCallback(res.data.data);
                })
                .catch((err) => {
                    failureCallback(err);
                });
        },
        fetchEvents(fetchInfo, successCallback, failureCallback) {
            calendarResource.events
                .index(this.office.id, {
                    dateFrom: moment(fetchInfo.start).format('X'),
                    dateTo: moment(fetchInfo.end).format('X'),
                })
                .then((res) => {
                    const now = moment();

                    const items = res.data.data.map((item) => {
                        const startTime = moment(item.start);
                        const endTime = moment(item.end);

                        let { state } = item;
                        if (item.state === 'active' && startTime < now) {
                            state = 'started-late';
                        } else if (item.state === 'started' && endTime < now) {
                            state = 'returned-late';
                        }

                        item.classNames = `reservations-cal-event reservations-cal-event--state-${state}`;

                        return item;
                    });
                    successCallback(items);
                })
                .catch((err) => {
                    failureCallback(err);
                });
        },
        eventSelected(info) {
            info.jsEvent.preventDefault();
            this.$router.push({
                name: 'Reservation',
                params: { id: info.event.id },
            });
        },
        mouseEnter(info) {
            const rect = info.el.getBoundingClientRect();
            this.tooltipPos = [rect.top, rect.right, rect.bottom, rect.left];
            const {
                realId,
                contact,
                comments,
                state,
            } = info.event.extendedProps;
            this.tooltipRows = [
                `#${realId}`,
                contact,
                this.formatState(state),
                comments,
            ];
        },
        mouseLeave() {
            this.tooltipPos = null;
            this.tooltipRows = [];
        },
        formatState(state) {
            const stateMap = {
                active: 'Ei käsitelty',
                started: 'Alkanut',
                returned: 'Palautettu',
                completed: 'Valmis',
                cancelled: 'Peruutettu',
                deleted: 'Poistettu',
            };
            return `Tila: ${stateMap[state]}`;
        },
        updateCalendarView(e) {
            this.$store.commit('reservations/SET_CALENDAR_VIEW', e.view.type);
        },
        renderDates({ view }) {
            const now = moment();
            const start = moment(view.currentStart);
            const end = moment(view.currentEnd);
            const dates = now > start && now < end
                ? null
                : {
                    start: start.toISOString(),
                    end: end.toISOString(),
                };

            this.$store.commit('reservations/SET_CALENDAR_VIEW_DATES', {
                view: view.type,
                dates,
            });
        },
    },
};
</script>

<template>
    <div>
        <div class="reservations-filters">
            <div class="reservations-filters--states col-5">
                <a
                    v-for="state in states"
                    :key="state.value"
                    href="#"
                    class="reservations-filters--state"
                    :class="{
                        'reservations-filters--active-state':
                            state.value === currentState,
                    }"
                    @click.prevent="changeState(state.value)"
                >
                    {{ state.title }}
                </a>
            </div>

            <div class="reservations-filters--inputs col-7">
                <div class="input--field">
                    <label>Kärry</label>
                    <input
                        v-model="unit"
                        class="input input--small"
                        type="text"
                        placeholder="ABC-123"
                        style="min-width:86px;"
                        @blur="load()"
                        @input="input(unit)"
                    />
                </div>
                <div class="input--field" style="flex:1;">
                    <label>Varaus</label>
                    <input
                        v-model="customer"
                        class="input input--small"
                        type="text"
                        placeholder="Varausnro, nimi, puhnro, sposti"
                        style="width:100%;"
                        @blur="load()"
                        @input="input(customer)"
                    />
                </div>
                <div class="input--field">
                    <label>Alkaen</label>
                    <div class="input-group input-group--small">
                        <flat-pickr
                            v-model="dateFrom"
                            :config="datePickerConfig"
                            class="input"
                            placeholder="pp.kk.vvvv"
                            @blur="load()"
                            @input="input(dateFrom)"
                        ></flat-pickr>
                        <button data-clear class="data-clear">
                            <i class="icon icon--times" />
                        </button>
                        <button data-toggle class="data-toggle">
                            <i class="icon icon--calendar-alt" />
                        </button>
                    </div>
                </div>
                <div class="input--field">
                    <label>Päättyen</label>
                    <div class="input-group input-group--small">
                        <flat-pickr
                            v-model="dateTo"
                            :config="datePickerConfig"
                            class="input"
                            placeholder="pp.kk.vvvv"
                            @blur="load()"
                            @input="input(dateTo)"
                        ></flat-pickr>
                        <button data-clear class="data-clear">
                            <i class="icon icon--times" />
                        </button>
                        <button data-toggle class="data-toggle">
                            <i class="icon icon--calendar-alt" />
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <data-table class="data-table--selectable">
            <template slot="head">
                <data-table-cell v-for="(col, index) in columns" :key="index">
                    <a
                        v-if="col.sort"
                        href="#"
                        @click.prevent="changeSort(col.sort)"
                    >
                        {{ col.title }}
                        <i
                            class="icon icon--chevron-down"
                            :class="{
                                'icon--chevron-up':
                                    sort && sort === `+${col.sort}`,
                            }"
                        ></i>
                    </a>
                    <span v-else>{{ col.title }}</span>
                </data-table-cell>
            </template>

            <template slot="body">
                <data-table-empty
                    v-if="!rows.length && !processing"
                    col-span="8"
                >
                    <empty-resource>
                        <span class="icon icon--reservations"></span>
                        Ei yhtään varausta valituilla kriteereillä
                    </empty-resource>
                </data-table-empty>

                <data-table-empty v-else-if="processing" col-span="8">
                    <empty-resource>
                        <span class="icon icon--reservations"></span>
                        Ladataan...
                    </empty-resource>
                </data-table-empty>

                <data-table-row
                    v-for="row in rows"
                    v-else
                    :key="row.id"
                    @click="
                        $router.push({
                            name: 'Reservation',
                            params: { id: row.id },
                        })
                    "
                >
                    <data-table-cell>
                        {{ row.unit ? row.unit : '-' }}
                    </data-table-cell>
                    <data-table-cell>
                        {{ row.realId ? row.realId : '-' }}
                    </data-table-cell>
                    <data-table-cell
                        v-if="row.ban && row.ban.state === 'ban'"
                        class="data-table__cell--unpad banned"
                    >
                        <span title="Varauskielto">
                            <i class="icon icon--times-circle banned__icon" />
                            <strong>{{ row.name ? row.name : '-' }}</strong>
                        </span>
                    </data-table-cell>
                    <data-table-cell v-else>
                        {{ row.name ? row.name : '-' }}
                    </data-table-cell>
                    <data-table-cell>
                        {{ row.email ? row.email : '-' }}
                    </data-table-cell>
                    <data-table-cell>
                        {{ row.phone ? row.phone : '-' }}
                    </data-table-cell>
                    <data-table-cell>
                        {{ row.created ? formatDate(row.created) : '-' }}
                    </data-table-cell>
                    <data-table-cell>
                        {{ row.dateFrom ? formatDate(row.dateFrom) : '-' }}
                    </data-table-cell>
                    <data-table-cell>
                        {{ row.dateTo ? formatDate(row.dateTo) : '-' }}
                    </data-table-cell>
                </data-table-row>
            </template>

            <template v-if="pagination" slot="total">
                Varauksia yhteensä: {{ pagination.total }}
            </template>

            <template v-if="pagination" slot="pagination">
                <a
                    v-for="(page, index) in truncatedPagination"
                    :key="index"
                    href="#"
                    class="data-table__pagination--item"
                    :class="{
                        'data-table__pagination--active': page === currentPage,
                    }"
                    @click.prevent="changePage(page)"
                >
                    {{ page }}
                </a>
            </template>
        </data-table>
    </div>
</template>

<script>
import moment from 'moment';
import Pusher from 'pusher-js';
import { mapGetters, mapActions } from 'vuex';
import FlatPickr from 'vue-flatpickr-component';
import { pickerConfigDate } from '../helpers';
import DataTable from './DataTable.vue';
import DataTableRow from './DataTable/DataTableRow.vue';
import DataTableCell from './DataTable/DataTableCell.vue';
import DataTableEmpty from './DataTable/DataTableEmpty.vue';
import EmptyResource from './EmptyResource.vue';

export default {
    name: 'ReservationsList',
    components: {
        FlatPickr,
        DataTable,
        DataTableRow,
        DataTableCell,
        DataTableEmpty,
        EmptyResource,
    },
    data() {
        return {
            columns: [
                { title: 'Kärry', sort: null },
                { title: 'Varausnumero', sort: null },
                { title: 'Asiakas', sort: null },
                { title: 'Sähköposti', sort: null },
                { title: 'Puhelin', sort: null },
                { title: 'Vastaanotettu', sort: 'created' },
                { title: 'Alkamisaika', sort: 'dateFrom' },
                { title: 'Päättymisaika', sort: 'dateTo' },
            ],
            states: [
                { title: 'Käsittelemättömät', value: 'active' },
                { title: 'Alkaneet', value: 'started' },
                { title: 'Palautetut', value: 'returned' },
                { title: 'Arkisto', value: 'in=completed|cancelled' },
            ],
            datePickerConfig: pickerConfigDate,
        };
    },
    computed: {
        ...mapGetters({
            currentPage: 'reservations/currentPage',
            currentState: 'reservations/currentState',
            limit: 'reservations/limit',
            office: 'base/office',
            pagination: 'reservations/pagination',
            processing: 'reservations/processing',
            rows: 'reservations/rows',
            truncatedPagination: 'reservations/truncatedPagination',
        }),
        dateFrom: {
            get() {
                return this.$store.state.reservations.dateFrom;
            },
            set(value) {
                this.$store.commit('reservations/SET_CURRENTPAGE', 1);
                this.$store.commit('reservations/SET_DATEFROM', value);
            },
        },
        dateTo: {
            get() {
                return this.$store.state.reservations.dateTo;
            },
            set(value) {
                this.$store.commit('reservations/SET_CURRENTPAGE', 1);
                this.$store.commit('reservations/SET_DATETO', value);
            },
        },
        unit: {
            get() {
                return this.$store.state.reservations.unit;
            },
            set(value) {
                this.$store.commit('reservations/SET_CURRENTPAGE', 1);
                this.$store.commit('reservations/SET_UNIT', value);
            },
        },
        customer: {
            get() {
                return this.$store.state.reservations.customer;
            },
            set(value) {
                this.$store.commit('reservations/SET_CURRENTPAGE', 1);
                this.$store.commit('reservations/SET_CUSTOMER', value);
            },
        },
        sort: {
            get() {
                return this.$store.state.reservations.sort;
            },
            set(value) {
                this.$store.commit('reservations/SET_SORT', value);
            },
        },
    },
    watch: {
        office(after, before) {
            if (after !== before) {
                this.load();
            }
        },
    },
    mounted() {
        this.load();

        if (process.env.PUSHER_KEY !== undefined) {
            const pusher = new Pusher(process.env.PUSHER_KEY, {
                cluster: 'eu',
                encrypted: true,
            });
            const officeId = this.$store.state.base.office.id;

            const channel = pusher.subscribe('reservation');
            channel.bind('reservation:item:change', (res) => {
                if (
                    res.message.officeId === officeId
                    && [
                        'active',
                        'started',
                        'returned',
                        'completed',
                        'cancelled',
                    ].includes(res.message.state)
                ) {
                    this.load();
                }
            });

            channel.bind('reservation:log', (res) => {
                if (
                    res.message.action === 'ban/create'
                    || res.message.action === 'ban/update'
                ) {
                    this.load();
                }
            });
        }
    },
    beforeDestroy() {
        this.softReset();
    },
    methods: {
        ...mapActions({
            changePage: 'reservations/changePage',
            changeSort: 'reservations/changeSort',
            changeState: 'reservations/changeState',
            load: 'reservations/load',
            softReset: 'reservations/softReset',
        }),
        input(field) {
            if (!field || field.length >= 3) {
                this.load();
            }
        },
        formatDate(timestamp) {
            return moment.unix(timestamp).format('D.M.Y H:mm');
        },
    },
};
</script>

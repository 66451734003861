import http from './common';

export default {
    weeks: {
        index: (year, week, params) => (
            http.get(`reservation/v1/calendar/${year}/weeks/${week}`, {
                params,
            })
        ),
    },
    resources: {
        index: (office, params) => (
            http.get(`reservation/v1/calendar/${office}/resources/`, {
                params,
            })
        ),
    },
    events: {
        index: (office, params) => (
            http.get(`reservation/v1/calendar/${office}/events/`, { params })
        ),
    },
};
